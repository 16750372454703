import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { catchError, map, mergeMap, switchMap, tap } from 'rxjs/operators';

import * as PROJECTS_ACTIONS from './projects.actions';

import { TQApiService } from 'src/app/services/tqapi.service';
import { SamuraiService } from 'src/services/samurai/samurai.service';

@Injectable()
export class ProjectsEffects {

  constructor(
    private actions$: Actions,
    private samApp: SamuraiService,
    private tqapi: TQApiService,
  ) {}

  // TQprojects
  loadProjectsList$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(PROJECTS_ACTIONS.loadProjectsList),
        switchMap(() =>
          from(this.tqapi.getProjectsByProfile())
            .pipe(
              map( (projects) => PROJECTS_ACTIONS.loadProjectsListOK({ projects })),
              catchError( (error) => of(PROJECTS_ACTIONS.loadProjectsListKO({ error })))
            )
        )
      )
  )
  
  selectProject$ = createEffect(() =>
    (
    this.actions$
      .pipe(
        ofType(PROJECTS_ACTIONS.selectProject),
        tap( (action) =>  {
          this.samApp.saveStore("TQselectedProjectId", action.id, "session")
        })
      )
    ),
    {
      dispatch: false,
    })
}