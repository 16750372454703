import { environment } from 'src/environments/environment';
import SAMconfig from 'src/assets/sam/application.json'

import { HostListener, Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class SamuraiService
{
  private samAppVersion = SAMconfig.application.version;

  private samAppEnvironment = environment.samurai.environment;
  private samAppFeatures = environment.samurai.features;
  private samDebugLevel = environment.samurai.debug;


  constructor()
  {
    // Get device screen size
    this.getScreenSize();
  }

  getVersion()
  {
    return this.samAppVersion
  }

  getEnvironment()
  {
    return this.samAppEnvironment
  }

  getFeatures()
  {
    return this.samAppFeatures
  }

  /** APP DEBUGGING SERVICE **/

  trace(msg: any)
  {
    if (this.samDebugLevel == 'trace')
    {
      console.log("TRACE: "+ JSON.stringify(msg));
    }
    if (this.samDebugLevel == 'debug')
    {
      console.log("DEBUG: "+ JSON.stringify(msg));
      console.log((new Error).stack);
    }
  }

  /** APP STORAGE SERVICE **/

  saveStore(key: string, data: any, store: string = "local")
  {
    switch (store)
    {
      case "local":
        localStorage.setItem(key, JSON.stringify(data));
        break;
      case "session":
        sessionStorage.setItem(key, JSON.stringify(data));
        break;
    }
  }

  retrieveStore(key: string, store: string = "local")
  {
    var data = null;
    switch (store)
    {
      case "local":
        data = localStorage.getItem(key);
        break;
      case "session":
        data = sessionStorage.getItem(key);
        break;
    }
    return (data == null)?null:JSON.parse(data);
  }

  deleteStore(key: string, store: string = "local")
  {
    switch (store)
    {
      case "local":
        localStorage.removeItem(key);
        break;
      case "session":
        sessionStorage.removeItem(key);
        break;
    }
  }

  cleanStore(store:string)
  {
    switch (store)
    {
      case "local":
        localStorage.clear()
        break;
      case "session":
        sessionStorage.clear()
        break;
    }
  }

  cleanAll()
  {
    this.cleanStore("local")
    this.cleanStore("session")
  }

  /** APP DEVICE SERVICE **/

  private scrHeight:number;
  private scrWidth:number;
  public onMobile:boolean;

  @HostListener('window:resize', ['$event'])
  getScreenSize(event?)
  {
    this.scrHeight = window.innerHeight;
    this.scrWidth = window.innerWidth;

    this.onMobile=(this.scrWidth < 768 ? true : false);
  }


}
