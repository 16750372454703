import { Component, EventEmitter, OnInit, Output } from '@angular/core';

import { SplitButtonModule } from 'primeng/splitbutton';
import { MenuItem } from 'primeng/api';

import { Store } from '@ngrx/store';
import { AppState } from 'src/app/appState/app.state';
import { appState } from 'src/app/appState/app.selectors';

@Component({
  selector: 'tq-profile-widget',
  standalone: true,
  imports: [
    SplitButtonModule
  ],
  templateUrl: './tq-profile-widget.component.html',
  styleUrl: './tq-profile-widget.component.scss'
})

export class TQProfileWidgetComponent implements OnInit
{
  @Output() command = new EventEmitter<string>();

  appState: AppState;
  appStateSubs: any;

  email: string;
  menuItems: MenuItem[];

  constructor
  (
    private store: Store
  )
  {}

  ngOnInit() 
  {
    this.appStateSubs = this.store.select(appState)
      .subscribe( state => {
        this.appState = state 
    })

    this.email = this.appState.Auth0Profile.email

    this.buildProfileMenu() 
  } 

  buildProfileMenu() 
  {
    this.menuItems = [
    {
      label:'Select role...',
      icon:'pi pi-fw ',
      command: (event) => this.outputCommand(event),
      items:[
        {
          label: 'All',
          icon: 'pi pi-fw',
          command: (event) => this.outputCommand(event)
        },
        {
          label: 'LONGWORKDAY',
          icon: 'pi pi-fw pi-user',
          command: (event) => this.outputCommand(event)
        },
        {
          label: 'HOME',
          icon: 'pi pi-fw pi-user',
          command: (event) => this.outputCommand(event)
        },
      ]
    }];
  }

  outputCommand(event)
  {
    this.command.emit(event.item.label)
  }

}
