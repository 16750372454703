import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { TQDateTimeService } from 'src/app/services/tqdatetime.service';

@Component({
  selector: 'tq-time-widget',
  templateUrl: './tq-time-widget.component.html',
  styleUrl: './tq-time-widget.component.scss'
})
export class TQTimeWidgetComponent implements OnChanges
{
  @Input() timeFormat: string = "24h";

  @Input() time: string; 
  @Output() timeChange = new EventEmitter<string>();

  hourLabel: string = null;
  minuteLabel: string = null;

  constructor
  (
    private tqDT: TQDateTimeService
  ) {}

  // Parent component changes 
  ngOnChanges()
  {
    if (this.time == null) 
    {
      this.hourLabel = null
      this.minuteLabel = null
    }
    else
    {
      this.hourLabel = this.buildHourLabel(this.time.substring(0,2));
      this.minuteLabel = this.time.substring(3,5);
    }
  } 

  // Local changes 
  changeTime() 
  {
    if (this.minuteLabel == null || this.minuteLabel == "") this.minuteLabel = "00";
    this.time = this.buildTime(this.hourLabel, this.minuteLabel);
    this.timeChange.emit(this.time);
  }


  buildHourLabel(hour: string): string
  {
    if ( hour == "" || hour == null) return "";

    let label = "";
    let h = +hour.substring(0,2)
    if (this.timeFormat == "12h") 
    {
      if (+hour < 12)
      {
        if (h == 0) h = 12  
        label = h + " AM"
      }
      else
      {
        if (h == 12) h = 24  
        label = (h - 12) + " PM"
      }
    }
    else
    {
      label = hour.substring(0,2)
    }

    return label;
  }

  buildTime(hour: string, minute: string): string
  {
    if ( hour == "" || hour == null) return "";
    if (minute == null) minute="00"

    // Convert a string with AP/PM hour to 24 hour format
    if (this.timeFormat == "12h") 
    {
      if (hour.length == 4) hour = "0"+hour;
      
      if (hour.includes("PM"))
      {
        hour = hour.substring(0,2)
        if (hour != "12") hour = (+hour + 12).toString()
      }
      else
      {
        hour = hour.substring(0,2)
        if (hour == "12") hour = "00"
      }
    }
    else
    {
      hour = hour.substring(0,2)
    }

    return hour+":"+minute;
  }

  getHourLabels(): Array<string>
  {
    var res: Array<string> = [];

    //res.push("") // First empty option
    if (this.timeFormat == "24h")
    {
      for (let h = 0; h<24; h++)
      {
        res.push(("0"+h.toString()).slice(-2))
      }  
    }
    else
    {
      for (let h = 0; h<12; h++)
      {
        res.push(((h == 0 ? 12 : h).toString())+ " AM")
      }  
      for (let h = 0; h<12; h++)
      {
        res.push(((h == 0 ? 12 : h).toString())+ " PM")
      }  
    }
    return res;
  }

  getMinuteLabels()
  {
    var res: Array<string> = [];

    //res.push("") // First empty option  
    for (var m = 0; m<60; m=m+5)
    {
      res.push(("00"+m.toString()).substr(-2))
    }
    return res;
  }

}
