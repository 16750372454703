export const environment = {
  production: false,

  samurai: {
    environment : "dev",
    debug: "trace",

    features: {
      reloadOnTab: true,

      allowFreeSubscriptions: true,

      STRIPE_allowBilling: true,
      STRIPE_advancedFraudSignals: false,

      allowIntegrations: true,
      GOOGLE_calendar_import: true,
    },
  },

  auth0: {
    domain: "taskquark-dev.eu.auth0.com",
    client_id: "ACwZsOG0wCD46tLRfKmWQPA4pkCDJZNd",
    audience: "https://api-dev.taskquark.com",
    useRefreshTokens: true,
    localstorage: true,
  },

  stripe: 
  {
    // key: "pk_test_DFVfe0RfdRSfWxPa8KlBiMX000if1SnzN0",
    // TQ US TEST key
    key: "pk_test_51OcP0aB1orQMtmVfxJlKeaYMf0WoJX3FgbChwH5xcVYLOsE7uxgLCRJHRBMme2T9LXisSCDem5GRCa7NO1CJoQ2X00vmqM6F1d"

  },

};
