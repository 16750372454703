<div class="samPane">
  <div class="samPaneHeader">
    <h4 class="samPaneTitle">Loading...
      <a href="https://docs.taskquark.com/support.html" target="TQ-docs">
        <fa-icon [icon]="faQuestionCircle" class="TQ-help-icon" title="Help page"></fa-icon>
      </a>
    </h4>
    <img class="TQ-loading-icon" src="/assets/img/spinner.gif">
  </div>
</div>
