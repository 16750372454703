import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { from, of } from 'rxjs';
import { map, mergeMap, switchMap, catchError } from 'rxjs/operators';

import * as NOTES_ACTIONS from './notes.actions';

import { TQApiService } from 'src/app/services/tqapi.service';

@Injectable()
export class NoteEffects {

  constructor(
    private actions$: Actions,
    private tqApiService: TQApiService
  ) { }

  // NOTEPAD
  loadNotesList$ = createEffect(() =>
    this.actions$
      .pipe(
        ofType(NOTES_ACTIONS.loadNotesList),
        switchMap(() =>
          from(this.tqApiService.getNoteSamplesByProfile())
            .pipe(
              map( (notes) => NOTES_ACTIONS.loadNotesListOK({ notes })),
              catchError( (error) => of(NOTES_ACTIONS.loadNotesListKO({ error })))
            )
        )
      )
  )

  // NOTE
  // loadNote$ = createEffect(() =>
  //   this.actions$
  //     .pipe(
  //       ofType(NOTES_ACTIONS.loadNote),
  //       switchMap((action) =>
  //         from(this.tqApiService.getNote(action.id))
  //           .pipe(
  //             map( (note) => NOTES_ACTIONS.loadNoteOK({ note })),
  //             catchError( (error) => of(NOTES_ACTIONS.loadNoteKO({ error })))
  //           )
  //       )
  //     )
  // )
  // deleteNote$ = createEffect(() =>
  //   this.actions$
  //     .pipe(
  //       ofType(NOTES_ACTIONS.deleteNote),
  //       mergeMap( (action) => this.tqApiService.deleteNote(action.id) )
  //     ),
  //     {
  //       dispatch: false,
  //     }
  // )

}