import { Component, OnInit, HostListener, Input, ChangeDetectorRef, ViewChild } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';

import { Auth0Service } from 'src/app/services/auth0/auth0.service';
import { SamuraiService } from 'src/services/samurai/samurai.service';
import { TQApiService } from 'src/app/services/tqapi.service';
import { TQSessionService } from 'src/app/services/tqsession.service';

import { StripeService } from 'src/app/services/stripe/stripe.service';

import { Store } from '@ngrx/store';
import { AppState, Auth0Profile } from 'src/app/appState/app.state';
import * as APP_ACTIONS from 'src/app/appState/app.actions';
import { appState } from 'src/app/appState/app.selectors';
import * as PROJECTS_ACTIONS from 'src/app/projects/store/projects.actions';
import * as TASKS_ACTIONS from 'src/app/tasks/store/tasks.actions';
import * as NOTES_ACTIONS from 'src/app/notes/store/notes.actions';

import { TQRoleSelectorComponent } from 'src/app/shared/widgets/tq-role-selector/tq-role-selector.component';

import { faCheckCircle, faExclamationCircle, faFileInvoice, faReceipt }
  from '@fortawesome/free-solid-svg-icons';
  import { MatAccordion } from '@angular/material/expansion';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent implements OnInit
{
  appState: AppState;
  appStateSubs: any;

  faCheckCircle = faCheckCircle;
  faExclamationCircle = faExclamationCircle;
  faFileInvoice = faFileInvoice;
  faReceipt = faReceipt;

  @ViewChild("upgradeAccordion") 
  upgradeAccordion: MatAccordion;

  TQsession: any;
  TQsubscriptions: any[];

  prefLoginStartPad = "workpad"
  prefLogoutAfterInactiveFor = "60"

  prefLocTimeZone = "UTC";
  prefLocDateFormat = "YYYY-MM-DD";
  prefLocTimeFormat = "24h";
  prefLocWeekStart = "Sunday";
  prefLocWeekendDays = "Sa Su";

  prefWorkpadNumCols = 5;
  prefWorkpadCol1 = "planning";
  prefWorkpadCol2 = "todo";
  prefWorkpadCol3 = "doing";
  prefWorkpadCol4 = "waiting";
  prefWorkpadCol5 = "stopped";
  prefWorkpadCol1Mobile = "planning";
  prefWorkpadCol2Mobile = "todo";
  prefWorkpadCol3Mobile = "doing";
  prefWorkpadCol4Mobile = "waiting";
  prefWorkpadCol5Mobile = "stopped";

  prefTaskRadarEnabled = false;
  prefTaskRadarFreq = "monthly";
  prefTaskRadarRange = "60d";

  cfgRoleSelectedId = null;

  isValidSubscription: boolean = false;
  isCanceledSubscription: boolean = false;
  isCancelableSubscription: boolean = false;
  isUpgradableSubscription: boolean = false;
  isUnpaidSubscription: boolean = true;
  freePlanAvailable: boolean = false;
  paidPlanAvailable: boolean = false;

  clickedSubscriptionCancel = false;
  clickedProfileDelete = false;

  sentVerificationEmail = false;

  selectedTab = 0;

  constructor(
    private cdr: ChangeDetectorRef,
    private activeRoute: ActivatedRoute,
    private router: Router,
    private store: Store,
    private auth0: Auth0Service,
    private stripe: StripeService,
    public  samApp: SamuraiService,
    public  tqSession: TQSessionService,
    private tqApi: TQApiService,
  )
  {
    this.appStateSubs = this.store.select(appState)
    .subscribe(async state => { 
        this.appState = state
      }
    )
  }

  async ngOnInit()
  {
    this.cdr.detach();
    // this.store.dispatch(APP_ACTIONS.enterTQpad( {pad:'profile'} ))
    this.store.dispatch(APP_ACTIONS.nextTQpad( {pad:'profile'} ))

    this.TQsession = this.samApp.retrieveStore("TQsession") 

    // Reload profile and subscriptions
    //// await this.reloadProfile()
    await this.tqSession.getTQProfile()
    // await this.updateTQsession()
    this.cdr.reattach();

    // Update TQsession after confirming email
    await this.auth0.getUser$().toPromise()
      .then( Auth0Profile =>
        {
          this.TQsession = this.samApp.retrieveStore("TQsession")
          this.TQsession['email_verified'] = Auth0Profile['email_verified']
          this.samApp.saveStore("TQsession", this.TQsession)  
        })

    // Reload profile and subscriptions
    await this.reloadProfile()

    // Check URL parameter to check a callback from Stripe Checkout
    let tab = this.activeRoute.snapshot.queryParams['tab']
    switch (tab)
    {
      case 'configuration':
        this.selectedTab = 4;
        break;
      case 'subscriptions':
        this.selectedTab = 1;
        let result = this.activeRoute.snapshot.queryParams['result']
        if (result == "KO") 
        {
          this.cancelSubscription();
        }
        break;
      default:
        // Default to preferences tab after first subscription
        this.selectedTab = 2;
        break;
    }

    // Check whether the subscription is valid
    if (this.isValidSubscription == false)
    {
      this.selectedTab = 1;
    }

    // Check whether the email has been verified
    if (this.tqSession.hasEmailVerified() == false)
    {
      this.selectedTab = 0;
    }
  }

  ngOnDestroy()
  {
    if (this.appStateSubs != null) this.appStateSubs.unsubscribe(); 
  }

  @HostListener('document:keydown.control.s', ['$event'])
  CtrlS(event: any)
  {
    event.preventDefault();
    document.getElementById("PrefSaveButton").click()
  }


  /*--- PROFILE ---*/

  async reloadProfile()
  {
    await this.tqSession.getTQProfile()
    await this.updateTQsession()

    await this.tqSession.getRolesByProfile();    
    await this.reloadSubscriptions();
  }

  async updateProfile()
  {
    await this.tqApi.updateProfile(this.TQsession)
  }

  async deleteProfile()
  {
    await this.tqApi.deleteProfile()

    // Unconditionally logout
    this.router.navigate(['logout'])
  }

  async savePreferences()
  {
    this.TQsession["prefLoginStartPad"]=this.prefLoginStartPad
    this.TQsession["prefLogoutAfterInactiveFor"]=this.prefLogoutAfterInactiveFor
    this.TQsession["prefLocTimeZone"]=this.prefLocTimeZone
    this.TQsession["prefLocWeekStart"]=this.prefLocWeekStart
    this.TQsession["prefLocWeekendDays"]=this.prefLocWeekendDays
    this.TQsession["prefLocDateFormat"]=this.prefLocDateFormat
    this.TQsession["prefLocTimeFormat"]=this.prefLocTimeFormat
    this.TQsession["prefWorkpadNumCols"]=this.prefWorkpadNumCols
    this.TQsession["prefWorkpadCol1"]=this.prefWorkpadCol1
    this.TQsession["prefWorkpadCol2"]=this.prefWorkpadCol2
    this.TQsession["prefWorkpadCol3"]=this.prefWorkpadCol3
    this.TQsession["prefWorkpadCol4"]=this.prefWorkpadCol4
    this.TQsession["prefWorkpadCol5"]=this.prefWorkpadCol5
    this.TQsession["prefWorkpadCol1Mobile"]=this.prefWorkpadCol1Mobile
    this.TQsession["prefWorkpadCol2Mobile"]=this.prefWorkpadCol2Mobile
    this.TQsession["prefWorkpadCol3Mobile"]=this.prefWorkpadCol3Mobile
    this.TQsession["prefWorkpadCol4Mobile"]=this.prefWorkpadCol4Mobile
    this.TQsession["prefWorkpadCol5Mobile"]=this.prefWorkpadCol5Mobile
    this.TQsession["prefTaskRadarEnabled"]=this.prefTaskRadarEnabled
    this.TQsession["prefTaskRadarFreq"]=this.prefTaskRadarFreq
    this.TQsession["prefTaskRadarRange"]=this.prefTaskRadarRange
    this.TQsession["cfgRoleSelectedId"]=this.cfgRoleSelectedId
   
    await this.updateProfile()
    
    this.store.dispatch(APP_ACTIONS.setTQroleSelectedId({id: this.cfgRoleSelectedId}))
    this.store.dispatch(APP_ACTIONS.setTQprofilePrefs({profile: this.TQsession}))   

    this.store.dispatch(APP_ACTIONS.projectFiltered({ id:0, code:'', color:'', list:[] }))
    this.store.dispatch(PROJECTS_ACTIONS.loadProjectsList())

    // this.router.navigate([this.prefLoginStartPad])
    this.router.navigate([this.appState.lastPad])
  }

  async checkEmailVerification()
  {
    // Login to check whether the email has been verified
    this.auth0.login("/profile")
  }

  async sendVerificationEmail()
  {
    this.sentVerificationEmail=true;

    await this.tqApi.sendVerificationEmail()
  }

  /*--- SESSION ---*/

  updateTQsession()
  {
    this.TQsession = this.samApp.retrieveStore("TQsession")

    this.prefLoginStartPad = this.TQsession["prefLoginStartPad"]
    this.prefLogoutAfterInactiveFor = this.TQsession["prefLogoutAfterInactiveFor"]
    this.prefLocTimeZone = this.TQsession["prefLocTimeZone"] || Intl.DateTimeFormat().resolvedOptions().timeZone;
    this.prefLocWeekStart = this.TQsession["prefLocWeekStart"] || "Monday"
    this.prefLocWeekendDays = this.TQsession["prefLocWeekendDays"] || "Sa Su"
    this.prefLocDateFormat = this.TQsession["prefLocDateFormat"] || "YYYY-MM-DD"
    this.prefLocTimeFormat = this.TQsession["prefLocTimeFormat"] || "24h"
    this.prefWorkpadNumCols = this.TQsession["prefWorkpadNumCols"] || 5
    this.prefWorkpadCol1 = this.TQsession["prefWorkpadCol1"] || "planning"
    this.prefWorkpadCol2 = this.TQsession["prefWorkpadCol2"] || "todo"
    this.prefWorkpadCol3 = this.TQsession["prefWorkpadCol3"] || "doing"
    this.prefWorkpadCol4 = this.TQsession["prefWorkpadCol4"] || "waiting"
    this.prefWorkpadCol5 = this.TQsession["prefWorkpadCol5"] || "stopped"
    this.prefWorkpadCol1Mobile = this.TQsession["prefWorkpadCol1Mobile"] || "planning"
    this.prefWorkpadCol2Mobile = this.TQsession["prefWorkpadCol2Mobile"] || "todo"
    this.prefWorkpadCol3Mobile = this.TQsession["prefWorkpadCol3Mobile"] || "doing"
    this.prefWorkpadCol4Mobile = this.TQsession["prefWorkpadCol4Mobile"] || "waiting"
    this.prefWorkpadCol5Mobile = this.TQsession["prefWorkpadCol5Mobile"] || "stopped"
    this.prefTaskRadarEnabled = this.TQsession["prefTaskRadarEnabled"]
    this.prefTaskRadarFreq = this.TQsession["prefTaskRadarFreq"]
    this.prefTaskRadarRange = this.TQsession["prefTaskRadarRange"]

    this.cfgRoleSelectedId = this.TQsession["cfgRoleSelectedId"]
  }
  
  /*--- SUBSCRIPTIONS ---*/

  async addSubscription(subscription_plan?: string)
  {
    let res = null;

    var TQSubscription = {
      "profile_id"             : this.appState.TQprofileId,
      "subscription_plan"      : subscription_plan,
      "session_id"             : null, 
      "subscription_id"        : this.appState.lastSubscription?.id || null,
      "externalSubscriptionId" : null,
    }

    // Cancel the old subscription
    if (this.appState.lastSubscription?.id == null || this.appState.lastSubscription?.dateCanceled != null)
    {
      // this.tqApi.trace("NO current subscription to cancel")
    }
    else
    {
      await this.tqApi.cancelSubscription(TQSubscription)
    }
  
    // Create the new subscription
    if (subscription_plan.includes('free'))
    {
      await this.tqApi.postSubscription(TQSubscription)
    }
    else
    {
      // Create Stripe session for non free plans
      res = await this.tqApi.getStripeSession(TQSubscription)
      TQSubscription["session_id"] = res['session_id']

      await this.tqApi.postSubscription(TQSubscription)

      // Proceed to Stripe checkout
      const checkout = await this.stripe.getStripeClient();
      const result = await checkout.redirectToCheckout({ sessionId: res['session_id'], })
        // .then(async (result) => { 
          // if (result.error) 
          // {
          //   // Cancel the new subscription
          //   this.cancelSubscription();
          // }
        //})
    }
  

    // if (this.appState.lastSubscription?.id == null || this.appState.lastSubscription?.dateCanceled != null)
    // {
    //   // Create the subscription
    //   res = await this.tqApi.postSubscription(TQSubscription)
    // }
    // else
    // {
    //   // Update the subscription
    //   res = await this.tqApi.updateSubscription(TQSubscription)
    // }

    // Go to checkout for non free plans
    // if (!subscription_plan.includes('free'))
    // {
    //   // Proceed to Stripe checkout
    //   const checkout = await this.stripe.getStripeClient();

    //   checkout.redirectToCheckout({ sessionId: res['session_id'], })
    //     .then(async (result) => { 
    //       if (result.error) 
    //       {
    //         // Cancel the new subscription
    //         this.cancelSubscription();
    //       }
    //     })
    // }

    // Reload profile to get the subscriptions
    this.upgradeAccordion.closeAll()
    await this.reloadSubscriptions()
  }

  async cancelSubscription()
  {
    let id = this.appState.lastSubscription.id;

    // Cancel the subscription
    let res = await this.tqApi.deleteSubscription(id)

    // Reload profile to get the subscriptions
    await this.reloadProfile()

    this.clickedSubscriptionCancel = false;
  }

  async reloadSubscriptions()
  {
    // Get the subscriptions and last subscription
    await this.tqSession.getSubscriptionsByProfile();
    this.TQsubscriptions = this.tqSession.getTQsubscriptions();

    this.isValidSubscription = this.tqSession.isValidSubscription()
    this.isCanceledSubscription = this.tqSession.isCanceledSubscription()
    this.isCancelableSubscription = this.tqSession.isCancelableSubscription()
    this.isUpgradableSubscription = this.tqSession.isUpgradableSubscription()
    this.isUnpaidSubscription = this.tqSession.isUnpaidSubscription()

    // Check for paid plan availability
    this.paidPlanAvailable = this.samApp.getFeatures().STRIPE_allowBilling 

    // Check for free plan availability
    this.freePlanAvailable = this.samApp.getFeatures().allowFreeSubscriptions // || this.isFreeSubscriber() 
    if (this.freePlanAvailable) this.isUnpaidSubscription = false;  
  }

  // isFreeSubscriber()
  // {
  //   if (this.tqSession.getTQsubscriptions().length == 0) return false;

  //   // A subscriber is a free candidate if ever had a free subsription
  //   let freeSubscriber = this.tqSession.getTQsubscriptions().find(s => s.plan.includes('free'))?true:false;
  //   return freeSubscriber
  // }
 
  async showSubscriptionInvoice(subs: string)
  {
    // Get the subscription invoice
    let res = await this.tqApi.getSubscriptionInvoice(subs)

    window.open(res['URL'], '_blank');
  }

  async showSubscriptionReceipt(subs: string)
  {
    // Get the subscription invoice
    let res = await this.tqApi.getSubscriptionReceipt(subs)

    window.open(res['URL'], '_blank');
  }

  async showSubscriptionRefund(subs: string)
  {
    // Get the subscription invoice
    let res = await this.tqApi.getSubscriptionRefund(subs)

    window.open(res['URL'], '_blank');
  }

  toggleClickProfileDelete()  
  {
    this.clickedProfileDelete = !this.clickedProfileDelete;
  }

  togglePrefTaskRadarEnabled()
  {
    this.prefTaskRadarEnabled = !this.prefTaskRadarEnabled;
  }
}
