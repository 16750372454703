import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { DragDropModule } from '@angular/cdk/drag-drop';
import { EditorModule } from 'primeng/editor';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatTabsModule } from '@angular/material/tabs';
import { MatLuxonDateModule } from '@angular/material-luxon-adapter';

import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { ContextMenuModule } from 'primeng/contextmenu';
import { SelectButtonModule } from 'primeng/selectbutton';
import { TreeSelectModule } from 'primeng/treeselect';

import { TQDateWidgetComponent } from 'src/app/shared/widgets/tq-date-widget/tq-date-widget.component';
import { TQDurationWidgetComponent } from 'src/app/shared/widgets/tq-duration-widget/tq-duration-widget.component';
import { TQShowDateTimeWidgetComponent } from 'src/app/shared/widgets/tq-show-date-time-widget/tq-show-date-time-widget.component';
import { TQTaskCardComponent } from 'src/app/shared/widgets/tq-task-card/tq-task-card.component';
import { TQTaskEventDateComponent } from 'src/app/shared/widgets/tq-task-event-date/tq-task-event-date.component';
import { TQTaskMenuComponent } from 'src/app/shared/widgets/tq-task-menu/tq-task-menu.component';
import { TQTimeWidgetComponent } from 'src/app/shared/widgets/tq-time-widget/tq-time-widget.component';
import { TQTimezonesWidgetComponent } from 'src/app/shared/widgets/tq-timezones-widget/tq-timezones-widget.component';
import { TQWeekdaysWidgetComponent } from 'src/app/shared/widgets/tq-weekdays-widget/tq-weekdays-widget.component';

import { MonthpadComponent } from './monthpad/monthpad.component';
import { TaskComponent } from './task/task.component';
import { TodopadComponent } from './todopad/todopad.component';
import { WeekpadComponent } from './weekpad/weekpad.component';
import { WorkpadComponent } from './workpad/workpad.component';


@NgModule({
  declarations: [
    MonthpadComponent,
    TaskComponent,
    TodopadComponent,
    WeekpadComponent,
    WorkpadComponent,
    TQDateWidgetComponent,
    TQDurationWidgetComponent,
    TQTaskCardComponent,
    TQTaskEventDateComponent,
    TQTaskMenuComponent,    
    TQTimeWidgetComponent,
    TQShowDateTimeWidgetComponent,
  ],
  imports: [
    CommonModule,
    ConfirmDialogModule,
    ContextMenuModule,
    DragDropModule,
    EditorModule,
    FontAwesomeModule,
    FormsModule,
    MatDatepickerModule,
    MatLuxonDateModule,
    MatTabsModule,
    SelectButtonModule,
    TQTimezonesWidgetComponent,
    TQWeekdaysWidgetComponent,
    TreeSelectModule,
  ],
  providers: [  
    ConfirmationService,
  ],
})
export class TasksModule { }
