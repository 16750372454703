import { Component } from '@angular/core';
import { Title } from '@angular/platform-browser'
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { AppState } from 'src/app/appState/app.state';
import { appState } from 'src/app/appState/app.selectors';
import * as APP_ACTIONS from 'src/app/appState/app.actions';

import { Auth0Service } from 'src/app/services/auth0/auth0.service';
import { SamuraiService} from 'src/services/samurai/samurai.service';


@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent
{
  appState: AppState;
  appStateSubs: any;

  environment = this.samApp.getEnvironment();

  email: string;

  constructor
  (
    private titleService: Title,
    private auth0: Auth0Service,
    public samApp: SamuraiService,
    private router: Router,
    private store: Store,
  )
  {
    this.appStateSubs = this.store.select(appState)
      .subscribe( state => {
        this.appState = state 
        if (state.status != 'loaded') return;

        this.getEmail()
        this.setProfile()
    })
  }

  onMobile():boolean
  {
    return this.samApp.onMobile;
  }

  isLoggedIn(): boolean
  {
    return this.auth0.loggedIn;
  }

  getEmail()
  {
    if (this.appState.Auth0Profile == null) return; "";

    this.email = this.appState.Auth0Profile.email
    return this.email
  }

  setProfile()
  {
    if (this.email == null) return;
    
    // Put the email in the tab title
    this.titleService.setTitle("TQ "+this.email)
  }

  gotoProfile()
  {
    this.router.navigate(['/profile']);
  }

  doLogin()
  {
    // Call first Auth0 login, 
    // if successful, then redirect to Loading component
    // this.auth0.login('/signin')
    this.router.navigate(['/signin']);
  }

}
