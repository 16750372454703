<nav *ngIf="appState.sidebarActive"
  class="sidebar"
  [ngClass]="appState.sidebarCollapsed?'sidebarCollapsed':'sidebarExpanded'">

  <div>
    <button
      title="Hide/Show SideBar"
      class="btn btn-sm btn-success sidebar-button"
      (click)="toggleSidebar()">
      <fa-icon *ngIf="!appState.sidebarCollapsed" [icon]="faArrowLeft"></fa-icon>
      <fa-icon *ngIf=" appState.sidebarCollapsed" [icon]="faArrowRight"></fa-icon>
    </button>
  </div>

  <div *ngIf="tqDT.tqDateString"
    class="mt-2">
    <p class="ml-3">{{tqDT.tqDayName}} {{tqDT.tqDateString}}</p>
    <p class="ml-4 pl-1">{{tqDT.tqTimeLongString}}</p>
    <p class="ml-4 pl-2 utcOffset">UTC{{tqDT.tqUTCOffsetString}}</p>  
  </div>

  <div *ngIf="appState.sidebarActive" 
    class="mt-3 pl-3 pr-3">

    <a class="sidebar-role"
      title="Set active role..."
      routerLink="/profile" [queryParams]="{ tab: 'configuration' }">
      {{TQroleSelectedCode}}
    </a>

    <!-- PADS LIST -->
    <ul class="list-unstyled">
      <li>
        <a class="navbar-brand"
        [ngClass]="appState.lastPad=='todopad'?'current':''"
        title="Todopad (Alt-1)"
        routerLink="/todopad">Todopad</a>
      </li>
      <li>
        <a class="navbar-brand"
          [ngClass]="appState.lastPad=='weekpad'?'current':''"
          title="Weekpad (Alt-2)"
          routerLink="/weekpad">Weekpad</a>
      </li>
      <li>
        <a class="navbar-brand"
          [ngClass]="appState.lastPad=='monthpad'?'current':''"
          title="Monthpad (Alt-3)"
          routerLink="/monthpad">Monthpad</a>
      </li>
      <li>
        <a class="navbar-brand"
          [ngClass]="appState.lastPad=='workpad'?'current':''"
          title="Workpad (Alt-4)"
          routerLink="/workpad">Workpad</a>
      </li>
      <hr *ngIf="!appState.sidebarCollapsed">
      <li>
        <a class="navbar-brand"
          [ngClass]="appState.lastPad=='notepad'?'current':''"
          title="Notepad (Alt-5)"
          routerLink="/notepad">Notepad</a>
      </li>
      <hr *ngIf="!appState.sidebarCollapsed">
      <li>
        <a class="navbar-brand"
          [ngClass]="appState.lastPad=='projectpad'?'current':''"
          title="Edit your projects (Alt-6)"
          routerLink="/projectpad">
          Projects
        </a>
      </li>
    </ul>

    <!-- PROJECTS TREE -->
    <ul *ngIf="appState.lastSubscription?.id != null"
      class="mt-1 mat-container list-unstyled">
      <mat-tree [dataSource]="treeSource" [treeControl]="treeControl">
        <!-- Leaf nodes -->
        <mat-tree-node *matTreeNodeDef="let node">
          <div>
            <fa-icon matTreeNodeToggle
              class="mat-tree-icon"
              [icon]="faCaretSquareRight"
              (click)="setProjectTo(node.id, node.code, treeControl.isExpanded(node))">
            </fa-icon>
          </div>
          <li>
            <a class="ml-1"
              [ngClass]="(node.id==appState.projectFilterId)?'current':''"
              (click)="setProjectTo(node.id, node.code, treeControl.isExpanded(node))"
              routerLink="/{{appState.lastPad}}"
              title="{{node.title}}">
              {{node.code}}
            </a>
          </li>
        </mat-tree-node>
        <!-- Expandable nodes -->
        <mat-nested-tree-node *matTreeNodeDef="let node; when: hasChild">
          <div>
            <fa-icon *ngIf="!treeControl.isExpanded(node)" matTreeNodeToggle
              class="mat-tree-icon" style="vertical-align: top"
              [icon]="faPlusSquare">
            </fa-icon>
            <fa-icon *ngIf="treeControl.isExpanded(node)" matTreeNodeToggle
              class="mat-tree-icon" style="vertical-align: top"
              [icon]="faMinusSquare">
            </fa-icon>
            <li style="display:inline-block">
              <a class="ml-1"
                [ngClass]="(node.id==appState.projectFilterId)?'current':''"
                (click)="setProjectTo(node.id, node.code, treeControl.isExpanded(node))"
                routerLink="/{{appState.lastPad}}"
                title="{{node.title}}">
                {{node.code}}
              </a>
              <ul [class.mat-tree-invisible]="!treeControl.isExpanded(node)"
                class="ml-1">
                <ng-container matTreeNodeOutlet></ng-container>
              </ul>
            </li>
          </div>
        </mat-nested-tree-node>
      </mat-tree>
    </ul>

  </div>

  <!-- Reserve space to deal with the fixed size footer -->
  <div class="samFooter"></div>
</nav>
