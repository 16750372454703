import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

import { Store } from '@ngrx/store';
import { AppState } from 'src/app/appState/app.state';
import { appState } from 'src/app/appState/app.selectors';
import * as APP_ACTIONS from 'src/app/appState/app.actions';

import { SamuraiService } from 'src/services/samurai/samurai.service';
import { TQApiService } from 'src/app/services/tqapi.service';

import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';

@Component({
  selector: 'app-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent implements OnInit
{
  faQuestionCircle = faQuestionCircle;

  appState: AppState;
  appStateSubs: any;
  
  constructor
  (
    private router: Router,
    private samApp: SamuraiService,
    private store: Store,
    private tqApi: TQApiService,
  )
  {
    this.appStateSubs = this.store.select(appState)
      .subscribe( state => {
        this.appState = state 
      })

    // Log out after 5 seconds if still on loading page
    this.sleep(5*1000).then(() => {
      this.samApp.trace("LOADING SLEEP OFF lastPad: "+this.appState.lastPad)
      this.tqApi.trace("LOADING SLEEP OFF lastPad: "+this.appState.lastPad)
      if (this.appState.nextPad != 'profile' && 
         (this.appState.lastPad == 'loading' || this.appState.lastPad == 'signin' || this.appState.lastPad == 'signup'))
      {
        this.router.navigate(['/logout']);
      }
    })
  }

  async ngOnInit()
  {
    this.store.dispatch(APP_ACTIONS.enterTQpad( {pad:'loading'} ))
    // this.store.dispatch(APP_ACTIONS.sidebarActive({value:true}))

    this.samApp.trace("LOADING...")   
    this.tqApi.pingLOGIN();
    
    // Do nothing, just display the spinner
    // The RouteGuard will route to the profile's default pad    
  }

  sleep(ms: number): Promise<void> {
    return new Promise(resolve => setTimeout(resolve, ms));
  }


}
