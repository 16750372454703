import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { FormsModule } from '@angular/forms';

import { SelectButtonModule } from 'primeng/selectbutton';

@Component({
  selector: 'tq-weekdays-widget',
  standalone: true,
  imports: [
    FormsModule,
    SelectButtonModule
  ],
  templateUrl: './tq-weekdays-widget.component.html',
  styleUrl: './tq-weekdays-widget.component.scss'
})
export class TQWeekdaysWidgetComponent implements OnInit, OnChanges
{
  @Input({required: true}) weekstart: string;
  @Input({required: true}) weekdays: string; 
  @Output() weekdaysChange = new EventEmitter<string>();

  days = [];

  dow : any[] = [
    { name: "Mon.", value: "Mo" },
    { name: "Tue.", value: "Tu" },
    { name: "Wed.", value: "We" },
    { name: "Thu.", value: "Th" },
    { name: "Fri.", value: "Fr" },
    { name: "Sat.", value: "Sa" },
    { name: "Sun.", value: "Su" },
  ];  

  constructor() 
  {}

  ngOnInit(): void 
  {
    if (this.weekdays.length == 0)
    {
      this.days = [];
    }
    else
    {
      this.days = this.weekdays.split(' ');
    }
  }

  // Parent component changes 
  ngOnChanges()
  {
    if (this.weekdays.length == 0)
    {
      this.days = [];
    }
    else
    {
      this.days = this.weekdays.split(' ');
    }
  } 
  
  // Local changes 
  changeDays() 
  {
    this.weekdaysChange.emit(this.days.join(' '));
  }
  

}
