import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

@Component({
  selector: 'tq-duration-widget',
  templateUrl: './tq-duration-widget.component.html',
  styleUrl: './tq-duration-widget.component.scss'
})

export class TQDurationWidgetComponent 
{
  @Input() duration: string; 
  @Output() durationChange = new EventEmitter<string>();

  hoursLabel: string = null;
  minutesLabel: string = null;

  constructor(){}

  // Parent component changes 
  ngOnChanges()
  {
    if (this.duration == null) 
    {
      this.hoursLabel = null
      this.minutesLabel = null
    }
    else
    {
      let duration = this.duration; // TODO Remove
      this.hoursLabel = this.buildHoursLabel(duration)

      this.minutesLabel = this.buildMinutesLabel(duration)
    }
  } 

  // Local changes 
  changeDuration() 
  {
    if (this.hoursLabel == null   || this.hoursLabel == "")   this.hoursLabel   = "0h";
    if (this.minutesLabel == null || this.minutesLabel == "") this.minutesLabel = "0m";

    this.duration = this.buildDuration(this.hoursLabel, this.minutesLabel);
    this.durationChange.emit(this.duration);
  }

  buildHoursLabel(duration: string): string
  {
    if (duration == "" || duration == null) return "0h";

    let label = duration.split("h")[0] + "h";

    return label;
  }

  buildMinutesLabel(duration: string): string
  {
    if (duration == "" || duration == null) return "0m";

    let label = duration.split("h")[1];

    return label;
  }

  buildDuration(hours: string, minutes: string): string
  {
    if (hours == "" || hours == null) hours = "0h";
    if (minutes == "" || minutes == null) minutes = "0m";

    return hours+minutes;
  }

  getHourLabels(): Array<string>
  {
    var res: Array<string> = ['0h','1h','2h','3h','4h','5h','6h','7h','8h','9h','10h','11h','12h','13h','14h','15h','16h','17h','18h','19h','20h','21h','22h','23h','24h']

    return res;
  }

  getMinuteLabels()
  {
    var res: Array<string> = ['0m','15m','30m','45m']

    return res;
  }

}
