import { Component, HostListener, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { CdkDragDrop, DragRef, DropListRef } from '@angular/cdk/drag-drop';
import { Router } from "@angular/router";

import * as TASK from 'src/app/models/task';

import { Store } from '@ngrx/store';
import { AppState } from 'src/app/appState/app.state';
import * as APP_ACTIONS from 'src/app/appState/app.actions';
import { appState } from 'src/app/appState/app.selectors';
import * as PROJECTS_ACTIONS from 'src/app/projects/store/projects.actions';
import { TasksState } from 'src/app/tasks/store/tasks.state';
import * as TASKS_ACTIONS from 'src/app/tasks/store/tasks.actions';
import { tasksState } from 'src/app/tasks/store/tasks.selectors';

import { SamuraiService } from 'src/services/samurai/samurai.service';
import { TQApiService } from 'src/app/services/tqapi.service';
import { TQDateTimeService } from 'src/app/services/tqdatetime.service';
import { TQSessionService } from 'src/app/services/tqsession.service';

import { DateTime } from 'luxon';

import { TQTaskMenuComponent } from 'src/app/shared/widgets/tq-task-menu/tq-task-menu.component';

import { faArrowUp, faThumbtack, faFilter, faPlus, faSquare, faCaretLeft, faCaretRight,
         faCaretSquareLeft, faCaretSquareRight, faCaretSquareDown, faCaretSquareUp, faExternalLinkAlt }
  from '@fortawesome/free-solid-svg-icons';
import { faQuestionCircle, faCalendarCheck } from '@fortawesome/free-regular-svg-icons';

interface DayOfWeek {
  date: DateTime;
  numberDay: number;
  numberMonth: number;
  numberYear: number;
  nameDay: string;
  isCurrentMonth: boolean;
  isToday: boolean;
  isWeekend: boolean;
}

interface SliceCell {
  date: DateTime;
  numberDay: number;
  numberMonth: number;
  numberYear: number;
  isToday: boolean;
  isTomorrow: boolean;
  isWeekend: boolean;
  tasks: Array<any>;
}

interface TimeSlice {
  sliceHour: number;
  sliceMinute: number;
  days: Array<SliceCell>;
}

interface Week {
  week: number;
  day: number;
  month: number;
  year: number;
  monthName: string;
  firstDate: DateTime;
  days: Array<DayOfWeek>;
  slices: Array<TimeSlice>;
}


@Component({
  selector: 'app-weekpad',
  templateUrl: './weekpad.component.html',
  styleUrls: ['./weekpad.component.scss']
})
export class WeekpadComponent implements OnInit, OnDestroy
{
  componentState = "init";

  appState: AppState;
  appStateSubs: any;
  
  tasksState: TasksState;
  tasksStateSubs: any;
  canAddTask: boolean = true;

  faThumbtack = faThumbtack;
  faFilter = faFilter;
  faPlus = faPlus;
  faArrowUp = faArrowUp;
  faSquare = faSquare;
  faCaretLeft = faCaretLeft;
  faCaretRight = faCaretRight;
  faCaretSquareLeft = faCaretSquareLeft;
  faCaretSquareRight = faCaretSquareRight;
  faCaretSquareUp = faCaretSquareUp;
  faCaretSquareDown = faCaretSquareDown;
  faQuestionCircle = faQuestionCircle;
  faExternalLinkAlt = faExternalLinkAlt;
  faCalendarCheck = faCalendarCheck;
  
  //TQtasks: Array<TASK.TaskSample>;
  
  taskId: number = 0; // Local copy of tasksState.selectedTask
  taskText: string = "";

  weekpadWeek : Week =  {
    week: 0,
    day: 0,
    month: 0,
    year: 0,
    monthName: "",
    firstDate: null,
    days: null,
    slices: null,
  };

  showOngoing: boolean;
  showWeekend: boolean;

  firstHourToScroll: string = "";

  weekpadFilter: boolean = false;
  weekpadFilterRegEx: string  = null;

  TQSession: any;


  constructor
  (
//    private cdr: ChangeDetectorRef,
    private router: Router,
    private store: Store,
    public  samApp: SamuraiService,
    private tqApi: TQApiService,
    public  tqDT: TQDateTimeService,
    private tqSession: TQSessionService,
  ) {}

  async ngOnInit()
  {
    this.store.dispatch(APP_ACTIONS.enterTQpad( {pad:'weekpad'} ))

    this.appStateSubs = this.store.select(appState)
      .subscribe(async state => {
        this.appState = state 
        if (state.status != 'loaded') return;
        if (state.nextPad != 'weekpad') return;

        this.TQSession = this.appState 

        this.showOngoing = this.samApp.retrieveStore("TQweekpad.showOngoing", "session")
        if (this.showOngoing === null)
        {
          this.showOngoing = true;
        }
        this.showWeekend = this.samApp.retrieveStore("TQweekpad.showWeekend", "session")
        if (this.showWeekend === null)
        {
          this.showWeekend = true;
        }
    
        this.weekpadWeek.year = this.samApp.retrieveStore("TQweekpad.year", "session")
        if (this.weekpadWeek.year === null)
        {
          this.weekpadWeek.year = this.tqDT.tqDateTime.year
        }
        this.weekpadWeek.month = this.samApp.retrieveStore("TQweekpad.month", "session")
        if (this.weekpadWeek.month === null)
        {
          this.weekpadWeek.month = this.tqDT.tqDateTime.month;
        }
        this.weekpadWeek.day = this.samApp.retrieveStore("TQweekpad.day", "session")
        if (this.weekpadWeek.day === null)
        {
          this.weekpadWeek.day = this.tqDT.tqDateTime.day;
        }
        this.weekpadWeek.week = this.tqDT.weekOfYear(this.weekpadWeek.day, this.weekpadWeek.month, this.weekpadWeek.year); 
        this.weekpadWeek.firstDate = this.tqDT.firsDateOfWeek(this.weekpadWeek.week, this.weekpadWeek.year)

        if (this.componentState == 'loaded')
        {
          this.canAddTask = this.tqSession.canAddTask()
          
          this.createWeekpad()
          this.buildWeekpadList()
        }
      })

    this.tasksStateSubs = this.store.select(tasksState)
      .subscribe( state => {
        this.tasksState = state 
        if (state.status != 'loaded') return;
        if (this.appState.nextPad != 'weekpad') return;

        if (this.componentState == 'loaded')
        {
          this.canAddTask = this.tqSession.canAddTask()

          // Update local copies
//          this.TQtasks = state.TQtasks; 
          this.taskId=state.selectedTaskId;
  
          this.createWeekpad()
          this.buildWeekpadList()
        }
      })

    this.weekpadFilter=this.samApp.retrieveStore("TQweekpad.filter", "session")
    this.weekpadFilterRegEx=this.samApp.retrieveStore("TQweekpad.filterRegEx", "session")

    this.store.dispatch(TASKS_ACTIONS.loadTasksList());
    this.componentState = 'loaded';
  }
  
  ngOnDestroy()
  {
    this.appStateSubs.unsubscribe();
    this.tasksStateSubs.unsubscribe();
  }

  @HostListener('document:keydown.control.f', ['$event'])
  CtrlF(event: any)
  {
    event.preventDefault();
    this.clearWeekpadFilter();
    this.weekpadFilter = true;

    // Force update after processing the key
    setTimeout( () =>
      {
        this.createWeekpad()
        this.buildWeekpadList()
      },0)

    this.samApp.saveStore("TQweekpad.filter", this.weekpadFilter, "session")
    this.samApp.saveStore("TQweekpad.filterRegEx", this.weekpadFilterRegEx, "session")
  }
  @HostListener('document:keydown', ['$event'])
  KeyDownOnFilter(event: any)
  {
    if (this.weekpadFilter == false) return;

    // Force update after processing the key
    setTimeout( () =>
      {
        this.createWeekpad()
        this.buildWeekpadList()
      },0)

    this.samApp.saveStore("TQweekpad.filter", this.weekpadFilter, "session")
    this.samApp.saveStore("TQweekpad.filterRegEx", this.weekpadFilterRegEx, "session")
  }
  @HostListener('document:keydown.escape', ['$event'])
  Escape(event: any)
  {
    event.preventDefault();
    if (this.weekpadFilter == true)
    {
      this.clearWeekpadFilter();
      this.weekpadFilter = false;

      // Force update after processing the key
      setTimeout( () =>
      {
        this.createWeekpad()
        this.buildWeekpadList()
      },0)

      this.samApp.saveStore("TQweekpad.filter", this.weekpadFilter, "session")
      this.samApp.saveStore("TQweekpad.filterRegEx", this.weekpadFilterRegEx, "session")
    }
    else if (this.taskId == 0)
    {
      this.clearProjectFilter()
    }
    else     
    {
      this.store.dispatch(TASKS_ACTIONS.selectTask({id: 0}))   
      this.scrollToPaneTitle()
      this.scrollToTop()
    }
  }

  @HostListener('document:keydown.home', ['$event'])
  ThisWeek(event: any)
  {
    event.preventDefault();
//    this.scrollToTop()
    this.goThisWeek()
  }
  @HostListener('document:keydown.pageUp', ['$event'])
  PreviousWeek(event: any)
  {
    event.preventDefault();
//    this.scrollToTop()
    this.goPreviousWeek()
  }
  @HostListener('document:keydown.pageDown', ['$event'])
  NextWeek(event: any)
  {
    event.preventDefault();
//    this.scrollToTop()
    this.goNextWeek()
  }


  canDrop(drag: DragRef, drop: DropListRef)
  {
    return true;

    // let allow: boolean = true;

    // // Do not allow move to other day slices for pinned tasks
    // if (drag.data.isPinned)
    // {
    //   if (drag.data.numberDay != drop.data[1].numberDay)
    //   {
    //     allow = false
    //   }
    // }

    // return allow;
   }

  drop(event: CdkDragDrop<string[]>)
  {
    if (event.previousContainer === event.container) return;

    if (this.checkLimits(event) == false) return;

    this.updateTask(event.item.data, "drop", event.container.data);

    this.store.dispatch(TASKS_ACTIONS.selectTask({id: event.item.data}))
  }

  checkLimits(event):boolean
  {
    // Allow moving tasks when it is a start and end event in same cell
    if (event.item.data.isStart && event.item.data.isEnd)
    {
      return true
    }

    let startDate = this.tqDT.toLuxon(event.item.data.startDay)
    let startTime = this.tqDT.timeTo24h(event.item.data.startTime)

    let endDate = this.tqDT.toLuxon(event.item.data.endDay)
    let endTime = this.tqDT.timeTo24h(event.item.data.endTime)

    let date = event.container.data[1].numberYear+"-"
             + event.container.data[1].numberMonth.toString().padStart(2, '0')+"-"
              + event.container.data[1].numberDay.toString().padStart(2, '0')
    let dropDate = this.tqDT.toLuxonFromISO(date)

    let time = event.container.data[0].sliceHour.toString().padStart(2, '0')+":"
             + event.container.data[0].sliceMinute.toString().padStart(2, '0')
    let dropTime = this.tqDT.timeTo24h(time)

    let startDateTime = this.tqDT.formatDateISO(startDate) + "T" + startTime
    let endDateTime   = this.tqDT.formatDateISO(endDate)   + "T" + endTime
    let dropDateTime  = this.tqDT.formatDateISO(dropDate)  + "T" + dropTime
    
    // Do not allow to move before start or after end
    if (event.item.data.isStart)
    {
      if (dropDateTime > endDateTime) return false
    }
    if (event.item.data.isEnd)
    {
      if (dropDateTime < startDateTime) return false
    }

    return true
  }

  scrollToPaneTitle()
  {
    document.getElementById("weekpadPane").scrollIntoView({ behavior: 'smooth' });
  }
  
  scrollToTop(delay: number = 0)
  {
    // this.cdr.detach();   
    // setTimeout( () => {
    //   document.getElementById("header").scrollIntoView({ behavior: 'smooth' })
    //   this.cdr.reattach();   
    // }, delay)
  }

  clearToTop()
  {
    this.store.dispatch(TASKS_ACTIONS.selectTask({id: 0}))   
    this.scrollToPaneTitle()
//    this.scrollToTop(1000)
  }

  scrollToTask()
  {
    // Do not scroll until data is ready
    if (this.tasksState.status != 'loaded') return;

    // Do not scroll when filter is active
    if (this.weekpadFilter == true) return;

    // No task where to scroll
    if (this.taskId == null) return;

    // If no taskId (e.g. a deletion) position to first task
    if (this.taskId == 0)
    {
      // Search hour to scroll
      var elem = document.getElementById("Hour-"+this.firstHourToScroll);
      if (elem != null)
      {
        elem.scrollIntoView({inline: 'start', block: 'center'});
//        this.scrollToTop()
      }
    }
    // If there exists taskId, position by scrollIntoView
    else
    {
      // Search taskId in week
      var elem = document.getElementById(this.taskId.toString());
      if (elem != null)
      {
        elem.scrollIntoView({inline: 'start', block: 'center'});
//        this.scrollToTop()
      }
    }
  }


  goThisWeek()
  {
    this.weekpadWeek.year = this.tqDT.tqDateTime.year
    this.weekpadWeek.month = this.tqDT.tqDateTime.month
    this.weekpadWeek.day = this.tqDT.tqDateTime.day
    this.weekpadWeek.monthName = this.tqDT.formatMonthName(this.tqDT.tqDateTime)
    this.weekpadWeek.week = this.tqDT.weekOfYear(this.weekpadWeek.day, this.weekpadWeek.month, this.weekpadWeek.year); 
    this.weekpadWeek.firstDate = this.tqDT.firsDateOfWeek(this.weekpadWeek.week, this.weekpadWeek.year)

    this.samApp.saveStore("TQweekpad.year", this.weekpadWeek.year, "session")
    this.samApp.saveStore("TQweekpad.month", this.weekpadWeek.month, "session")
    this.samApp.saveStore("TQweekpad.day", this.weekpadWeek.day, "session")

    this.createWeekpad()
    this.buildWeekpadList()
  }

  goPreviousWeek()
  {
    let previousWeek = this.weekpadWeek.firstDate.minus({weeks: 1})

    this.weekpadWeek.year = previousWeek.year
    this.weekpadWeek.month = previousWeek.month
    this.weekpadWeek.day = previousWeek.day
    this.weekpadWeek.monthName = this.tqDT.formatMonthName(previousWeek)
    this.weekpadWeek.week = this.tqDT.weekOfYear(this.weekpadWeek.day, this.weekpadWeek.month, this.weekpadWeek.year); 
    this.weekpadWeek.firstDate = this.tqDT.firsDateOfWeek(this.weekpadWeek.week, this.weekpadWeek.year)
                                
    this.samApp.saveStore("TQweekpad.year", this.weekpadWeek.year, "session")
    this.samApp.saveStore("TQweekpad.month", this.weekpadWeek.month, "session")
    this.samApp.saveStore("TQweekpad.day", this.weekpadWeek.day, "session")

    this.createWeekpad()
    this.buildWeekpadList()
  }

  goNextWeek()
  {
    let nextWeek = this.weekpadWeek.firstDate.plus({weeks: 1})

    this.weekpadWeek.year = nextWeek.year
    this.weekpadWeek.month = nextWeek.month
    this.weekpadWeek.day = nextWeek.day
    this.weekpadWeek.monthName = this.tqDT.formatMonthName(nextWeek)
    this.weekpadWeek.week = this.tqDT.weekOfYear(this.weekpadWeek.day, this.weekpadWeek.month, this.weekpadWeek.year); 
    this.weekpadWeek.firstDate = this.tqDT.firsDateOfWeek(this.weekpadWeek.week, this.weekpadWeek.year)
                                
    this.samApp.saveStore("TQweekpad.year", this.weekpadWeek.year, "session")
    this.samApp.saveStore("TQweekpad.month", this.weekpadWeek.month, "session")
    this.samApp.saveStore("TQweekpad.day", this.weekpadWeek.day, "session")

    this.createWeekpad()
    this.buildWeekpadList()
  }

  createWeekpad()
  {
//    this.cdr.detach();

    this.weekpadWeek.year = this.samApp.retrieveStore("TQweekpad.year", "session")
    if (this.weekpadWeek.year === null)
    {
      this.weekpadWeek.year = this.tqDT.tqDateTime.year
    }
    this.weekpadWeek.month = this.samApp.retrieveStore("TQweekpad.month", "session")
    if (this.weekpadWeek.month === null)
    {
      this.weekpadWeek.month = this.tqDT.tqDateTime.month;
    }
    this.weekpadWeek.day = this.samApp.retrieveStore("TQweekpad.day", "session")
    if (this.weekpadWeek.day === null)
    {
      this.weekpadWeek.day = this.tqDT.tqDateTime.day;
    }
    this.samApp.saveStore("TQweekpad.year", this.weekpadWeek.year, "session")
    this.samApp.saveStore("TQweekpad.month", this.weekpadWeek.month, "session")
    this.samApp.saveStore("TQweekpad.day", this.weekpadWeek.day, "session")

    let weekpadDate = DateTime.fromObject({day: this.weekpadWeek.day, month: this.weekpadWeek.month, year: this.weekpadWeek.year})
    
    this.weekpadWeek.week = this.tqDT.weekOfYear(this.weekpadWeek.day, this.weekpadWeek.month, this.weekpadWeek.year)
    this.weekpadWeek.firstDate = this.tqDT.firsDateOfWeek(this.weekpadWeek.week, this.weekpadWeek.year)
    this.weekpadWeek.monthName = this.tqDT.formatMonthName(weekpadDate)

    // TODO move to TQDateTime
    let firstDay=this.TQSession['prefLocWeekStart']
    if (firstDay == null) firstDay="Sunday"
    let dow;
    switch (firstDay)
    {
      case "Friday"  : dow = [ "Fri.", "Sat.", "Sun.", "Mon.", "Tue.", "Wed.", "Thu.", ]; break
      case "Saturday": dow = [ "Sat.", "Sun.", "Mon.", "Tue.", "Wed.", "Thu.", "Fri.", ]; break
      case "Sunday"  : dow = [ "Sun.", "Mon.", "Tue.", "Wed.", "Thu.", "Fri.", "Sat.", ]; break
      case "Monday"  : dow = [ "Mon.", "Tue.", "Wed.", "Thu.", "Fri.", "Sat.", "Sun.", ]; break
      default        : dow = [ "Sat.", "Sun.", "Mon.", "Tue.", "Wed.", "Thu.", "Fri.", ]; break
    }

    //--- Start at the week number of the first day of the week

    // Prepare days in the week
    let days: Array<DayOfWeek> = Array(7);
    for (let d=0; d<7; d++)
    {
      // Add d days to first day of the week
      let thisDate = this.weekpadWeek.firstDate.plus({days: d}) 
      let day: DayOfWeek = {
        date: thisDate,
        nameDay: dow[d],
        numberDay: thisDate.day,
        numberMonth: thisDate.month,
        numberYear: thisDate.year,
        isCurrentMonth: false,
        isToday: false,
        isWeekend: false,
      };
      // Mark current month
      if (day.numberYear  == this.weekpadWeek.year && 
          day.numberMonth == this.weekpadWeek.month)
      {
        day.isCurrentMonth = true;
      }
      // Mark today
      if (day.numberYear  == this.tqDT.tqDateTime.year  &&
          day.numberMonth == this.tqDT.tqDateTime.month &&
          day.numberDay   == this.tqDT.tqDateTime.day)
      {
        day.isToday = true;
      }
      // Mark weekends
      day.isWeekend = this.tqDT.isWeekend(day.date)

      days[d] = day
    }
    this.weekpadWeek.days = days;

    //--- Prepare time slices in this week
    let sliceResolution = 1;
    let numSlices = 24*sliceResolution+1
    let slices: Array<TimeSlice> = Array(numSlices);
    this.weekpadWeek.slices = slices;

    // Add one extra hour to the pad for no exeTime tasks
    for (let s=0; s<numSlices; s++)
    {     
      let sliceHour = Math.floor(s/sliceResolution)
      let sliceMinute = (s % sliceResolution)?30:0
      let emptyDays: Array<SliceCell> = Array(7);

      let slice: TimeSlice = {
        sliceHour: sliceHour,
        sliceMinute: sliceMinute,
        days: emptyDays,
        }

      // Fill the empty days
      for (let d=0; d<7; d++)
      {
        slice.days[d] = {
          date: this.weekpadWeek.days[d].date,
          numberDay: this.weekpadWeek.days[d].numberDay,
          numberMonth: this.weekpadWeek.days[d].numberMonth,
          numberYear: this.weekpadWeek.days[d].numberYear,
          isToday: this.weekpadWeek.days[d].isToday,
          isTomorrow: false,
          isWeekend: this.weekpadWeek.days[d].isWeekend,
          tasks: [],
        }

        // Mark tomorrow
        let sliceDate=slice.days[d].numberYear.toString() + "-" +
                      slice.days[d].numberMonth.toString().padStart(2, '0') + "-" +
                      slice.days[d].numberDay.toString().padStart(2, '0')
        if (sliceDate == this.tqDT.formatDateISO(this.tqDT.tqDateTime.plus({days: 1}))) 
        {
          slice.days[d].isTomorrow = true;
        }
      }

      this.weekpadWeek.slices[s] = slice;
    }

//    this.cdr.reattach();
  }

  buildWeekpadList()
  {
//    if (this.TQtasks == null) return;
    if (this.tasksState.TQtasks == null) return;

//    this.cdr.detach();

    this.firstHourToScroll = "0"; //"24"

    // Show tasks ordered by project code
    let tasks: any[] = this.tasksState.TQtasks; // Array.from(this.TQtasks)
    
    //   .sort((a, b) => {
    //     if (a.project_code == b.project_code) return 0;
    //     return (a.project_code < b.project_code ? -1 : 1)
    //   }
    // )
    
    for (var i = 0; i < tasks.length; i++)
    {
      var t=tasks[i];

      // Filter by project list
      if (this.appState.projectFilterCode != '')
      {
        if (this.appState.projectFilterList.indexOf(t.project_id) < 0) continue;
      }

      // Ignore filtered tasks
      if (this.filterTask(t)) continue;

      // Test for Pinned Today
      if (t.relDate == "+0d")
      {
        if (t.status != "done" && t.status != "canceled")
        {
          this.weekpadWeek.slices.forEach(slice =>
            {
              let taskHour = 24
              if (t.exeTime != null)
              {
                taskHour = parseInt(t.exeTime.split(":")[0])
              }
              if (slice.sliceHour == taskHour)
              {
                slice.days.forEach(day =>
                  {
                    if (day.isToday)
                    {
                      this.loadTask(t, day, "pinned")

                      let hour = "24"
                      if (t.exeTime != null)
                      {
                          hour = t.exeTime.split(":")[0]
                      }
                      if ( hour < this.firstHourToScroll)
                      {
                        this.firstHourToScroll = hour.toString()
                      }
                    }
                  })
              }
            })
        }
      }

      // Test for pinned next day
      if (t.relDate == "+1d")
      {
        if (t.status != "done" && t.status != "canceled")
          {
          let day = null;
          this.weekpadWeek.slices.forEach(slice =>
            {
              let taskHour = 24
              if (t.exeTime != null)
              {
                taskHour = parseInt(t.exeTime.split(":")[0])
              }
              if (slice.sliceHour == taskHour)
              {
                slice.days.forEach(day =>
                  {
                    if (day.isTomorrow)
                    {
                      this.loadTask(t, day, "pinned")

                      let hour = "24"
                      if (t.exeTime != null)
                      {
                        hour = t.exeTime.split(":")[0]
                      }
                      if (hour < this.firstHourToScroll)
                      {
                        this.firstHourToScroll = hour.toString()
                      }

                    }
                  })
                }
              })
        }
      }

      // Test for Start event
      if (t.startWorkDate)
      {
        if (this.tqDT.isSameDateOrAfter(t.startWorkDate, this.tqDT.formatDate(this.weekpadWeek.firstDate)) && 
            this.tqDT.isDateBefore(t.startWorkDate, this.tqDT.formatDate(this.weekpadWeek.firstDate.plus({days: 7}))))  
        {
          let date = this.tqDT.toLuxon(t.startWorkDate).day

          this.weekpadWeek.slices.forEach(slice =>
          {
            let taskHour = 24
            if (t.startWorkTime != null)
            {
              taskHour = parseInt(t.startWorkTime.split(":")[0])
            }
            if (slice.sliceHour == taskHour)
            {
              slice.days.forEach(day =>
              {
                if (day.numberDay == date)
                {
                  this.loadTask(t, day, "start")

                  let hour = "24"
                  if (t.exeTime != null)
                  {
                    hour = t.exeTime.split(":")[0]
                  }

                  if (hour < this.firstHourToScroll)
                  {
                    this.firstHourToScroll = hour.toString()
                  }
                }
              })
            }
          })
        }
      }

      // Test for End event
      if (t.endWorkDate)
      {
        if (this.tqDT.isSameDateOrAfter(t.endWorkDate, this.tqDT.formatDate(this.weekpadWeek.firstDate)) && 
            this.tqDT.isDateBefore(t.endWorkDate, this.tqDT.formatDate(this.weekpadWeek.firstDate.plus({days: 7}))))
        {
          let date = this.tqDT.toLuxon(t.endWorkDate).day

          this.weekpadWeek.slices.forEach(slice =>
          {
            let taskHour = 24
            if (t.endWorkTime != null)
            {
              taskHour = parseInt(t.endWorkTime.split(":")[0])
            }
            if (slice.sliceHour == taskHour)
            {
              slice.days.forEach(day =>
              {
                if (day.numberDay == date)
                {
                  this.loadTask(t, day, "end")

                  let hour = "24"
                  if (t.exeTime != null)
                  {
                    hour = t.exeTime.split(":")[0]
                  }

                  if (hour < this.firstHourToScroll)
                  {
                    this.firstHourToScroll = hour.toString()
                  }
                }
              })
            }
          })
        }
      }

      // Test for Due event
      if (t.dueDate)
      {
        if (this.tqDT.isSameDateOrAfter(t.dueDate, this.tqDT.formatDate(this.weekpadWeek.firstDate)) && 
            this.tqDT.isDateBefore(t.dueDate, this.tqDT.formatDate(this.weekpadWeek.firstDate.plus({days: 7}))))
        {
          let date = this.tqDT.toLuxon(t.dueDate).day

          this.weekpadWeek.slices.forEach(slice =>
          {
            let taskHour = !!t.dueTime?t.dueTime.split(":")[0]:24;
            if (slice.sliceHour == taskHour)
            {
              slice.days.forEach(day =>
              {
                if (day.numberDay == date)
                {
                  this.loadTask(t, day, "due")

                  let hour = "24"
                  if (t.exeTime != null)
                  {
                    hour = t.exeTime.split(":")[0]
                  }

                  if ( hour < this.firstHourToScroll)
                  {
                    this.firstHourToScroll = hour.toString()
                  }
                }
              })
            }
          })
        }
      }

      // Test for Target event
      if (t.targetDate)
      {
        if (this.tqDT.isSameDateOrAfter(t.targetDate, this.tqDT.formatDate(this.weekpadWeek.firstDate)) && 
            this.tqDT.isDateBefore(t.targetDate, this.tqDT.formatDate(this.weekpadWeek.firstDate.plus({days: 7}))))
        {
          let date = this.tqDT.toLuxon(t.targetDate).day

          this.weekpadWeek.slices.forEach(slice =>
          {
            let taskHour = !!t.targetTime?t.targetTime.split(":")[0]:24;
            if (slice.sliceHour == taskHour)
            {
              slice.days.forEach(day =>
              {
                if (day.numberDay == date)
                {
                  this.loadTask(t, day, "target")

                  let hour = "24"
                  if (t.exeTime != null)
                  {
                    hour = t.exeTime.split(":")[0]
                  }

                  if ( hour < this.firstHourToScroll)
                  {
                    this.firstHourToScroll = hour.toString()
                  }
                }
              })
            }
          })
        }
      }

      // Load task as todo or ongoing 
      if (this.showOngoing) 
      {
        this.weekpadWeek.slices.forEach(slice =>
        {
          let taskHour = 24
          if (t.exeTime != null)
          {
            taskHour = parseInt(t.exeTime.split(":")[0])
          }
          if (slice.sliceHour == taskHour)
          {
            slice.days.forEach(day =>
            {
              let startHour = taskHour.toString()
              if (startHour == "24") startHour = "00" 
              if (this.tqDT.isDateTimeBefore(this.tqDT.formatDateISO(day.date), startHour+":00",
                                             this.tqDT.formatDateISO(this.tqDT.toLuxon(t.startWorkDate)), t.startWorkTime)) 
              {
                return;
              }

              let endHour = taskHour.toString()
              if (endHour == "24") startHour = "00" 
              if (this.tqDT.isDateTimeAfter(this.tqDT.formatDateISO(day.date), endHour+":00", 
                                            this.tqDT.formatDateISO(this.tqDT.toLuxon(t.endWorkDate)), t.endWorkTime)) 
              {
                return;
              }

              if (t.relDate == "+0d" || t.relDate == "+1d") return;

              if (TASK.hasRepetitionOnNumberDay(t) && !TASK.taskRepeatsThisNumberDay(t, day.numberDay)) return;
              if (TASK.hasRepetitionOnMonths(t)    && !TASK.taskRepeatsThisMonth(t, day.numberMonth) ) return;
              if (TASK.hasRepetitionOnWeekdays(t)  && !TASK.taskRepeatsThisWeekday(t, day.date.weekday) ) return;

              if (t.status == "todo")
              {
                // Ignore task with no definite start or end date
                if (t.startWorkDate == "" && t.endWorkDate == "" && (t.exeTime == "" || t.exeTime == null) ) return; 

                // Ignore task with no exeTime
                if (t.exeTime == "" || t.exeTime == null) return; 

                this.loadTask(t, day, "todo")
              }
              if (t.status == "doing")
              {
                this.loadTask(t, day, "ongoing")
              }

              // Get the first hour in the pad to scroll
              let hour = "24"
              if (t.exeTime != null)
              {
                hour = t.exeTime.split(":")[0]
              }

              if (hour < this.firstHourToScroll)
              {
                this.firstHourToScroll = hour.toString()
              }
            })
          }
        })
      }
    }

    setTimeout( () => this.scrollToTask(), 0)

//    this.cdr.reattach();
  }

  loadTask(t: any, day: any, attribute: string)
  {
    // Determine the minute for this task case
    let taskHour = 0
    let taskMinute = 0
    switch (attribute)
    {
      case "pinned":
        taskHour   = parseInt(!!t.exeTime ? t.exeTime.split(":")[0] : 0);
        taskMinute = parseInt(!!t.exeTime ? t.exeTime.split(":")[1] : 0);
        break;
      case "start":
        taskHour   = parseInt(!!t.startWorkTime ? t.startWorkTime.split(":")[0] : 0);
        taskMinute = parseInt(!!t.startWorkTime ? t.startWorkTime.split(":")[1] : 0);
        break;
      case "end":
        taskHour   = parseInt(!!t.endWorkTime ? t.endWorkTime.split(":")[0] : 0);
        taskMinute = parseInt(!!t.endWorkTime ? t.endWorkTime.split(":")[1] : 0);
        break;
      case "due":
        taskHour   = parseInt(!!t.dueTime ? t.dueTime.split(":")[0] : 0);
        taskMinute = parseInt(!!t.dueTime ? t.dueTime.split(":")[1] : 0);
        break;
      case "target":
        taskHour   = parseInt(!!t.targetTime ? t.targetTime.split(":")[0] : 0);
        taskMinute = parseInt(!!t.targetTime ? t.targetTime.split(":")[1] : 0);
        break;
      case "ongoing":
        taskHour   = parseInt(!!t.exeTime ? t.exeTime.split(":")[0] : 0);
        taskMinute = parseInt(!!t.exeTime ? t.exeTime.split(":")[1] : 0);
        break;
      case "todo":
        taskHour   = parseInt(!!t.exeTime ? t.exeTime.split(":")[0] : 0);
        taskMinute = parseInt(!!t.exeTime ? t.exeTime.split(":")[1] : 0);
        break;
    }

    // Find the task in the day or add an empty one
    let task = day.tasks.find(e => e.id == t.id && e.sliceHour == taskHour && e.sliceMinute == taskMinute )
    if (!task)
    {
      task = {
        id: t.id,
        title: t.title,
        numberDay: day.numberDay,
        sliceHour: taskHour,
        sliceMinute: taskMinute,
        isStart: false,
        isEnd: false,
        isDue: false,
        isTarget: false,
        isPinned: false,
        isOngoing: false,
        shortDuration: this.tqDT.shortDuration(t.exeDuration),
        status: t.status,
        project_code: t.project_code,
        project_color: t.project_color,
        // Save start and end datetime to check drop limits
        startDay: t.startWorkDate,
        startTime: t.startWorkTime,
        endDay: t.endWorkDate,
        endTime: t.endWorkTime,
        // Save timezones
        startWorkTZ: t.startWorkTZ,
        endWorkTZ: t.endWorkTZ,
        dueTZ: t.dueTZ,
        targetTZ: t.targetTZ,
        exeTZ: t.exeTZ,
      }
    }
    // Set task attributes
    switch (attribute)
    {
      case "pinned":
        task.isPinned = true;
        break;
      case "start":
        task.isStart = true;
        break;
      case "end":
        task.isEnd = true;
        break;
      case "due":
        task.isDue = true;
        break;
      case "target":
        task.isTarget = true;
        break;
      case "ongoing":
        task.isOngoing = true;
        break;
    }

    // Mark if is an external task
    if (t.origin != null)
    {
      task.isExternal = true;
    }
            
    // Remove the task from the tasks in this day
    day.tasks = day.tasks.filter( e => !(e.id == t.id && e.sliceMinute == taskMinute) )

    // Add the modified task back to the day and order by sliceMinute
    day.tasks.push(task)

    // TODO Take this sort out of each loadTask ???
    day.tasks.sort((a, b) =>
      {
        if (a.sliceMinute > b.sliceMinute) return 1;
        if (a.sliceMinute < b.sliceMinute) return -1;
      })
  }

  addTask(s: any, d: any)
  {
    // Leaving component
    this.store.dispatch(APP_ACTIONS.nextTQpad({ pad:'task' })) 

    this.taskId=0;
    this.taskText="";
    this.store.dispatch(TASKS_ACTIONS.selectTask({id: 0}))

    this.samApp.saveStore("TQweekpad.year", this.weekpadWeek.year, "session")
    this.samApp.saveStore("TQweekpad.month", this.weekpadWeek.month, "session")
    this.samApp.saveStore("TQweekpad.day", this.weekpadWeek.day, "session")

    this.samApp.saveStore("TQweekpad.filter", this.weekpadFilter, "session")
    this.samApp.saveStore("TQweekpad.filterRegEx", this.weekpadFilterRegEx, "session")

    this.router.navigate(['/task', this.taskId],
      { queryParams: { 
          from: "weekpad", 
          YYYY: d["numberYear"], 
          MM: d["numberMonth"], 
          DD: d["numberDay"], 
          HH: s['sliceHour']
        }
      }
    )
  }

  selectTask(event: any, t: any)
  {
    setTimeout(() => {
      //event.preventDefault();
      //TODO mark task, not select!! Prevetns moving tasks!
    //  this.taskId=t["id"];
    //  this.store.dispatch(TASKS_ACTIONS.selectTask({id: this.taskId})) 
      },750)
  }

  editTask(event: any, t?: any)
  {
    // Just select the task if Ctrl key is pressed
    if (event && event.ctrlKey) 
    {
      //event.preventDefault();
      this.taskId=t["id"];
      this.store.dispatch(TASKS_ACTIONS.selectTask({id: this.taskId}))
      return;
    }

    if (t == null)
    {
      this.taskId=0;
      this.taskText="";
    }
    else
    {
      this.taskId=t["id"];
      this.taskText=t["title"];
    }
    // Leaving component
    this.store.dispatch(APP_ACTIONS.nextTQpad({ pad:'task' })) 

    this.store.dispatch(TASKS_ACTIONS.selectTask({id: this.taskId}))

    this.samApp.saveStore("TQweekpad.year", this.weekpadWeek.year, "session")
    this.samApp.saveStore("TQweekpad.month", this.weekpadWeek.month, "session")
    this.samApp.saveStore("TQweekpad.day", this.weekpadWeek.day, "session")

    this.samApp.saveStore("TQweekpad.filter", this.weekpadFilter, "session")
    this.samApp.saveStore("TQweekpad.filterRegEx", this.weekpadFilterRegEx, "session")

    this.router.navigate(['/task', this.taskId])
  }

  async updateTask
  (
    t: any,
    attribute: string,
    value: string | any[],
  )
  {
    var TQTask = {
      "id"         : t,
      "profile_id" : this.appState.TQprofileId,
    }
    if (attribute=="status")
    {
      TQTask['status']=value
    }
    if (attribute=="priority")
    {
      TQTask['priority']=value
    }
    if (attribute=="relDate")
    {
      if (value == "not pinned") 
      {
        TQTask['relDate']='future'
      }
      if (value == "current day") 
      {
        TQTask['relDate']='+0d'
      }
      if (value == "next day") 
      {
        TQTask['relDate']='+1d'
      }
    }
    if (attribute=="drop")
    {
      // Get the taskId from the full task t
      TQTask['id']=t.id;

      // New date
      let newDateISO = this.tqDT.formatDateISO(this.weekpadWeek.days.find(d => d.numberDay==value[1].numberDay).date)

      // New time. Keep same minute after moving the task
      let newHour   = value[0].sliceHour.toString().padStart(2, '0')
      let newMinute = t.sliceMinute.toString().padStart(2, '0')
      let newTime = newHour+":"+newMinute;
      if (newHour == "24")
      {
        newTime = "";
      }

      // Update task events
      if (t.isStart)
      {
        TQTask['startWorkDate'] = newDateISO; 
        TQTask['startWorkTime'] = newTime;
        if (t.startWorkTZ != null && t.startWorkTZ != "N")  
          {
            let dateTimeTZ = this.tqDT.toLuxonFromISO(newDateISO, newTime, this.TQSession.prefLocTimeZone).setZone(t.startWorkTZ)
            TQTask['startWorkTZ'] = t.startWorkTZ
            TQTask['startWorkTZDate'] = this.tqDT.formatDateISO(dateTimeTZ)
            TQTask['startWorkTZTime'] = this.tqDT.formatTimeISO(dateTimeTZ)
          }            
      }
      if (t.isEnd)
      {
        TQTask['endWorkDate'] = newDateISO;
        TQTask['endWorkTime'] = newTime;
        if (t.endWorkTZ != null && t.endWorkTZ != "N")  
          {
            let dateTimeTZ = this.tqDT.toLuxonFromISO(newDateISO, newTime, this.TQSession.prefLocTimeZone).setZone(t.endWorkTZ)
            TQTask['endWorkTZ'] = t.endWorkTZ
            TQTask['endWorkTZDate'] = this.tqDT.formatDateISO(dateTimeTZ)
            TQTask['endWorkTZTime'] = this.tqDT.formatTimeISO(dateTimeTZ)
          }            
      }
      if (t.isDue)
      {
        TQTask['dueDate'] = newDateISO;
        TQTask['dueTime'] = newTime;
        if (t.dueTZ != null && t.dueTZ != "N")  
          {
            let dateTimeTZ = this.tqDT.toLuxonFromISO(newDateISO, newTime, this.TQSession.prefLocTimeZone).setZone(t.dueTZ)
            TQTask['dueTZ'] = t.dueTZ
            TQTask['dueTZDate'] = this.tqDT.formatDateISO(dateTimeTZ)
            TQTask['dueTZTime'] = this.tqDT.formatTimeISO(dateTimeTZ)
          }            
        }
      if (t.isTarget)
      {
        TQTask['targetDate'] = newDateISO;
        TQTask['targetTime'] = newTime;
        if (t.targetTZ != null && t.targetTZ != "N")  
        {
          let dateTimeTZ = this.tqDT.toLuxonFromISO(newDateISO, newTime, this.TQSession.prefLocTimeZone).setZone(t.targetTZ)
          TQTask['targetTZ'] = t.targetTZ
          TQTask['targetTZDate'] = this.tqDT.formatDateISO(dateTimeTZ)
          TQTask['targetTZTime'] = this.tqDT.formatTimeISO(dateTimeTZ)
        }            
      }
      // Update task exeTime
      if (!t.isStart && !t.isEnd && !t.isDue && !t.isTarget)
      {
        TQTask['exeTime'] = newTime;
        TQTask['exeTZ'] = t.exeTZ
        if (t.exeTZ != null && t.exeTZ != "N")  
        {
          let dateTimeTZ = this.tqDT.toLuxonFromISO(this.tqDT.tqDateISOString, newTime, this.TQSession.prefLocTimeZone).setZone(t.exeTZ)
          TQTask['exeTZTime'] = this.tqDT.formatTimeISO(dateTimeTZ)
        }
      }
    }

    // TODO catch
    let res = await this.tqApi.updateTask(TQTask)

    this.store.dispatch(TASKS_ACTIONS.selectTask({id: res['id']}))
    this.store.dispatch(TASKS_ACTIONS.loadTasksList());

    this.createWeekpad();
    this.buildWeekpadList();
  }

  async duplicateTask(taskId: any)
  {
    let res = await this.tqApi.duplicateTask(taskId);

    this.store.dispatch(TASKS_ACTIONS.selectTask({id: res['id']}))

    this.store.dispatch(TASKS_ACTIONS.loadTasksList());
    this.createWeekpad();
    this.buildWeekpadList();
  }


  isNowSlice(s)
  {
    return (s.sliceHour == this.tqDT.tqDateTime.hour)
  }

  isTodayColumn(d)
  {
    return (d.numberDay == this.tqDT.tqDateTime.day && d.numberMonth == this.tqDT.tqDateTime.month && d.numberYear == this.tqDT.tqDateTime.year)
  }

  getDaysOfWeek()
  {
    if (!!!this.weekpadWeek.days) return;

    if (this.showWeekend)
    {
      return this.weekpadWeek.days
    }
    else
    {
      return this.weekpadWeek.days.filter(d => d.isWeekend == false);
    }
  }

  getSliceHour(s)
  {
    let label = "24"
    if (s.sliceHour < 24)
    {
      label = s.sliceHour.toString().padStart(2, '0')
    }
    return label
  }

  getSliceLabel(s)
  { 
    if (s.sliceHour < 24)
    {
      return this.tqDT.formatClockTime(s.sliceHour, "", this.tqSession['prefLocTimeFormat'] == "24h")
    }
    else
    {
      return "To Do"
    }
  }

  getSlicesOfWeek()
  {
    return this.weekpadWeek.slices;
  }

  getDaysOfSlice(s:any)
  {
    if (this.showWeekend)
    {
      return this.weekpadWeek.slices[s].days;
    }
    else
    {
      return this.weekpadWeek.slices[s].days.filter(d => d.isWeekend == false);
    }

  }

  getTasksOfDay(s: number, d: number)
  {
    return this.weekpadWeek.slices[s].days[d].tasks
  }

  gotoTodopad(d:any)
  {
    let date = this.tqDT.formatDateISO(d.date) 
    this.router.navigate(['todopad'], { queryParams: {date:date} })
  }

  toggleOngoing()
  {
    this.showOngoing = ! this.showOngoing
    this.samApp.saveStore("TQweekpad.showOngoing", this.showOngoing, "session")

    this.createWeekpad()
    this.buildWeekpadList()
  }

  toggleWeekend()
  {
    this.showWeekend = ! this.showWeekend
    this.samApp.saveStore("TQweekpad.showWeekend", this.showWeekend, "session")
  }

  clearWeekpadFilter()
  {
    this.weekpadFilterRegEx = null;
    this.samApp.deleteStore("TQweekpad.filterRegEx", "session")
    this.samApp.deleteStore("TQweekpad.filter", "session")

    var elem=document.getElementById("WeekpadFilter")
    setTimeout(() =>
    {
      elem.focus();
      elem.scrollIntoView({behavior: 'smooth', inline: 'center', block: 'center'});
    }, 0);
  }

  filterTask(t: any): boolean
  {
    if (this.weekpadFilterRegEx == null) return false

    this.samApp.saveStore("TQweekpad.filter", this.weekpadFilter, "session")
    this.samApp.saveStore("TQweekpad.filterRegEx", this.weekpadFilterRegEx, "session")

    // Return whether the task title matches the filter
    return (t['title'].match(new RegExp(this.weekpadFilterRegEx,"i")) == null)? true:false;
  }

  clearProjectFilter()
  {
    this.store.dispatch(APP_ACTIONS.projectFiltered({ id: 0, code:'', color:'', list:[]}))
  }

  onTaskMenuCommand(command: string, task: any)
  {
    switch(command)
    {
      case "Edit":
        this.editTask(null, task); // TODO pass event
        break;
      case "Duplicate":
        this.duplicateTask(task.id);
        break;
      case "planning":
      case "todo":
      case "doing":
      case "waiting":
      case "stopped":
      case "done":
      case "canceled":
        this.updateTask(task.id, "status", command);
        break;
      case "A":
      case "B":
      case "C":
      case "D":
      case "E":
        this.updateTask(task.id, "priority", command);
        break;
      case "not pinned":
      case "current day":
      case "next day":
        this.updateTask(task.id, "relDate", command);
        break;
      case "Select":
        this.store.dispatch(TASKS_ACTIONS.selectTask({id: task.id}))
        break;
      }
  }

}
