<div class="samPane">
  <div class="samPaneHeader">
    <h4 class="samPaneTitle">
      Note
      <a href="https://docs.taskquark.com/notes.html" target="TQ-docs">
        <fa-icon [icon]="faQuestionCircle" class="TQ-help-icon" title="Help page"></fa-icon>
      </a>
    </h4>
  </div>

  <div *ngIf="!canAddNote"
    class="alert alert-danger" role="alert">
    You have reached the maximum number of notes for your subscription plan.
  </div>

  <div class="ml-2 mr-2 mt-3">
    <div>
      <label for="NoteProject"
        class="TQ-checkbox-label ml-2 mr-2 mt-2">
        Project:
      </label>
      <p-treeSelect 
        [options]="projectsNodes"
        [(ngModel)]="selectedProject">
      </p-treeSelect>
    </div>
  
      <!--  <label>Title:</label> -->
    <input id="NoteTitle" type="Text"
        class="ml-2 pl-1 pr-1 samMandatory"
        [ngClass]="{'col-sm-12' : this.samApp.onMobile,
                    'col-11'    : !this.samApp.onMobile}"
        placeholder="Enter a note title..." maxlength=255
        [(ngModel)]="noteTitle">
    <button title="Save (Ctrl-S)"
        class="btn btn-sm btn-success mt-1 mb-1 ml-1 pa-1"
        [disabled]="clicked || !canAddNote"
        (click)="clicked=true;addNote()">
        {{ !this.noteId ? "Add" : "Save"  }}
    </button>
  </div>

  <div>
    <label for="NoteContent"
      class="TQ-checkbox-label mt-3 ml-3 mr-1">
      Content:
    </label>
    <p-editor #NoteContent [style]="{'white-space':'pre'}"
      placeholder="Edit the contents of the note here..."
      ngDefaultControl [(ngModel)]="noteDescription" >
    </p-editor>
  </div>
    
  <hr/>

  <div class="mt-1 mb-2">

    <button id="NoteSaveButton" 
      title="Save (Ctrl-S)" 
      class="btn btn-success ml-1"
      [disabled]="noteId == 0 && (clicked || !canAddNote)"
      (click)="clicked=true;addNote()">
      {{ noteId == 0 ? "Add" : "Save"  }}
    </button>
    <button id="NoteCancelButton" 
      title="Cancel (ESC)" 
      class="btn btn-warning ml-1"
      [disabled]="clicked"
      (click)="clicked=true; cancelNote()">
      Cancel
    </button>
    <button id="NoteDuplicateButton" 
      title="Duplicate (Ctrl-D)"
      class="btn btn-success ml-3"
      [disabled]="clicked || !canAddNote"
      (click)="clicked=true;
      duplicateNote()">
      Duplicate
    </button>
    <button 
      *ngIf="noteId != 0"
      [disabled]="clicked"
      class="btn btn-danger ml-2 mr-1" 
      style="float:right"
      (click)="clicked=true; confirmDelete();">
      Delete
    </button>
  </div>

  <p-confirmDialog #cd
    [style]="{width: '33vw'}" 
    [breakpoints]="{'960px': '75vw', '640px': '95vw'}"
    [baseZIndex]="10000"
    focusTrap="true"
    closeOnEscape="true"
    message="Are you sure you want to delete the note?">
    <ng-template pTemplate="header">
      <h3>Delete Note</h3>
    </ng-template>
    <ng-template pTemplate="footer">
        <button type="button" class="btn btn-warning ml-2" (click)="cd.reject()">Cancel</button>
        <button type="button" class="btn btn-danger ml-2" (click)="cd.accept()">Delete</button>
    </ng-template>
  </p-confirmDialog>

</div>
