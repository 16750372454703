import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { DragDropModule } from '@angular/cdk/drag-drop';

import { FormsModule } from '@angular/forms';

import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { EditorModule } from 'primeng/editor';
import { TreeSelectModule } from 'primeng/treeselect';
import { TreeTableModule } from 'primeng/treetable';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { NoteComponent } from './note/note.component';
import { NotepadComponent } from './notepad/notepad.component';

@NgModule({
  declarations: [
    NoteComponent,
    NotepadComponent,
  ],
  imports: [
    CommonModule,
    ConfirmDialogModule,
    DragDropModule,
    EditorModule,
    FontAwesomeModule,
    FormsModule,
    TreeSelectModule,
    TreeTableModule,
  ],
  providers: [  
    ConfirmationService,
  ],
})
export class NotesModule { }
