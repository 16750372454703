import { environment } from 'src/environments/environment';

import { Injectable } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';

import { SamuraiService } from 'src/services/samurai/samurai.service';

// Load from pure to avoid loading the Stripe JS library
import { loadStripe } from '@stripe/stripe-js/pure';


@Injectable({
  providedIn: 'root'
})
export class StripeService 
{
  constructor
  (
    private router: Router,
    private activeRoute: ActivatedRoute,
    private samApp: SamuraiService,
  ) 
  {}


  async getStripeClient()
  {
    const stripe_key = environment.stripe.key;

    if (this.samApp.getFeatures().STRIPE_advancedFraudSignals)
    {
      loadStripe.setLoadParameters({advancedFraudSignals: false});
    }

    // Load Stripe Client asynchronously
    let stripe = await loadStripe(stripe_key);

    return stripe;
  }


  handleStripeCallback()
  {
    // Check URL parameter to check a callback from Stripe Checkout
    let result = this.activeRoute.snapshot.queryParams['result']
    if (result == "OK")
    {
      this.router.navigateByUrl('/profile?tab=subscriptions&result=OK');
    }
    if (result == "KO")
    {  
      this.router.navigateByUrl('/profile?tab=subscriptions&result=KO');
    }   
  }

}
