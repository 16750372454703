import { Component, HostListener, OnInit} from '@angular/core'
import { Router, ActivatedRoute  } from "@angular/router"
import { HttpClient, HttpHeaders} from '@angular/common/http'

import { Store } from '@ngrx/store';
import { AppState } from 'src/app/appState/app.state';
import { appState } from 'src/app/appState/app.selectors';
import * as APP_ACTIONS from 'src/app/appState/app.actions';
import * as PROJECTS_ACTIONS from 'src/app/projects/store/projects.actions';
import * as TASKS_ACTIONS from 'src/app/tasks/store/tasks.actions';
import * as NOTES_ACTIONS from 'src/app/notes/store/notes.actions';

import { SamuraiService} from 'src/services/samurai/samurai.service';
import { Auth0Service } from 'src/app/services/auth0/auth0.service';
import { TQApiService} from 'src/app/services/tqapi.service'
import { TQSessionService } from 'src/app/services/tqsession.service';

import { timer } from 'rxjs';
import { DateTime, Duration } from 'luxon';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit
{
  appState: AppState;
  appStateSubs: any;
 
  constructor
  (
    private http: HttpClient,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    private store: Store,
    private auth0: Auth0Service,
    private samApp: SamuraiService,
    private tqApi: TQApiService,
    private tqSession: TQSessionService,
  ) 
  {
    this.appStateSubs = this.store.select(appState)
      .subscribe( state => {
        this.appState = state 
      })
  }

  ngOnInit()
  {
    this.samApp.trace("APP loading ------------------------------------")
    this.tqApi.trace("APP loading ------------------------------------")
    this.store.dispatch(APP_ACTIONS.applicationLoading())

    this.store.dispatch(APP_ACTIONS.initTQapp());
    this.store.dispatch(APP_ACTIONS.onMobile( {value:this.samApp.onMobile} ))

    this.store.dispatch(APP_ACTIONS.setLastGuardTime());
  
    // Check versions and connectivity 
    timer(0, 60*60*1000).subscribe( async () => {
      this.checkAppVersion()
      await this.tqApi.pingTQAPI()
      await this.tqApi.pingTQDB()
    })

    // Recover TQsession from localStorage
    this.samApp.trace("APP recovering TQsession...")
    let TQsession = this.samApp.retrieveStore("TQsession")
    if (TQsession)
    {
      this.samApp.trace("APP loaded TQsession: " + TQsession.id)
      
      this.store.dispatch(APP_ACTIONS.setTQApiToken({token: TQsession.tqapi_token}))
      
      this.store.dispatch(APP_ACTIONS.setTQprofileId({id: TQsession.id}))
      this.store.dispatch(APP_ACTIONS.setTQprofileEmail({email: TQsession.email}))
      this.store.dispatch(APP_ACTIONS.setTQroleSelectedId({id: TQsession.TQroleSelectedId}))
      this.store.dispatch(APP_ACTIONS.setTQprofilePrefs({profile: TQsession}))
    }
    else
    {
      this.samApp.trace("APP loaded TQsession was empty!")
    }

    // Subscribe to Auth0 user profile
    this.subscribeAuth0UserProfile()

    // Subscribe to Auth0Token authorization
    this.subscribeAuth0Token()

    // Logout after some time with no ProfileGuard checked
    timer(0, 1*1000).subscribe( () =>
      {
        ///if (this.tqSession.getSession() == null) return;
        if (this.appState.status != 'loaded') return;
        
        let maxInactiveTime = Duration.fromObject({minutes: parseInt(this.appState.prefLogoutAfterInactiveFor)})
        if (maxInactiveTime.toMillis() > 0) 
        {
          let inactiveTime = DateTime.now().diff(this.appState.lastGuardTime, 'minutes')
          if (inactiveTime > maxInactiveTime)
          {
            this.router.navigate(['/logout'])
          }  
        }
      });
      
    this.store.dispatch(APP_ACTIONS.applicationLoaded())
    this.samApp.trace("APP loaded.")
    this.tqApi.trace("APP loaded.")
  }

  @HostListener('window:focus', ['$event'])
  onFocus(event: FocusEvent): void {
    this.auth0.isAuthenticated$.toPromise()
    .then( (res) => {
      if ( res == true )
      {
        // Ping to update
        this.tqApi.pingTQDB()
        .then(() => { 
          if (this.samApp.getFeatures().reloadOnTab)
          {
            this.samApp.trace("APP reloading on tab focus ------------------------------------")
            this.tqApi.trace("APP reloading on tab focus ------------------------------------")
            /// TODO get stamp -> Overload for a while...
            this.store.dispatch(PROJECTS_ACTIONS.loadProjectsList()) // Upatge the sidebar
            this.store.dispatch(TASKS_ACTIONS.loadTasksList()) // Update the tasks list    
            ///this.store.dispatch(NOTES_ACTIONS.loadNotesList())
          }
        })             
      }
    })
  }

  onSwipeRight($event)
  {
    // Ignore swipe on HOME
    if (this.activatedRoute['_routerState'].snapshot.url == "/") return;

    this.store.dispatch(APP_ACTIONS.sidebarActive({value:true}))
  }
  onSwipeLeft($event)
  {
    // Ignore swipe on HOME
    if (this.activatedRoute['_routerState'].snapshot.url == "/") return;

    this.store.dispatch(APP_ACTIONS.sidebarActive({value:false}))
  }


  // TODO move to samurai.service
  // but would add dependencies on TQApiService and HttpClient
  async checkAppVersion()
  {
    try
    {
      this.tqApi.setTQWEB_led("yellow");
      
      // Get last application.json 
      var headers = new HttpHeaders({
        'Content-type': 'application/json',
        'Cache-Control': 'no-cache, no-store, must-revalidate',
        'Pragma': 'no-cache',
        'Expires': '0'
      });
      var res = await this.http.get("/assets/sam/application.json", { headers }).toPromise()

      // Check version on server
      if (res["application"]["version"] == this.samApp.getVersion())
      {
        this.tqApi.setTQWEB_led("green");
      }
      else
      {
      this.tqApi.setTQWEB_led("yellow");
      }
    }
    catch
    {
      this.tqApi.setTQWEB_led("red");
    }
  }

 
  /*** Auth0 MANAGEMENT ***/

  subscribeAuth0UserProfile()
  {
    this.samApp.trace("APP Auth0UserProfile: Subscribe userProfile$...")
    // this.tqApi.trace("APP Auth0UserProfile: Subscribe userProfile$...")
    
    this.auth0.userProfile$
      .subscribe(async (auth0Profile) =>
        {
          this.samApp.trace("APP Auth0UserProfile: GOT auth0Profile")
          this.tqApi.trace("APP Auth0UserProfile: GOT auth0Profile")

          if (auth0Profile == null)
          {
            this.samApp.trace("APP Auth0UserProfile: auth0Profile received IS null -> TESTING NO delete TQSession + return")
            this.tqApi.trace("APP Auth0UserProfile: auth0Profile received IS null -> TESTING NO delete TQSession + return")
            // this.samApp.deleteStore("TQsession")
            return
          } 

          // Save new Auth0 profile
          this.samApp.trace("APP Auth0UserProfile: auth0Profile received IS NOT null -> dispatch authProfile " +auth0Profile.email)
          this.tqApi.trace("APP Auth0UserProfile: auth0Profile received IS NOT null -> dispatch authProfile " +auth0Profile.email)
          this.store.dispatch(APP_ACTIONS.setAuth0Profile({ profile: auth0Profile }))
          
          const localSession = this.samApp.retrieveStore("TQsession")

          // Detect when auth0Profile has changed with respect to localStorage
          // by email change (or sub, for no ID ready yet)
          if (localSession == null)
          {
            this.samApp.trace("APP Auth0UserProfile: localSession IS null -> return ")
            this.tqApi.trace("APP Auth0UserProfile: localSession IS null -> return ")
            return 
          }

          // Reset rare case where profile holds "null" strings in localStorage
          if (localSession.id == 'null')
          {
            this.samApp.trace("APP Auth0UserProfile: localSession.id is 'null' -> delete TQsession ")
            this.tqApi.trace("APP Auth0UserProfile: localSession.id is 'null' -> delete TQsession ")
            this.samApp.deleteStore("TQsession")
            return
          }

          this.samApp.trace("APP Auth0UserProfile: localSession IS NOT null ")
          this.tqApi.trace("APP Auth0UserProfile: localSession IS NOT null ")
          
          // Check for a change of profile with respect localStorage
          if (localSession.email != this.appState.Auth0Profile.email) 
          {
            this.samApp.trace("APP Auth0UserProfile: localSession.email has NEW Auth0Profile email -> postTQProfile + getTQProfile")
            this.tqApi.trace("APP Auth0UserProfile: localSession.email has NEW Auth0Profile email -> postTQProfile + getTQProfile")
            this.tqApi.trace("APP Auth0UserProfile: localSession.email: " + localSession.email)
            this.tqApi.trace("APP Auth0UserProfile: GOT auth0Profile: " + auth0Profile.email)
            this.tqApi.trace("APP Auth0UserProfile: STATE Auth0Profile.email: " + this.appState.Auth0Profile.email)
            
            this.samApp.trace("APP Auth0UserProfile: posting profile...")
            this.tqApi.trace("APP Auth0UserProfile: posting profile...")
            await this.tqSession.postTQProfile()

            await this.tqSession.getTQProfile()
            await this.tqSession.getRolesByProfile();    
            await this.tqSession.getSubscriptionsByProfile();    
            }
          else
          {
            this.samApp.trace("APP Auth0UserProfile: localSession.email has OLD Auth0Profile email -> do nothing")
            this.tqApi.trace("APP Auth0UserProfile: localSession.email has OLD Auth0Profile email -> do nothing")
          } 
        })
    }
 
    // Ensure that the Auth0 token is subscribed only once
    private subscribedAuth0Token = false;

    subscribeAuth0Token()
    {
      // Subscribe only once
      if (this.subscribedAuth0Token != false)
      {
        this.samApp.trace("APP Auth0Token$: already subscribed to getTokenSilently$! -> return")
        this.tqApi.trace("APP Auth0Token$: already subscribed to getTokenSilently$! -> return")
        return
      }
      else
      {
        this.subscribedAuth0Token = true;
        this.samApp.trace("APP Auth0Token$: Subscribe getTokenSilently$")
        //this.tqApi.trace("APP Auth0Token$: Subscribe getTokenSilently$")

        this.auth0.getTokenSilently$()
          .subscribe(async token =>
            {
              this.samApp.trace("APP Auth0Token$: getTokenSilently$ GOT Auth0Token$")
              this.tqApi.trace("APP Auth0Token$: getTokenSilently$ GOT Auth0Token$")

              // Save new Auth0 token as TQAPI bearer
              this.store.dispatch(APP_ACTIONS.newAuth0Token({ token: token }))

              // Post the profile first, if not already posted
              if (this.appState.Auth0Profile.email)
              {
                this.samApp.trace("APP Auth0Token$: getTokenSilently$ posting profile...")
                this.tqApi.trace("APP Auth0Token$: getTokenSilently$ posting profile...")
                await this.tqSession.postTQProfile()
              }
              // Reload the session 
              this.samApp.trace("APP Auth0Token$: getTokenSilently$ getting profile...")
              this.tqApi.trace("APP Auth0Token$: getTokenSilently$ getting profile...")
              await this.tqSession.getTQProfile() 
              await this.tqSession.getRolesByProfile();    
              await this.tqSession.getSubscriptionsByProfile();
              
              // Update the projects list for the sidebar
              this.store.dispatch(PROJECTS_ACTIONS.loadProjectsList())
              // this.store.dispatch(TASKS_ACTIONS.loadTasksList())
              // this.store.dispatch(NOTES_ACTIONS.loadNotesList())

              // Route after receiving a new token
              this.samApp.trace("APP Auth0Token$: getTokenSilently$ route to URL "+ this.router.url)
              this.tqApi.trace("APP Auth0Token$: getTokenSilently$ route to URL "+ this.router.url)

              // this.router.url is /callback when coming from Auth0 login process
              if (this.router.url.startsWith("/callback"))
              {
                // this.tqApi.trace("APP Auth0Token$: getTokenSilently$ getting back from Auth0 /callback -> do nothing") ///logout -> BEST BAD solution")
                // this.router.navigate(['/loading'])
                // this.auth0.login("/signin") -> NO, loops!
                // this.router.navigate(['/logout']) -> loops too?
                // Do nothing
                // this.auth0.logout()
                // this.auth0.login("/signin") -> NO, loops!
                this.tqApi.trace("APP Auth0Token$: getTokenSilently$ getting back from Auth0 /callback -> DO NOTHING")
                // Do nothing, again
                return;
              }

              // Route to the startPad, if comes from /loading or /
              if (this.router.url == "/loading" || this.router.url == "/")
              {
                this.store.dispatch(APP_ACTIONS.onMobile( {value:this.samApp.onMobile} ))

                let startPad = this.appState.prefLoginStartPad;
                if (startPad == null || startPad == "null" || startPad == undefined || startPad == "undefined") 
                {
                  this.samApp.trace("APP Auth0Token$: startPad was " +  startPad + " -> set to workpad")
                  startPad = "workpad";
                }
                this.samApp.trace("APP Auth0Token$: final route to startPad " +  startPad )
                this.tqApi.trace("APP Auth0Token$: final route to startPad " +  startPad )
                this.router.navigate([startPad])
              }
              else
              {
                this.samApp.trace("APP Auth0Token$: final route to URL " +  this.router.url )
                this.tqApi.trace("APP Auth0Token$: final route to URL " +  this.router.url )
                this.router.navigateByUrl(this.router.url)
              }
          })
      }
    }
 
}
