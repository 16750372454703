import { environment } from '../environments/environment'; 

import { NgModule, Injectable } from '@angular/core';
import { LOCALE_ID } from '@angular/core';
import {
  BrowserModule,
  HammerModule, HammerGestureConfig, HAMMER_GESTURE_CONFIG,
  Title,
  } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import * as Hammer from 'hammerjs';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome'

import { HttpClientModule} from '@angular/common/http'
import { HTTP_INTERCEPTORS } from '@angular/common/http'

import { FormsModule } from '@angular/forms';

import { DateAdapter } from '@angular/material/core';
import { MatCardModule } from '@angular/material/card'; 
import { MatDatepickerModule} from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatIconModule } from '@angular/material/icon';
import { MatTabsModule  } from '@angular/material/tabs';
import { MatTreeModule } from '@angular/material/tree';

import { DragDropModule } from '@angular/cdk/drag-drop';

import { CheckboxModule } from 'primeng/checkbox';
import { EditorModule } from 'primeng/editor';
import { SelectButtonModule } from 'primeng/selectbutton';
import { SplitButtonModule } from 'primeng/splitbutton';
import { TreeTableModule } from 'primeng/treetable';

import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { appReducer } from 'src/app/appState/app.reducers';
import { notesReducer } from './notes/store/notes.reducers';
import { projectsReducer } from './projects/store/projects.reducers';
import { tasksReducer } from './tasks/store/tasks.reducers';
import { AppEffects } from 'src/app/appState/app.effects';
import { NoteEffects } from './notes/store/notes.effects';
import { ProjectsEffects } from './projects/store/projects.effects';
import { TasksEffects } from './tasks/store/tasks.effects';

import { TQDateTimeService, TQDateAdapter } from 'src/app/services/tqdatetime.service'
import { TQApiErrorInterceptor } from 'src/app/services/tqapi.service';
import { CallbackComponent } from 'src/app/services/auth0/callback/callback.component';
import { StripeCallbackComponent } from 'src/app/services/stripe/stripe-callback/stripe-callback.component';

import { TQProfileWidgetComponent } from 'src/app/shared/widgets/tq-profile-widget/tq-profile-widget.component';
import { TQRoleSelectorComponent } from 'src/app/shared/widgets/tq-role-selector/tq-role-selector.component';
import { TQTimezonesWidgetComponent } from 'src/app/shared/widgets/tq-timezones-widget/tq-timezones-widget.component';
import { TQWeekdaysWidgetComponent } from 'src/app/shared/widgets/tq-weekdays-widget/tq-weekdays-widget.component';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppRoutingGuard } from './app-routing.guard';

import { FooterComponent } from './modules/footer/footer.component';
import { HeaderComponent } from './modules/header/header.component';
import { HomeComponent } from './modules/home/home.component';
import { LoadingComponent } from './modules/loading/loading.component';
import { LogoutComponent } from './modules/logout/logout.component';
import { SigninComponent } from './modules/signin/signin.component';
import { SignupComponent } from './modules/signup/signup.component';
import { SidebarComponent } from './modules/sidebar/sidebar.component';

import { ProfileComponent } from 'src/app/profile/profile.component';
import { ProjectsModule } from 'src/app/projects/projects.module';
import { TasksModule } from 'src/app/tasks/tasks.module';
import { NotesModule } from 'src/app/notes/notes.module';



@Injectable()
export class HammerConfig extends HammerGestureConfig
{
  buildHammer(element: HTMLElement): HammerManager
  {
    return new Hammer.Manager(element,
    {
      touchAction: 'auto',
      inputClass: Hammer.TouchInput,
      recognizers: [ [Hammer.Swipe, {
        direction: Hammer.DIRECTION_HORIZONTAL,
        threshold: 20
      }] ]
    });
  }
}


@NgModule({
  declarations: [
    AppComponent,
    CallbackComponent,
    FooterComponent,
    HeaderComponent,
    HomeComponent,
    LoadingComponent,
    LogoutComponent,
    ProfileComponent,
    SidebarComponent,
    SigninComponent,
    SignupComponent,
    StripeCallbackComponent,
  ],
  imports: [
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    CheckboxModule,
    DragDropModule,
    EditorModule,
    FontAwesomeModule,
    FormsModule,
    HammerModule,
    HttpClientModule,
    MatCardModule,
    MatExpansionModule,
    MatIconModule,
    MatDatepickerModule,
    MatTabsModule,
    MatTreeModule,
    NotesModule,
    ProjectsModule,
    TasksModule,
    SelectButtonModule,
    SplitButtonModule,
    TQProfileWidgetComponent,
    TQRoleSelectorComponent,
    TQTimezonesWidgetComponent,
    TQWeekdaysWidgetComponent,
    TreeTableModule,
    StoreModule.forRoot({
      appState: appReducer,
      notesState: notesReducer,
      projectsState: projectsReducer,
      tasksState: tasksReducer,
    }, {}),
    StoreDevtoolsModule.instrument({
      maxAge: 25, 
      logOnly: environment.production, 
      connectInZone: true
    }),
    EffectsModule.forRoot([
      AppEffects,
      NoteEffects,
      ProjectsEffects,
      TasksEffects,
    ]),
  ],
  providers: [
    {
      provide: LOCALE_ID,
      useValue: 'en-US'
    },
    {
      provide: HAMMER_GESTURE_CONFIG,
      useClass: HammerConfig
    },
    {
      provide: DateAdapter,
      useClass: TQDateAdapter
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: TQApiErrorInterceptor,
      multi: true
    },
    AppRoutingGuard,
    Title,
    TQDateTimeService,
  ],
  bootstrap: [
    AppComponent
  ]
})
export class AppModule {}
