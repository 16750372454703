<div id="monthpadPane" class="samPanePane">
  <div class="samPaneHeader"
    [ngClass]="{'TQ-header-filtered' : monthpadFilter}">
    <h4 class="samPaneTitle" (click)="scrollToTop()">
      Monthpad
      <a href="https://docs.taskquark.com/monthpad.html" target="TQ-docs">
        <fa-icon [icon]="faQuestionCircle" class="TQ-help-icon" title="Help page"></fa-icon>
      </a>
    </h4>
    <div *ngIf="appState.projectFilterCode != ''">
      <p class="samPaneSubtitle"
        [ngClass]="'TQ-'+appState.projectFilterColor">
        {{appState.projectFilterCode}}
      </p>
      <button 
        class="samPaneSubtitle badge TQ-clear-button mt-3"
        title="Clear (Esc)"
        (click)="clearProjectFilter()">X
      </button>
    </div>
    <img *ngIf="tasksState.status!='loaded'" class="TQ-loading-icon" src="/assets/img/spinner.gif">
    <div class="TQ-filter-area">
      <button class="TQ-filter-button"
        (click)="monthpadFilter=!monthpadFilter; clearMonthpadFilter();">
        <fa-icon [icon]="faFilter" class="fa-lg" title="Filter Monthpad (Ctrl-F)"></fa-icon>
      </button>
      <input id="MonthpadFilter" type="text" class="pl-1"
        [hidden]="!monthpadFilter"
        [(ngModel)]="monthpadFilterRegEx">
      <button class="badge TQ-clear-button ml-1 mr-2"
        title="Clear (Esc)"
        [hidden]="!monthpadFilter"
        (click)="clearMonthpadFilter()">X</button>
    </div>
  </div>

  <div>
    <button *ngIf="taskId > 0"
        class="btn btn-sm btn-info TQ-scroll-top-button"
        (click)="clearToTop()">
        <fa-icon [icon]="faArrowUp" title="Scroll to top"></fa-icon>
    </button>
    <button *ngIf="canAddTask"
        class="btn btn-sm btn-success TQ-add-button"
        (click)="editTask($event)">
        <fa-icon [icon]="faPlus" title="Add Task"></fa-icon>
    </button>

    <div *ngIf="!canAddTask"
      class="alert alert-danger" role="alert">
      You have reached the maximum number of tasks for your subscription plan.
    </div>

    <div class="ml-2 pb-2 text-center" style="float:left">
      <button
        class="btn btn-sm btn-success date-arrow"
        title="Previous Month"
        (click)="goPreviousMonth()">
        <fa-icon [icon]="faCaretLeft" ></fa-icon>
      </button>
      <button
        class="btn m-0 p-0 date-button"
        [ngClass]="(monthpadMonth.month==tqDT.tqDateTime.month?'date-actualMonth':'')"
        title="Go to current month"
        (click)="goThisMonth()">
        <b>{{monthpadMonth.name}} {{monthpadMonth.year}}</b>
      </button>
      <button 
        class="btn btn-sm btn-success date-arrow"
        title="Next Month"
        (click)="goNextMonth()">
        <fa-icon [icon]="faCaretRight" ></fa-icon>
      </button>
      </div>
      <div style="float:right">
        <button class="badge TQ-action-button mr-2"
          (click)="toggleOngoing()">
          <span *ngIf="!this.showOngoing">Show ongoing</span>
          <span *ngIf="this.showOngoing">Hide ongoing</span>
        </button>
        <button class="badge TQ-action-button mr-2"
          (click)="toggleWeekend()">
          <span *ngIf="!this.showWeekend">Show weekend</span>
          <span *ngIf="this.showWeekend">Hide weekend</span>
        </button>
      </div>

    <table id="DoW" class="table table-bordered">
      <thead>
        <tr>
          <!-- DAYS OF WEEK ROW -->
          <td
            *ngFor="let d of getNamesOfDays(); let i = index"
            class="m-0 p-0 text-center day-name"
            (dblclick)="gotoTodopad(d)">
            {{d.nameDay}}
          </td>
        </tr>
      </thead>
      <tbody cdkDropListGroup >
        <!-- WEEKS OF MONTH -->
        <tr *ngFor="let w of getWeeksOfMonth(); let i = index">
          <!-- DAYS OF EACH WEEK -->
          <td *ngFor="let d of getDaysOfWeek(i); let j = index"
            class="day-area"
            [class.day-area-weekend]="d.weekend"
            [class.day-area-today]="isToday(d)"
            (dblclick)="addTask(d)">
            <div id="day-{{i}}-{{j}}"
              class="day-number"
              [class.day-number-today]="(d.isToday)"
              [ngClass]="(d.isCurrentMonth?'day-number-currentMonth':'day-number-otherMonth')"
              style="cursor: pointer;"
              (dblclick)="gotoTodopad(d)">
              {{d.numberDay}}
            </div>
            <div
              cdkDropList
              [cdkDropListData]="[d]"
              (cdkDropListDropped)="drop($event)"
              [cdkDropListEnterPredicate]="canDrop"
              class="day-tasks-list">
              <div *ngFor="let t of getTasksOfDay(d); let k = index"
                id="{{t.id}}" #taskCell
                cdkDrag
                [cdkDragDisabled]="samApp.onMobile"
                [cdkDragData]="t"
                class="day-task"
                [class.table-success]="(t.id==this.taskId)">
                <div (click)="editTask($event, t)">
                  <span 
                    class="badge TQ-badge"
                    [ngClass]="'TQ-'+t.project_color"
                    title="{{t.project_title}}">
                    {{t.project_code}}
                  </span>
                  <span 
                    [ngClass]="'task-' + t.status">
                    {{t.title}}
                  </span>
                  <span *ngIf="t.isStart" class="task-icon">
                    <fa-icon [icon]="faCaretSquareRight" title="start date"></fa-icon>
                  </span>
                  <span *ngIf="t.isOngoing" class="task-icon" style="color:blue;">
                    <fa-icon [icon]="faCaretRight" title="ongoing task"></fa-icon>
                  </span>
                  <span *ngIf="t.isTarget" class="task-icon" style="color:blue">
                    <fa-icon [icon]="faCaretSquareDown" title="target date"></fa-icon>
                  </span>
                  <span *ngIf="t.isDue" class="task-icon" style="color:red">
                    <fa-icon [icon]="faCaretSquareDown" title="due date"></fa-icon>
                  </span>
                  <span *ngIf="t.isEnd" class="task-icon">
                    <fa-icon [icon]="faCaretSquareLeft" title="end date"></fa-icon>
                  </span>
                  <span *ngIf="t.isPinned" class="task-icon">
                    <fa-icon [icon]="faThumbtack" title="pinned task"></fa-icon>
                  </span>
                  <span *ngIf="t.isRepetition" class="task-icon" style="font-size: small;">
                    <fa-icon [icon]="faRedo" title="task repetition"></fa-icon>
                  </span>
                </div>
                <tq-task-menu *ngIf="!this.samApp.onMobile"
                  [target]="taskCell"
                  [canAddTask]="canAddTask"
                  (command)="onTaskMenuCommand($event, t)"
                ></tq-task-menu>
              </div>
              <div class="mb-2">
                <!-- Empty space to allow double-clicking -->
              </div>  
            </div>
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
