import { Component, EventEmitter, Input, OnChanges, OnInit, Output } from '@angular/core';

import { CommonModule } from '@angular/common';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { FormsModule } from '@angular/forms';

import { Store } from '@ngrx/store';
import { AppState } from 'src/app/appState/app.state';
import { appState } from 'src/app/appState/app.selectors';

import { TQApiService } from 'src/app/services/tqapi.service';
import { TQSessionService } from 'src/app/services/tqsession.service';

import { faClock, faUser, faUserClock, faGlobe } from '@fortawesome/free-solid-svg-icons';

@Component({
  selector: 'tq-timezones-widget',
  standalone: true,
  imports: [
    CommonModule, 
    FontAwesomeModule, 
    FormsModule],
  templateUrl: './tq-timezones-widget.component.html',
  styleUrl: './tq-timezones-widget.component.scss'
})
export class TQTimezonesWidgetComponent implements OnInit, OnChanges
{
  faClock = faClock;
  faUser = faUser;
  faUserClock = faUserClock;
  faGlobe = faGlobe;

  @Input() timezone: string;
  @Input() allowProfile: boolean = false;
  @Input() allowFloating: boolean = false;
  @Output() timezoneChange = new EventEmitter<string>();

  appState: AppState; 
  appStateSubs: any;

  // Current time zone selector
  timezoneSelector: string = "global"; 

  // Time zone data
  TZ: any;
  TZzone1Labels: any = null;
  TZzone2Labels: any = null;
  TZzone3Labels: any = null;;

  // Selected time zone labels
  zone1Label: string = null;
  zone2Label: string = null;
  zone3Label: string = null;

  
  constructor
  (
    private tqApi: TQApiService,
    private store: Store,
  ) 
  {
    this.appStateSubs = this.store.select(appState)
    .subscribe( state => {
      this.appState = state 
    })
  }

  async ngOnInit()
  {
    if (this.timezone == null) this.timezoneSelector = "profile";
    if (this.timezone == "N")  this.timezoneSelector = "floating";

    await this.getTimezones()

    this.getZone1Labels()
    this.getZone2Labels()
    this.getZone3Labels()
    this.setLabels()
  }

  async getTimezones()
  {
    this.TZ = await this.tqApi.getProfileTimeZones()
  }

  // Parent component changes 
  ngOnChanges()
  {
    if (this.timezone == null) 
    {
      this.timezoneSelector = "profile";
    }
    else if (this.timezone == "N")  
      {
      this.timezoneSelector = "floating";
    }
    else 
    {
      this.timezoneSelector = "global";
    }

    this.setLabels()
  } 

  // Local changes 
  changeZone1() 
  {
    this.getZone2Labels()
    if (this.TZzone2Labels == null) 
    {
      this.zone2Label = null;
      this.zone3Label = null;
    }
    else
    {
      this.zone2Label = this.TZzone2Labels.values().next().value;      
      this.zone3Label = null;
    }

    this.getZone3Labels()
    this.changeTimezone()
  }
  
  changeZone2() 
  {
    this.getZone3Labels()
    if (this.TZzone3Labels == null) 
    {
      this.zone3Label = null;
    }
    else
    {
      this.zone3Label = this.TZzone3Labels.values().next().value;      
    }

    this.changeTimezone()
  }

  changeZone3() 
  {
    this.changeTimezone()
  }

  changeTimezone() 
  {    
    this.timezone = this.buildTimezone();

    this.timezoneChange.emit(this.timezone);
  }

  buildTimezone(): string
  { 
    if (this.timezoneSelector == "floating") return "N";
    if (this.timezoneSelector == "profile") return null;

    let res = this.zone1Label
    if (this.zone2Label != null) res += "/" + this.zone2Label
    if (this.zone3Label != null) res += "/" + this.zone3Label
    return res;
  }

  setLabels()
  {
    if (this.timezone == null || this.timezone == "N") return;

    let labels = this.timezone.split("/")
    if (labels.length > 0) 
      {
      this.zone1Label = labels[0]
    }
    if (labels.length > 1)
    {
      this.zone2Label = labels[1]
    }
    if (labels.length > 2) 
    {
      this.zone3Label = labels[2]
    }
 }

  getZone1Labels() 
  {
    this.TZzone1Labels = null; 
    this.TZzone2Labels = null; 
    this.TZzone3Labels = null; 

    if (this.TZ == null) return;

    // Load first level labels
    this.TZzone1Labels = new Set(
      this.TZ
        .map( (tz) => tz.split(",")[0])
      )  
    if (this.TZzone1Labels.size == 1) 
    {
      this.TZzone1Labels = null; 
    } 
  }  
  
  getZone2Labels() 
  {
    this.TZzone2Labels = null; 
    this.TZzone3Labels = null; 

    if (this.TZ == null) return;

    // Load second level labels
    this.TZzone2Labels = new Set(
      this.TZ
        .filter( (tz) => tz.split(",")[0] == this.zone1Label )
        .map( (tz) => tz.split(",")[1])
      )
    if (this.TZzone2Labels.size == 1) 
    {
      this.TZzone2Labels = null; 
    } 
  }

  getZone3Labels() 
  {
    this.TZzone3Labels = null; 

    if (this.TZ == null) return;

    // Load third level labels
    this.TZzone3Labels = new Set(
      this.TZ
        .filter( (tz) => tz.split(",")[0] == this.zone1Label )
        .filter( (tz) => tz.split(",")[1] == this.zone2Label )
        .map( (tz) => tz.split(",")[2])
    )
    if (this.TZzone3Labels.size == 1) 
    {
      this.TZzone3Labels = null; 
    }
  }  

  cycleTimezone()
  {
    switch (this.timezoneSelector) 
    {
      case "global":   
        if (this.allowFloating) 
        {
          this.timezoneSelector = "floating"; 
          this.timezone = "N"; 
          break;
        }
        if (this.allowProfile) 
        {
          this.timezoneSelector = "profile"; 
          this.timezone = null; 
          break;
        }
        this.timezoneSelector = "global"; 
        this.timezone = this.appState.prefLocTimeZone
        break;
      case "floating": 
        if (this.allowProfile) 
        {
          this.timezoneSelector = "profile"; 
          this.timezone = null; 
          break;
        }
        this.timezoneSelector = "global" 
        this.timezone = this.appState.prefLocTimeZone
        break;
      case "profile": 
        this.timezoneSelector = "global"; 
        this.timezone = this.appState.prefLocTimeZone
        break;
    } 

    this.getZone1Labels()
    this.setLabels()
    this.getZone2Labels()
    this.setLabels()
    this.getZone3Labels()
    this.setLabels()
    this.changeTimezone()
  }
}