import { Inject, Injectable } from '@angular/core';

import { MAT_DATE_LOCALE } from '@angular/material/core';
import { LuxonDateAdapter } from '@angular/material-luxon-adapter';

import { Store } from '@ngrx/store';
import { AppState } from 'src/app/appState/app.state';
import { appState } from 'src/app/appState/app.selectors';

import { timer } from 'rxjs';
import { DateTime, Info } from 'luxon';

@Injectable({
  providedIn: 'root'
})
export class TQDateTimeService 
{
  appState: AppState;
  appStateSubs: any;

  //---
  // All DateTime values are null or valid  
  // All string values are empty or valid
  //---
  public tqDateTime: DateTime = null;         // TQSession current time
  public tqUTCOffset: Number = null;          // Offset from tqDateTime to UTC, in minutes 
   
  public tqDateString: string = "";           // Date from TQDateTime in profile date format
  public tqDateISOString: string = "";        // tqDateTime in YYYY-MM-DD format
  public tqTimeString: string = "";           // HH:mm
  public tqTimeLongString: string = "";       // HH:mm:ss
  public tqUTCOffsetString: string = "";      // +00:00 - TQDateTime Offset from UTC 
  public tqDayName: string = "";              // Name of the day of week

  TQSession = null; 

  constructor
  (
    private store: Store,
  )
  {
    this.appStateSubs = this.store.select(appState)
      .subscribe( state => {
        this.appState = state 
    })

    this.computeDateTime()

    timer(0, 1000).subscribe( () => {
      this.computeDateTime()
    })
  }
  
  private computeDateTime()
  {
    this.TQSession = this.appState 
    if (this.TQSession) 
    {
      this.tqDateTime = DateTime.now().setZone(this.TQSession.prefLocTimeZone);
    }
    else
    {
      this.tqDateTime = DateTime.now()
    }
    this.tqUTCOffset = this.tqDateTime.offset;
    
    this.tqDateString = this.formatDate(this.tqDateTime);
    this.tqDateISOString = this.formatDateISO(this.tqDateTime);
    this.tqDayName = this.formatDayName(this.tqDateTime); 
    this.tqUTCOffsetString = this.formatUTCOffset(this.tqDateTime); 
    this.tqTimeString = this.formatTime(this.tqDateTime, true, false);
    this.tqTimeLongString = this.formatTime(this.tqDateTime, true, true);
  }


  /***
   * Date and time formating functions 
   */

  /**
   * Formats a Datetime in the profile's preferred date format.
   * @param date The DateTime object to be formatted.
   * @returns PrfFrm date string.
   */
  formatDate(date:DateTime):string
  {
    if (date == undefined) return "";

    this.TQSession = this.appState /// this.tqSession.getSession()       
    if (this.TQSession == null) return "";

    let res: string = ""; 
    switch (this.TQSession.prefLocDateFormat)
    {
      case "YYYY-MM-DD": res = date.toFormat("yyyy-MM-dd"); break
      case "MM/DD/YYYY": res = date.toFormat("MM/dd/yyyy"); break
      case "DD-MM-YYYY": res = date.toFormat("dd-MM-yyyy"); break
    }
    return res;
  }

  /**
   * Formats a Datetime in ISO 8601 extended format.
   * @param date DateTime object to be formatted.
   * @returns ISO (yyyy-MM-dd) date string.
   */
  formatDateISO(date:DateTime):string
  {
    return date==null ? null : date.toFormat("yyyy-MM-dd");;
  }

  formatDayName(date:DateTime):string
  {
    if (date == null) return "";
    
    return date.toFormat('EEE')+".";
  }

  formatMonthName(date:DateTime):string
  {
    return date.toFormat('LLLL', { locale: 'en-US' });
  }
  formatMonthNameFromNumber(month:number):string
  {
    return Info.months('long', { locale: 'en-US' })[month - 1];
  }

  /**
   * Formats a Datetime according to the profile's preferred time format.
   * @param date The DateTime object to be formatted.
   * @returns The formatted time string.
   */
  formatTime(time:DateTime, minutes:boolean = true, seconds:boolean = false):string
  {
    let format = this.TQSession?.prefLocTimeFormat || "24h";
    
    if (seconds) 
    {
      switch (format) 
      {
        case "24h": return time.toFormat("HH:mm:ss");
        case "12h": return time.toFormat("hh:mm:ss a");
      }
    }
    else 
    {
      switch (format) 
      {
        case "24h": return time.toFormat(minutes?"HH:mm":"HH");
        case "12h": return time.toFormat(minutes?"hh:mm a":" h a");
      }
    }
  }

  /**
  * Formats a Datetime according to the ISO 8601 extended format.
  * @param date DateTime object to be formatted.
  * @returns The formatted date ISO string (hh:mm).
  */
  formatTimeISO(date:DateTime):string
  {
    return date==null ? null : date.toFormat("HH:mm");;
  }
  
  formatClockTime(hour:string, minutes:string = "", showMinutes:boolean = true):string
  {
    if (showMinutes)
    {
      return this.formatTime(DateTime.fromFormat(hour.toString().padStart(2, '0')+":"+minutes.toString().padStart(2, '0'), "HH:mm"))
    }
    else
    {
      return this.formatTime(DateTime.fromFormat(hour.toString().padStart(2, '0'), "HH"), false)
    }
  }

  formatUTCOffset(date:DateTime):string
  {
    return date.toFormat('ZZ');
  }


  /***
   * Date and time validation functions 
   */ 

  isToday(date:string): boolean
  {
    if (date == "" || date == null) return false;

    return this.toLuxon(date).hasSame(this.tqDateTime, 'day')
  }

  isTomorrow(date:string, compareTo:string = this.tqDateString): boolean
  {
    if (date == "" || date == null) return false;
    if (compareTo == "" || compareTo == null) return false

    return this.toLuxon(date).hasSame(this.toLuxon(compareTo).plus({days: 1}), 'day')
  }

  isYesterday(date:string, compareTo:string = this.tqDateString): boolean
  {
    if (date == "" || date == null) return false;
    if (compareTo == "" || compareTo == null) return false

    return this.toLuxon(date).hasSame(this.toLuxon(compareTo).minus({days: 1}), 'day')
  }

  isDateBefore(date:string, compareTo:string = this.tqDateString): boolean
  {
    if (date == "" || date == null) return false;
    if (compareTo == "" || compareTo == null) return false;

    return this.toLuxon(date) < this.toLuxon(compareTo)
  }

  /**
   * Checks if a time string happens before another time string
   * If not provided the second time, the current time is used
   * @param time 
   * @param compareTo 
   * @returns 
   */
  isTimeBefore(time:string, compareTo:string = this.tqTimeString): boolean
  {
    if (time == "" || time == null) return false;
    if (compareTo == "" || compareTo == null) return false;
    
    time = this.timeTo24h(time)
    compareTo = this.timeTo24h(compareTo)

    return time < compareTo
  }

  isSameDate(date:string, compareTo:string = this.tqDateString): boolean
  {
    if (date == "" || date == null) return false;
    if (compareTo == "" || compareTo == null) return false;

    return this.toLuxon(date).hasSame(this.toLuxon(compareTo), 'day')
  }

  isDateAfter(date:string, compareTo:string = this.tqDateString): boolean
  {
    if (date == "" || date == null) return false;
    if (compareTo == "" || compareTo == null) return false;

    return this.toLuxon(date) > this.toLuxon(compareTo)
  }

  isDateTimeAfter(date:string, time:string, compareDate:string, compareTime:string): boolean
  {
    if (date == "" || date == null) return false;
    if (time == "" || time == null) time = "00:00:00";
    if (compareDate == "" || compareDate == null) return false;
    if (compareTime == "" || compareTime == null) compareTime = "00:00:00";

    time = this.timeTo24h(time)
    compareTime = this.timeTo24h(compareTime)

    let datetime = date + "T" + time
    let compareDateTime = compareDate + "T" + compareTime
    return this.toLuxonFromISO(datetime) > this.toLuxonFromISO(compareDateTime)
  }

  isDateTimeBefore(date:string, time:string, compareDate:string, compareTime:string): boolean
  {
    if (date == "" || date == null) return false;
    if (time == "" || time == null) time = "00:00:00";
    if (compareDate == "" || compareDate == null) return false;
    if (compareTime == "" || compareTime == null) compareTime = "00:00:00";

    time = this.timeTo24h(time)
    compareTime = this.timeTo24h(compareTime)

    let datetime = date + "T" + time
    let compareDateTime = compareDate + "T" + compareTime
    return this.toLuxonFromISO(datetime) < this.toLuxonFromISO(compareDateTime)
  }

  isSameDateOrAfter(date:string, compareTo:string = this.tqDateString): boolean
  {
    if (date == "" || date == null) return false;
    if (compareTo == "" || compareTo == null) return false;

    return this.toLuxon(date) >= this.toLuxon(compareTo)
  }

  isSameDateOrBefore(date:string, compareTo:string = this.tqDateString): boolean
  {
    if (date == "" || date == null) return false;
    if (compareTo == "" || compareTo == null) return false;

    return this.toLuxon(date) <= this.toLuxon(compareTo)
  }

  isValidDateISO(date:string): boolean
  {
    if (date == "" || date == null) return false;

    return DateTime.fromISO(date).isValid
  }

  isWeekend(date:DateTime): boolean
  {
    if (date == null) return false;

    if (this.appState.status != 'loaded') return false;

    let dow = [ "Mo", "Tu", "We", "Th", "Fr", "Sa", "Su"]
    let weekday = date.weekday - 1
 
    return this.appState.prefLocWeekendDays?.includes(dow[weekday]) 
  }


  /*** 
   * Date and time computations functions 
   */

  dayOfWeek(date:string): number
  {
    return this.toLuxon(date).weekday; 
  }

  weekOfYear(day: number, month: number, year: number): number
  {
    this.computeDateTime();

    let offset = 0;
    let date = null;
    if (day == null || month == null || year == null  ) 
    {
      date = this.tqDateTime      
    }
    else 
    {
      date = DateTime.fromObject({ day, month, year })
    }
    let first = date.startOf('week');
    switch(this.TQSession.prefLocWeekStart)
    {
      case "Friday":   if (date.weekdayShort == "Fri" ||  date.weekdayShort == "Sat" || date.weekdayShort == "Sun") offset = 1;
      case "Saturday": if (date.weekdayShort == "Sat" || date.weekdayShort == "Sun") offset = 1;
      case "Sunday":   if (date.weekdayShort == "Sun") offset = 1;
    }
    return first.weekNumber + offset;
  }

  firsDateOfWeek(week: number, year: number): DateTime
  {
    let first = DateTime.fromObject({ weekNumber: week, weekYear: year }).startOf('week');
    switch (this.TQSession.prefLocWeekStart) 
    {
      case "Friday":   return first.minus({days: 3})
      case "Saturday": return first.minus({days: 2})
      case "Sunday":   return first.minus({days: 1})
      case "Monday":   return first;
      default:         return first;
    }
  }

  nextDay(date:string): DateTime
  {
    return DateTime.fromISO(date).plus({days: 1});
  }

  previousDay(date:string): DateTime
  {
    return DateTime.fromISO(date).minus({days: 1});
  }

  datePlusDays(date:string, days:number): string
  {
    return this.formatDate(this.toLuxon(date).plus({days: days}))
  }

  biggestDate(): string
  {
    return this.formatDate(this.tqDateTime.plus({years: 1000}))
  }

  smallestDate(): string
  {
    return this.formatDate(this.tqDateTime.minus({years: 1000}))
  }


  /***
   * Date and time time zone conversion functions
   */

  /**
   * Converts UTC ISO date and time to date PrfTZ PrfFmt.
   * If time is null, just changes formats but no TZ conversion is made
   * @param date Date UTC ISO string 
   * @param time Time UTC ISO string 
   * @param tz IANA timezone string, default is 'UTC'
   * @returns PrfTZ PrfFmt Date string
   */
  dateToTZ(date:string, time:string, tz:string = 'UTC'):string
  {
    if (date == null || date == "") return "";

    this.TQSession = this.appState /// this.tqSession.getSession()       
    if (time == null || time == "") 
    {
      return this.formatDate(DateTime.fromISO(date))
    }
    else
    {
      return this.formatDate(DateTime.fromISO(date+'T'+time, {zone: tz}).setZone(this.TQSession.prefLocTimeZone))
    }
  }

  /**
   * Converts UTC ISO date and time to ISO PrfTZ time.
   * @param date Date UTC ISO string, if null uses tqDateISOString
   * @param time Time UTC ISO string 
   * @param withSeconds Boolean to include seconds in the time string
   * @returns ISO ProfTZ time string 
   */
  timeToTZ(date:string, time:string, tz:string = 'UTC', withSeconds:boolean = false):string
  {
    if (time == null || time == "") return time;

    if (date == null || date == "") date = this.tqDateISOString;

    if (withSeconds)
    {
      return DateTime.fromISO(date+'T'+time, {zone: tz}).setZone(this.TQSession.prefLocTimeZone).toFormat('HH:mm:ss');
    }
    else
    {
      return DateTime.fromISO(date+'T'+time, {zone: tz}).setZone(this.TQSession.prefLocTimeZone).toFormat('HH:mm');
    }
  }

  /**
   * Converts a timezone, PrfFmt date and time to PrfTZ PrfFmt date.
   * If timezone is null or empty, returns empty string
   * If date is null or empty, returns empty string
   * If time is null or empty, no TZ conversion is made
   * @param tz IANA timezone string
   * @param date PrfTZ PrfFmt date string 
   * @param time PrfTZ PrfFmt time string 
   * @returns PrfTZ PrfFmt date string
   */
  dateTZToTZ(tz: string, date:string, time:string):string
  {
    if (tz == null || tz == "") return "";
    if (date == null || date == "") return "";
    if (time == null || time == "") return date;

    this.TQSession = this.appState /// this.tqSession.getSession()       

    // Convert from TZ, PrfTZ PrfFmt to ProfTZ PrfFmt
    let dateTZ = null
    let datetime = null
    datetime = this.toLuxon(date, time, tz)
    datetime = datetime.setZone(this.TQSession.prefLocTimeZone)

    dateTZ = this.formatDate(datetime)

    return dateTZ
  }

  /**
   * Converts ISO timezone, date and time to the PrfTZ
   * If timezone is null or empty, no conversion is made
   * If date is null or empty, no conversion is made
   * If time is null or empty, no conversion is made
   * @param date Date TZ ISO string 
   * @param time Time TZ ISO string 
   * @param withSeconds Boolean to include seconds in the time string
   * @returns PrfTZ ISO time string
   */
  timeTZToTZ(tz:string, date:string, time:string, withSeconds:boolean=false):string
  {
    if (tz == null || tz == "") return time;
    if (date == null || date == "") return time;
    if (time == null || time == "") return time;

    // Convert from TZ, PrfTZ PrfFmt to PrfTZ ISO
    let timeTZ = null
    let datetime = null
    datetime = this.toLuxon(date, time, tz)
    datetime = datetime.setZone(this.TQSession.prefLocTimeZone)

    // Return time in ISO format
    timeTZ = this.formatTimeISO(datetime)

    return timeTZ
  }

  /**
   * Converts a date from TZ ISO to UTC ISO string
   * @param date ISO string with date, if null returns null
   * @param time ISO string with time, if null returns date
   * @returns ISO string with date in UTC
   */
  dateToUTC(date:string, time:string):string
  {
    if (date == null || date == "") return "";
    if (time == null || time == "") return date;

    let res = DateTime.fromFormat(date + ' ' + time, 'yyyy-MM-dd HH:mm', { zone: this.TQSession.prefLocTimeZone }) 
      .setZone('UTC')
      .toFormat('yyyy-MM-dd');
    return res
  }

  /**
   * Converts a time from TZ ISO to UTC ISO string
   * @param date ISO string with date, if null uses tqDateISOString
   * @param time ISO string with time, if null returns date
   * @returns ISO string time in UTC
   */
  timeToUTC(date:string, time:string):string
  {
    if (time == null || time == "") return time;

    if (date == null || date == "") date = this.tqDateISOString;

    return DateTime.fromFormat(date + ' ' + time, 'yyyy-MM-dd HH:mm', { zone: this.TQSession.prefLocTimeZone }) 
      .setZone('UTC')
      .toFormat('HH:mm');
  }

  /**
   * Converts from TZ ISO to UTC ISO string
   * @param tz: timezone, if null returns date
   * @param date: ISO string with date, if null returns null
   * @param time: ISO string with time, if null returns date
   * @returns ISO string with date in UTC
   */
  dateTZToUTC(tz: string, date:string, time:string):string
  {
    if (tz == null || tz == "") return date;
    if (date == null || date == "") return "";
    if (time == null || time == "") return date;

    let res = DateTime
      .fromFormat(date + ' ' + time, 'yyyy-MM-dd HH:mm', { zone: this.TQSession.prefLocTimeZone }) 
      .setZone('UTC')
      .toFormat('yyyy-MM-dd');
    return res
  }

  /**
   * Converts from TZ ISO to UTC ISO string
   * @param tz: timezone, if null returns date
   * @param date: ISO string with date, if null uses tqDateISOString
   * @param time: ISO string with time, if null returns date
   * @returns ISO string with time in UTC
   */
  timeTZToUTC(tz:string, date:string, time:string):string
  {
    if (tz == null || tz == "") return time;
    if (time == null || time == "") return time;

    if (date == null || date == "") date = this.tqDateISOString;

    return DateTime
      .fromFormat(date + ' ' + time, 'yyyy-MM-dd HH:mm', { zone: tz }) 
      .setZone('UTC')
      .toFormat('HH:mm');
  }

  /**
   * Converts from time string to 24h format
   * @param time in format hh:mm [AM/PM]
   * @returns time in format  HH:MM
   */
  timeTo24h(time:string):string
  {
    if (time == null || time == "") return time;
    
    let isAM = time.includes("AM") 
    let isPM = time.includes("PM")

    time = time.split(" ")[0]
    let hour = parseInt(time.split(":")[0])
    let minutes = parseInt(time.split(":")[1])

    if (isAM && hour == 12)
    {
      hour = 0
    }
    if (isPM)
    {
      hour = hour + 12
    }

    return hour.toString().padStart(2, '0') + ":" + minutes.toString().padStart(2, '0')
  }

  offsetToUTC(date:string, time:string):string
  {
    if (date == null || date == "") return "";
    if (time == null || time == "") time="00:00"

    if (this.TQSession == null) 
    {
      return "";
    }
    else
    {
      return DateTime.fromFormat(date + ' ' + time, 'yyyy-MM-dd HH:mm', { zone: this.TQSession.prefLocTimeZone }) 
        .setZone('utc')
        .toFormat('ZZ');
    }
  }

  /**
   * Returns a short duration string 
   * @param duration in the format "XhYm"
   * @returns duration in the format "XhYm" or "Xh" or "Ym"
   */
  shortDuration(duration: string): string
  {
    if (duration == null) return "";

    let dur = ""
    let hours = parseInt(duration.split("h")[0])
    let minutes = parseInt(duration.split("h")[1])
    if (hours > 0) dur += hours + "h"
    if (minutes > 0) dur += minutes + "m"
    return dur;
  }


  /***
   * Luxon auxiliary functions 
   */

  /**
   * Returns a Luxon Datetime object from a date string and optionally a time string and a timezone string
   * The strings are expected to be in the profile's preferred formats
   * IF tz is null, the profile's timezone is used
   * @param date PrfFmt date string
   * @param time PrfFmt time string or null
   * @param tz IANA timezone string or null
   * @returns Luxon DateTime object
   */
  toLuxon(date:string, time:string = null, timezone:string = null): DateTime 
  {
    if (date == "") return null;
    if (this.TQSession == null) return null;
  
    let res = null;
    let fmt = null; 
    let tz = timezone ? timezone : this.TQSession['prefLocTimeZone'] 
    let prfFmt = this.TQSession['prefLocDateFormat']
    switch (prfFmt) 
    {
      case 'YYYY-MM-DD': fmt = 'yyyy-LL-dd'; break;
      case 'DD-MM-YYYY': fmt = 'dd-LL-yyyy'; break;
      case 'MM/DD/YYYY': fmt = 'LL/dd/yyyy'; break;
    }

    if (time)
    {
      res = DateTime.fromFormat(date+' '+time, fmt+' HH:mm', {zone:tz});
    }
    else
    {
      res = DateTime.fromFormat(date, fmt, {zone:tz})
    }
    return res
  }
  
  /**
   * Returns a Luxon Datetime object from an ISO date string and optionally an ISO time string and a timezone string
   * IF tz is null, UTC timezone is used
   * @param date ISO date string
   * @param time ISO time string or null
   * @param tz IANA timezone string or null
   * @returns Luxon DateTime object
   */
  toLuxonFromISO(date: string, time:string = null, timezone:string = null): DateTime 
  {
    if (date == "") return null;
    //if (this.TQSession == null) return null;
  
    let res = null;
    let tz = timezone ? timezone : 'UTC' 
    if (time)
    {
      res = DateTime.fromISO(date+"T"+time, {zone: tz});
    }
    else
    {
      res = DateTime.fromISO(date, {zone: tz});
    }
    return res 
  }


  /***
   * Date and time debugging functions 
   */

  dumpDateTime()
  {
    console.log("---");
    console.log("dumpDateTime:");
    console.log("tqDateTime", this.tqDateTime);
    console.log("tqDateString", this.tqDateString);
    console.log("tqDateISOString", this.tqDateISOString);
    console.log("---");    
  } 
  
}




//--------------------------------------------------
// Extend the LuxonDateAdapter for Angular Material DatePicker
//--------------------------------------------------
@Injectable({
  providedIn: 'root'
})
export class TQDateAdapter extends LuxonDateAdapter
{
  appState: AppState;
  appStateSubs: any;

  TQSession = null; 

  constructor
  ( 
    //@Optional() 
    @Inject(MAT_DATE_LOCALE) 
    private dateLocale: string,
    private store: Store,
    private tqDT: TQDateTimeService,
  ) 
  {
    super(dateLocale);

    this.appStateSubs = this.store.select(appState)
      .subscribe( state => {
        this.appState = state 
    })
  }
  
  // Gets the first day of the week from the Profile
  getFirstDayOfWeek(): number
  {
    let day = 0;
    switch (this.appState.prefLocWeekStart)
    {
      case "Friday"   : day = 5; break;
      case "Saturday" : day = 6; break;
      case "Sunday"   : day = 0; break;
      case "Monday"   : day = 1; break;
    }
    return day
  }

  // Formats the date to the Profile's preferred date format
  format(date: DateTime, displayFormat: any): string
  {
    // Needed ???
    return this.tqDT.formatDate(date)  
  }
}
