import { NgModule } from '@angular/core';
import { Routes, RouterModule } from '@angular/router';
import { AppRoutingGuard } from './app-routing.guard';

import { CallbackComponent } from 'src/app/services/auth0/callback/callback.component';
import { StripeCallbackComponent } from 'src/app/services/stripe/stripe-callback/stripe-callback.component';

import { HomeComponent } from './modules/home/home.component';
import { LoadingComponent } from './modules/loading/loading.component';
import { LogoutComponent } from './modules/logout/logout.component';
import { MonthpadComponent } from './tasks/monthpad/monthpad.component';
import { NoteComponent } from './notes/note/note.component';
import { NotepadComponent } from './notes/notepad/notepad.component';
import { ProjectComponent } from './projects/project/project.component';
import { ProjectpadComponent } from './projects/projectpad/projectpad.component';
import { ProjectImporterComponent } from 'src/app//projects/project-importer/project-importer.component';
import { ProfileComponent } from 'src/app/profile/profile.component';
import { SigninComponent } from './modules/signin/signin.component';
import { SignupComponent } from './modules/signup/signup.component';
import { TaskComponent } from './tasks/task/task.component';
import { TodopadComponent } from './tasks/todopad/todopad.component';
import { WeekpadComponent } from './tasks/weekpad/weekpad.component';
import { WorkpadComponent } from './tasks/workpad/workpad.component';

const ROUTES: Routes = [
  { path: '',                 component: HomeComponent },
  { path: 'callback',         component: CallbackComponent },
  { path: 'signup',           component: SignupComponent },
  { path: 'signin',           component: SigninComponent },
  { path: 'logout',           component: LogoutComponent },
  
  { path: 'stripe',           component: StripeCallbackComponent },

  { path: 'loading',          component: LoadingComponent,         canActivate: [AppRoutingGuard], data: { guards: ['Auth0'] } },
  { path: 'profile',          component: ProfileComponent,         canActivate: [AppRoutingGuard], data: { guards: ['Auth0'] } },
          
  { path: 'monthpad',         component: MonthpadComponent,        canActivate: [AppRoutingGuard], data: { guards: ['Auth0', 'Profile'] } },
  { path: 'note/:id',         component: NoteComponent,            canActivate: [AppRoutingGuard], data: { guards: ['Auth0', 'Profile'] } },
  { path: 'notepad',          component: NotepadComponent,         canActivate: [AppRoutingGuard], data: { guards: ['Auth0', 'Profile'] } },
  { path: 'project/:id',      component: ProjectComponent,         canActivate: [AppRoutingGuard], data: { guards: ['Auth0', 'Profile'] } },
  { path: 'projectpad',       component: ProjectpadComponent,      canActivate: [AppRoutingGuard], data: { guards: ['Auth0', 'Profile'] } },
  { path: 'project-importer', component: ProjectImporterComponent, canActivate: [AppRoutingGuard], data: { guards: ['Auth0', 'Profile'] } },
  { path: 'task/:id',         component: TaskComponent,            canActivate: [AppRoutingGuard], data: { guards: ['Auth0', 'Profile'] } },
  { path: 'todopad',          component: TodopadComponent,         canActivate: [AppRoutingGuard], data: { guards: ['Auth0', 'Profile'] } },
  { path: 'weekpad',          component: WeekpadComponent,         canActivate: [AppRoutingGuard], data: { guards: ['Auth0', 'Profile'] } },
  { path: 'workpad',          component: WorkpadComponent,         canActivate: [AppRoutingGuard], data: { guards: ['Auth0', 'Profile'] } },

  { path: '**', redirectTo: '/logout', pathMatch: 'full' },
];

@NgModule({
  imports: [RouterModule.forRoot(ROUTES, {})],
  exports: [RouterModule],
})
export class AppRoutingModule { }
