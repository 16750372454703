<div class="samPane">
  <div class="samPaneHeader">
    <h4 class="samPaneTitle">
      Project
      <a href="https://docs.taskquark.com/projects.html" target="TQ-docs">
        <fa-icon [icon]="faQuestionCircle" class="TQ-help-icon" title="Help page"></fa-icon>
      </a>
    </h4>
  </div>

  <div *ngIf="isDefaultProject()"
    class="alert alert-info" role="alert">
    This is your default project! You can rename and reuse it, but you cannot delete it.
  </div>

  <div *ngIf="!canAddProject"
    class="alert alert-danger" role="alert">
    You have reached the maximum number of projects for your subscription plan.
  </div>

  <div class="ml-2 mr-2 mt-3">

    <div style="display:inline-block">
      <label for="ParentProject"
        class="TQ-checkbox-label mt-1 ml-2 mr-1">
        Parent:
      </label>
      <p-treeSelect 
        [options]="projectsNodes"
        [(ngModel)]="parentProjectNode"
        (onNodeSelect)="parentChanged($event)"
      ></p-treeSelect>
      <label for="Role" 
        class="TQ-checkbox-label mt-1 ml-3 mr-1">
        Role:
      </label>
      <tq-role-selector *ngIf="projectParentId == 0 && !isDefaultProject()"
        [(roleId)]="projectRoleId"
      ></tq-role-selector>
      <span *ngIf="projectParentId != 0 || isDefaultProject()">
        {{this.projectRoleCode}}
      </span>
    </div>

    <!--  <label>Title:</label> -->
    <input id="ProjectTitle" type="Text"
      class="ml-2 pl-1 pr-1 samMandatory"
      [ngClass]="{'col-sm-12' : this.samApp.onMobile,
                  'col-11'    : !this.samApp.onMobile}"
      placeholder="Enter a project title..." maxlength=255
      [(ngModel)]="projectTitle">
    <button title="Save (Ctrl-S)"
      class="btn btn-sm btn-success mt-1 mb-1 ml-1 pa-1"
      [disabled]="clicked || !canAddProject"
      (click)="clicked=true;addProject()">
      {{ !this.projectId ? "Add" : "Save"  }}
    </button>

    <br>

    <label for="ProjectCode"
      class="TQ-input-text ml-2 mr-1">
      Code:
    </label>
    <input id="ProjectCode" type="text"
      class="mt-2 pl-1 pr-1 samMandatory"
      [ngClass]="'TQ-'+this.getProjectColor()"
      placeholder="Enter a project code..." size="25"
      [(ngModel)]="projectCode">

    <label for="ProjectColor"
      class="TQ-input-text ml-3 mr-1">
      Color:
    </label>
    <div class="mb-2" style="display:inline-block">
      <button class="badge TQ-badge ml-1 TQ-color-button TQ-white"
      (click)="setProjectColor('white')">&nbsp;&nbsp;</button>
      <button class="badge TQ-badge ml-1 TQ-color-button TQ-red"
      (click)="setProjectColor('red')">&nbsp;&nbsp;</button>
      <button class="badge TQ-badge ml-1 TQ-color-button TQ-orange"
      (click)="setProjectColor('orange')">&nbsp;&nbsp;</button>
      <button class="badge TQ-badge ml-1 TQ-color-button TQ-yellow"
      (click)="setProjectColor('yellow')">&nbsp;&nbsp;</button>
      <button class="badge TQ-badge ml-1 TQ-color-button TQ-green"
      (click)="setProjectColor('green')">&nbsp;&nbsp;</button>
      <button class="badge TQ-badge ml-1 TQ-color-button TQ-blue"
      (click)="setProjectColor('blue')">&nbsp;&nbsp;</button>
      <button class="badge TQ-badge ml-1 TQ-color-button TQ-brown"
      (click)="setProjectColor('brown')">&nbsp;&nbsp;</button>
      <button class="badge TQ-badge ml-1 TQ-color-button TQ-purple"
      (click)="setProjectColor('purple')">&nbsp;&nbsp;</button>
      <button class="badge TQ-badge ml-1 TQ-color-button TQ-black"
      (click)="setProjectColor('black')">&nbsp;&nbsp;</button>
    </div>

    <br>

    <!--  <label>Description:</label>  -->
    <textarea class="col-12 mt-3 pl-2 pr-2" rows="25"
      placeholder="Project description..."
      [(ngModel)]="projectDescription">
    </textarea>

    <br>
    <hr/>

    <div class="mt-1 mb-2">
      <button id="ProjectSaveButton" 
        title="Save (Ctrl-S)"
        class="btn btn-success ml-2"
        [disabled]="projectId == 0 && (clicked || !canAddProject)"
        (click)="clicked=true;addProject()">
        {{ projectId == 0 ? "Add" : "Save"  }}
      </button>
      <button id="ProjectCancelButton" 
        title="Cancel (ESC)"
        class="btn btn-warning ml-1"
        [disabled]="clicked"
        (click)="clicked=true; cancelProject()">
        Cancel
      </button>
      <button id="ProjectDuplicateButton" 
        *ngIf="this.projectId != 0"
        title="Duplicate (Ctrl-D)"
        class="btn btn-success ml-3"
        [disabled]="clicked || !canAddProject"
        (click)="clicked=true;
        duplicateProject()">
        Duplicate
      </button>
      <button 
        *ngIf="this.projectId != 0 && !isDefaultProject()"
        [disabled]="clicked"
        class="btn btn-danger ml-2 mr-2" style="float:right"
        (click)="clicked=true; confirmDelete();">
        Delete
      </button>
    </div>

  </div>

  <p-confirmDialog #cd
    [style]="{width: '33vw'}" 
    [breakpoints]="{'960px': '75vw', '640px': '95vw'}"
    [baseZIndex]="10000"
    focusTrap="true"
    closeOnEscape="true"
    message="
    <b>All tasks and notes in this project and its subprojects will be deleted too.</b>
    <hr>
    Are you sure you want to delete the project?
    ">
    <ng-template pTemplate="header">
      <h3>Delete Project</h3>
    </ng-template>
    <ng-template pTemplate="message" let-message>
      <div class="flex flex-column align-items-center w-full gap-3 border-bottom-1 surface-border">
        <div *ngIf="projectOrigin=='googleCalendar'">
          <b>The connected Google Calendar will NOT be deleted.</b>
          <hr>
        </div>
        Are you sure you want to delete the project?
      </div>
    </ng-template>
    <ng-template pTemplate="footer">
        <button type="button" class="btn btn-warning ml-2" (click)="cd.reject()">Cancel</button>
        <button type="button" class="btn btn-danger ml-2" (click)="cd.accept()">Delete</button>
    </ng-template>
  </p-confirmDialog>

</div>
