import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';

import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';

import { DragDropModule } from '@angular/cdk/drag-drop';

import { FormsModule } from '@angular/forms';

import { ConfirmationService } from 'primeng/api';
import { ConfirmDialogModule } from 'primeng/confirmdialog';
import { EditorModule } from 'primeng/editor';
import { TreeSelectModule } from 'primeng/treeselect';
import { TreeTableModule, } from 'primeng/treetable';


import { ProjectComponent } from './project/project.component';
import { ProjectpadComponent } from './projectpad/projectpad.component';
import { ProjectImporterComponent } from './project-importer/project-importer.component';

import { TQRoleSelectorComponent } from 'src/app/shared/widgets/tq-role-selector/tq-role-selector.component';


@NgModule({
  declarations: [
    ProjectComponent,
    ProjectpadComponent,
    ProjectImporterComponent,
  ],
  imports: [
    CommonModule,
    ConfirmDialogModule,
    DragDropModule,
    EditorModule,
    FontAwesomeModule,
    FormsModule,
    TQRoleSelectorComponent,
    TreeSelectModule,
    TreeTableModule,
  ],
  providers: [  
    ConfirmationService,
  ],
})
export class ProjectsModule { }
