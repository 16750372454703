import { Injectable } from '@angular/core';
import { Router, ActivatedRouteSnapshot, RouterStateSnapshot, UrlTree } from '@angular/router';
  
import { Store } from '@ngrx/store';
import { AppState } from 'src/app/appState/app.state';
import { appState } from 'src/app/appState/app.selectors';

import { Auth0Service } from 'src/app/services/auth0/auth0.service';
import { SamuraiService} from 'src/services/samurai/samurai.service';
import { TQApiService } from './services/tqapi.service';
import { TQSessionService } from 'src/app/services/tqsession.service';

import { Observable } from 'rxjs';
import { tap } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class AppRoutingGuard 
{
  appState: AppState;
  appStateSubs: any;

  constructor
  (
    private router: Router,
    private store: Store,
    private auth0: Auth0Service,
    private samApp: SamuraiService,
    private tqApi: TQApiService,
    private tqSession: TQSessionService,
  ) 
  {
    this.appStateSubs = this.store.select(appState)
    .subscribe( state => { 
        this.appState = state
      }
    )
  }

  canActivate
  (
    route: ActivatedRouteSnapshot,
    routerState: RouterStateSnapshot
  ): Observable<boolean|UrlTree> | Promise<boolean|UrlTree> | boolean | UrlTree
  {
    // this.samApp.trace("GUARD canActivate? " + routerState.url)
    // this.tqApi.trace("GUARD canActivate? " + routerState.url)

    // Get the guards to check for
    let guards = route.data.guards as Array<string>;

    if (!guards) return true;


    //*** GUARD PROFILE ***//

    // Check Profile before Auth0 to capture new users without email verified !!
    // NOTE: If NOT Authenticad, potential race condition with Auth0 guard ??

    // Check whether the profile needs to be verified for activation or subscriptions
    // and return a new route if user cannot proceed
    if (guards.indexOf('Profile') >= 0)
    {
      // this.samApp.trace("GUARD Profile checking...")
      // this.tqApi.trace("GUARD Profile checking...")

      if (this.appState.TQprofileId == undefined || this.appState.TQprofileId == null || this.appState.TQprofileId == 0)
      {
        this.samApp.trace("GUARD Profile: Attempting to route directly without login -> signin, TEST login(routerState.url) later")
        this.tqApi.trace("GUARD Profile: Attempting to route directly without login -> signin, TEST login(routerState.url) later")
        return this.router.parseUrl('/signin')
        // TEST login(routerState.url) as in Auth0 guard
      }
      else
      {
        this.samApp.trace("GUARD Profile: Checking ID: " + this.appState.TQprofileId)
        this.tqApi.trace("GUARD Profile: Checking ID: " + this.appState.TQprofileId)
      }

      // Only Check whether email is verified when appState is loaded
      // On a page reload, this gets here too fast and gets no subscriptions
      if (this.appState.Auth0Profile?.email != null)
      {
        if (this.tqSession.hasEmailVerified() != true)
        {
          this.samApp.trace("GUARD Profile: Routing to /profile by not verified email")
          this.tqApi.trace("GUARD Profile: Routing to /profile by not verified email")
          // Stay on Profile page until email is verified
          return this.router.parseUrl('/profile')
        }
      }

      // Only check subscriptions when they are already loaded
      // On a page reload, this gets here too fast and gets no subscriptions
      if (this.appState.Auth0Profile?.email != null)
      {
        if (this.appState.lastSubscription?.id == null || this.tqSession.isValidSubscription() != true)
        {
          this.samApp.trace("GUARD Profile: Routing to /profile by not valid subscription")
          this.tqApi.trace("GUARD Profile: Routing to /profile by not valid subscription")
          // Stay on Profile page until subscription is valid
          return this.router.parseUrl('/profile')
        }
      }
    }

    //*** GUARD AUTH0 ***//

    // Check whether the Auth0 profile is authenticated
    if (guards.indexOf('Auth0')>=0)
      {
        // this.samApp.trace("GUARD Auth0 checking...")
        // this.tqApi.trace("GUARD Auth0 checking...")
  
        return this.auth0.isAuthenticated$
          .pipe(
            tap( loggedIn =>
                {
                  if (!loggedIn)
                  {
                    this.samApp.trace("GUARD Auth0: not authenticated! for "+ routerState.url + " -> TEST tap login(routerState.url) again")
                    this.tqApi.trace("GUARD Auth0: not authenticated! for "+ routerState.url + " -> TEST tap login(routerState.url) again")
                    // this.auth0.login(routerState.url); // -> NO, attempts to route without the session loaded loops!
                    this.auth0.login(routerState.url);  // TEST again
                    // this.auth0.login("/loading"); // ACCEPT THIS? -> NO, goes to prefLoginStartPad
                    // return this.router.parseUrl('/logout') -> NO, does really nothing
                    // return this.router.navigate['/signin'] // -> NO, does really nothing 
                  }
                  else
                  {
                    this.samApp.trace("GUARD Auth0: authenticated for "+ routerState.url)
                    this.tqApi.trace("GUARD Auth0: authenticated for "+ routerState.url)
                  }
                })
          );
      }
    

    //*** NO MORE GUARDS ***//

    // No guards activated for this route. 
    return true
  }

    
}
