<div id="weekpadPane" class="samPane">
  <div class="samPaneHeader"
    [ngClass]="{'TQ-header-filtered' : weekpadFilter}">
    <h4 class="samPaneTitle" (click)="scrollToTop()">
      Weekpad
      <a href="https://docs.taskquark.com/weekpad.html" target="TQ-docs">
        <fa-icon [icon]="faQuestionCircle" class="TQ-help-icon" title="Help page"></fa-icon>
      </a>
    </h4>
    <div *ngIf="appState.projectFilterCode != ''">
      <p class="samPaneSubtitle"
        [ngClass]="'TQ-'+appState.projectFilterColor">
        {{appState.projectFilterCode}}
      </p>
      <button 
        class="samPaneSubtitle badge TQ-clear-button mt-3"
        title="Clear (Esc)"
        (click)="clearProjectFilter()">X
      </button>
    </div>
    <img *ngIf="tasksState.status!='loaded'" class="TQ-loading-icon" src="/assets/img/spinner.gif">
    <div class="TQ-filter-area">
      <button class="TQ-filter-button"
        (click)="weekpadFilter=!weekpadFilter; clearWeekpadFilter();">
        <fa-icon [icon]="faFilter" class="fa-lg" title="Filter Weekpad (Ctrl-F)"></fa-icon>
      </button>
      <input id="WeekpadFilter" type="text" class="pl-1"
        [hidden]="!weekpadFilter"
        [(ngModel)]="weekpadFilterRegEx">
      <button class="badge TQ-clear-button ml-1 mr-2"
        title="Clear (Esc)"
        [hidden]="!weekpadFilter"
        (click)="clearWeekpadFilter()">X</button>
    </div>
  </div>

  <div>
    <button *ngIf="taskId > 0"
        class="btn btn-sm btn-info TQ-scroll-top-button"
        (click)="clearToTop()">
        <fa-icon [icon]="faArrowUp" title="Scroll to top"></fa-icon>
    </button>
    <button *ngIf="canAddTask"
        class="btn btn-sm btn-success TQ-add-button"
        (click)="editTask($event)">
        <fa-icon [icon]="faPlus" title="Add Task"></fa-icon>
    </button>

    <div *ngIf="!canAddTask"
      class="alert alert-danger" role="alert">
      You have reached the maximum number of tasks for your subscription plan.
    </div>

    <div class="ml-2 pb-2 text-center" style="float:left">
      <button
        class="btn btn-sm btn-success date-arrow"
        title="Previous week"
        (click)="goPreviousWeek()">
        <fa-icon [icon]="faCaretLeft" title="Previous Week"></fa-icon>
      </button>
      <button
        class="btn m-0 p-0 date-button"
        [ngClass]="weekpadWeek.week==tqDT.tqDateTime.weekNumber?'date-actualMonth':''"
        title="Go to current week"
        (click)="goThisWeek()">
        <b>{{weekpadWeek.monthName}} {{weekpadWeek.year}}</b>
      </button>
      <button 
        class="btn btn-sm btn-success date-arrow"
        title="Next week"
        (click)="goNextWeek()">
        <fa-icon [icon]="faCaretRight" title="Next Week"></fa-icon>
      </button>
    </div>
    <div style="float:right">
      <button class="badge TQ-action-button mr-2"
      (click)="toggleOngoing()">
      <span *ngIf="!this.showOngoing">Show ongoing</span>
      <span *ngIf="this.showOngoing">Hide ongoing</span>
      </button>
      <button class="badge TQ-action-button mr-2"
        (click)="toggleWeekend()">
        <span *ngIf="!this.showWeekend">Show weekend</span>
        <span *ngIf="this.showWeekend">Hide weekend</span>
      </button>
    </div>

    <table id="DoW" 
      class="table table-bordered">

      <!-- DAYS OF WEEK HEADER -->
      <thead>
        <tr>
          <td *ngIf="getDaysOfWeek()"
            class="sliceHour"
            class="m-0 p-1 text-center"
            [ngClass]="weekpadWeek.week==tqDT.tqDateTime.weekNumber?'date-actualWeekNumber':'date-otherWeekNumber'"
            >{{weekpadWeek.week}}</td>
          <td *ngFor="let d of getDaysOfWeek(); let i = index"
            class="m-0 p-1 text-center"
            [class.day-number-today]="(d.isToday)"
            [ngClass]="(d.isCurrentMonth?'day-number-currentMonth':'day-number-otherMonth')"
            style="cursor: pointer;"
            (dblclick)="gotoTodopad(d)">
            <div>
              <span class="day-name">{{d.nameDay}}</span>
              <span class="day-number">{{d.numberDay}}</span>
            </div>
          </td>
        </tr>
      </thead>

      <!-- HOUR SLICES OF THE WEEKPAD -->
      <tbody cdkDropListGroup >
        <tr *ngFor="let s of getSlicesOfWeek(); let i = index; last as last">

          <!-- WEEKDAY CELL OF EACH HOUR SLICE -->
          <td id="Hour-{{getSliceHour(s)}}"
            [class.day-area-now]="isNowSlice(s)"
            [class.sliceHour-last]="last"
            class="sliceHour">
            {{getSliceLabel(s)}}  
          </td>
          <!-- Days in that hour slice-->
          <td *ngFor="let d of getDaysOfSlice(s.sliceHour); let j = index"
            cdkDropList
            [cdkDropListData]="[s, d]"
            (cdkDropListDropped)="drop($event)"
            [cdkDropListEnterPredicate]="canDrop"
            (dblclick)="addTask(s, d)"
            class="col-xs-1 day-area"
            [class.day-area-weekend]="d.isWeekend"
            [class.day-area-today]="isTodayColumn(d)"
            [class.day-area-now]="isTodayColumn(d) && isNowSlice(s)"
            [class.sliceHour-last]="last">
            <!-- Tasks in the day-->
            <div class="day-tasks-list">
              <div *ngFor="let t of getTasksOfDay(i, j); let k = index"
                id="{{t.id}}" #taskCell
                cdkDrag
                [cdkDragDisabled]="this.samApp.onMobile"
                [cdkDragData]="t"
                class="day-task"
                [class.table-success]="(t.id==this.taskId)">
                <div 
                  (mouseenter)="selectTask($event, t)"
                  (click)="editTask($event, t)">
                  <span 
                    class="badge TQ-badge"
                    [ngClass]="'TQ-'+t.project_color"
                    title="{{t.project_title}}">
                    {{t.project_code}}
                  </span>
                  <span *ngIf="(t.sliceMinute>0)"
                    class="ml-1">
                    <b>:{{t.sliceMinute}}</b>
                  </span>
                  <span 
                    [ngClass]="'task-' + t.status">
                    {{t.title}}
                  </span>
                  <span *ngIf="t.isExternal" class="task-icon mr-1">
                    <fa-icon [icon]="faCalendarCheck" ></fa-icon>
                  </span>
                  <span *ngIf="t.isStart" class="task-icon">
                    <fa-icon [icon]="faCaretSquareRight" title="start date"></fa-icon>
                  </span>
                  <span *ngIf="t.isOngoing" class="task-icon" style="color:blue;">
                    <fa-icon [icon]="faCaretRight" title="ongoing task"></fa-icon>  
                  </span>
                  <span *ngIf="t.shortDuration && (t.isOngoing) " 
                    class="ml-1 mr-1">
                    <b>+{{t.shortDuration}}</b>
                  </span>
                  <span *ngIf="t.isDue" class="task-icon" style="color:red">
                    <fa-icon [icon]="faCaretSquareDown" title="due date"></fa-icon>
                  </span>
                  <span *ngIf="t.isTarget" class="task-icon" style="color:blue">
                    <fa-icon [icon]="faCaretSquareDown" title="target date"></fa-icon>
                  </span>
                  <span *ngIf="t.isEnd" class="task-icon">
                    <fa-icon [icon]="faCaretSquareLeft" title="end date"></fa-icon>
                  </span>
                  <span *ngIf="t.isPinned" class="task-icon">
                    <fa-icon [icon]="faThumbtack" title="pinned task"></fa-icon>
                  </span>
                </div>
                <tq-task-menu *ngIf="!this.samApp.onMobile"
                  [target]="taskCell"
                  [canAddTask]="canAddTask"
                  (command)="onTaskMenuCommand($event, t)"
                ></tq-task-menu>
              </div>
            </div>
            <!-- <div class="mt-2">
              &nbsp;<!- Empty space to allow double-clicking ->
            </div> -->
          </td>
          <!-- REPEAT HOURS COLUMN -->
          <td *ngIf="!appState.onMobile"
            id="Hour2-{{getSliceHour(s)}}"
            [class.day-area-now]="isNowSlice(s)"
            [class.sliceHour-last]="last"
            class="sliceHour">
            {{getSliceLabel(s)}}
          </td>
        </tr>
      </tbody>
    </table>
  </div>
</div>
