import { Component, HostListener, OnInit, OnDestroy, ChangeDetectorRef } from '@angular/core';
import { CdkDragDrop, DragRef, DropListRef } from '@angular/cdk/drag-drop';
import { Router } from "@angular/router";

import { TQTaskMenuComponent } from 'src/app/shared/widgets/tq-task-menu/tq-task-menu.component';

import * as TASK from 'src/app/models/task';

import { Store } from '@ngrx/store';
import { AppState } from 'src/app/appState/app.state';
import * as APP_ACTIONS from 'src/app/appState/app.actions';
import { appState } from 'src/app/appState/app.selectors';
import { TasksState } from 'src/app/tasks/store/tasks.state';
import * as TASKS_ACTIONS from 'src/app/tasks/store/tasks.actions';
import { tasksState } from 'src/app/tasks/store/tasks.selectors';

import { SamuraiService } from 'src/services/samurai/samurai.service';
import { TQApiService } from 'src/app/services/tqapi.service';
import { TQDateTimeService } from 'src/app/services/tqdatetime.service';
import { TQSessionService } from 'src/app/services/tqsession.service';

import { DateTime } from 'luxon';

import { faArrowUp, faFilter, faPlus, faCaretLeft, faCaretRight, faCaretDown, faCaretUp,
         faCaretSquareLeft, faCaretSquareRight, faCaretSquareDown, faCaretSquareUp, faThumbtack, faRedo }
  from '@fortawesome/free-solid-svg-icons';
import { faQuestionCircle } from '@fortawesome/free-regular-svg-icons';


interface DayOfWeek {
  date: DateTime;
  numberDay: number;
  numberMonth: number;
  numberYear: number;
  nameDay: string;
  isToday: boolean;
  isCurrentMonth: boolean;
  weekend: boolean;
  tasks: Array<any>;
}

interface WeekOfMonth {
  number: number;
  days: Array<DayOfWeek>;
}

interface Month {
  name: string;
  month: number;
  year: number;
  firstDate: DateTime;
  weeks: Array<WeekOfMonth>;
}


@Component({
  selector: 'app-monthpad',
  templateUrl: './monthpad.component.html',
  styleUrls: ['./monthpad.component.scss']
})
export class MonthpadComponent implements OnInit, OnDestroy
{
  faThumbtack = faThumbtack;
  faFilter = faFilter;
  faPlus = faPlus;
  faArrowUp = faArrowUp;
  faCaretLeft = faCaretLeft;
  faCaretRight = faCaretRight;
  faCaretUp = faCaretUp;
  faCaretDown = faCaretDown;
  faCaretSquareLeft = faCaretSquareLeft;
  faCaretSquareRight = faCaretSquareRight;
  faCaretSquareUp = faCaretSquareUp;
  faCaretSquareDown = faCaretSquareDown;
  faQuestionCircle = faQuestionCircle;
  faRedo = faRedo;

  componentState = 'init';

  appState: AppState;
  appStateSubs: any;

  tasksState: TasksState;
  tasksStateSubs: any;
  canAddTask: boolean = true;

  // Monthpad Tasks
///  TQtasks: Array<TASK.TaskSample>;

  monthpadMonth : Month =  {
    name: "",
    month: 0,
    year: 0,
    firstDate: null,
    weeks: null,
  };

  taskId: number = 0; // Local copy of tasksState.selectedTask
  taskText: string = "";

  showOngoing: boolean;
  showWeekend: boolean;

  scrollTop: boolean = true;

  monthpadFilter: boolean = false;
  monthpadFilterRegEx: string  = null;

  TQSession: any;

  
  constructor
  (
///    private cdr: ChangeDetectorRef,
    private router: Router,
    private store: Store,
    public  samApp: SamuraiService,
    private tqApi: TQApiService,
    public  tqDT: TQDateTimeService,
    private tqSession: TQSessionService,
  )
  {}

  async ngOnInit()
  {
    this.store.dispatch(APP_ACTIONS.enterTQpad( {pad:'monthpad'} ))

    this.appStateSubs = this.store.select(appState)
      .subscribe(async state => {
        this.appState = state 
        if (state.status != 'loaded') return;
        if (state.nextPad != 'monthpad') return;

        this.TQSession = this.appState  // this.tqSession.getSession()

        this.showOngoing = this.samApp.retrieveStore("TQmonthpad.showOngoing", "session")
        if (this.showOngoing === null)
        {
          this.showOngoing = false;
        }
        this.showWeekend = this.samApp.retrieveStore("TQmonthpad.showWeekend", "session")
        if (this.showWeekend === null)
        {
          this.showWeekend = true;
        }
    
        this.monthpadMonth.year = this.samApp.retrieveStore("TQmonthpad.year", "session")
        if (this.monthpadMonth.year === null)
        {
          this.monthpadMonth.year = this.tqDT.tqDateTime.year
        }
        this.monthpadMonth.month = this.samApp.retrieveStore("TQmonthpad.month", "session")
        if (this.monthpadMonth.month === null)
        {
          this.monthpadMonth.month = this.tqDT.tqDateTime.month
        }
        this.monthpadMonth.name = this.tqDT.formatMonthName(this.tqDT.tqDateTime)
    
        if (this.componentState == 'loaded') 
        {
          this.canAddTask = this.tqSession.canAddTask()
          
          this.createMonthpad()
          this.buildMonthpadList()
        }
      })

    this.tasksStateSubs = this.store.select(tasksState)
      .subscribe(async state => {
        this.tasksState = state 
        if (state.status != 'loaded') return;
        if (this.appState.nextPad != 'monthpad') return;

        if (this.componentState == 'loaded') 
        {
          this.canAddTask = this.tqSession.canAddTask()
          
          // Update local copies
///          this.TQtasks = state.TQtasks; 
          this.taskId=state.selectedTaskId;
          
          this.createMonthpad()
          this.buildMonthpadList()
        }
      })
     
    this.monthpadFilter=this.samApp.retrieveStore("TQmonthpad.filter", "session")
    this.monthpadFilterRegEx=this.samApp.retrieveStore("TQmonthpad.filterRegEx", "session")

    this.store.dispatch(TASKS_ACTIONS.loadTasksList());
    this.componentState = 'loaded';  
  }

  ngOnDestroy()
  {
    this.appStateSubs.unsubscribe();
    this.tasksStateSubs.unsubscribe();
  } 

  @HostListener('document:keydown.control.f', ['$event'])
  CtrlF(event: any)
  {
    event.preventDefault();
    this.clearMonthpadFilter();
    this.monthpadFilter = true;

    setTimeout( () =>
      {
        this.createMonthpad()
        this.buildMonthpadList()
      },0)

    this.samApp.saveStore("TQmonthpad.filter", this.monthpadFilter, "session")
    this.samApp.saveStore("TQmonthpad.filterRegEx", this.monthpadFilterRegEx, "session")
  }
  @HostListener('document:keydown', ['$event'])
  KeyDownOnFilter(event: any)
  {
    if (this.monthpadFilter == false) return;

    setTimeout( () =>
      {
        this.createMonthpad()
        this.buildMonthpadList()
      },0)

    this.samApp.saveStore("TQmonthpad.filter", this.monthpadFilter, "session")
    this.samApp.saveStore("TQmonthpad.filterRegEx", this.monthpadFilterRegEx, "session")
  }
  @HostListener('document:keydown.escape', ['$event'])
  Escape(event: any)
  {
    event.preventDefault();
    if (this.monthpadFilter == true)
    {
      this.clearMonthpadFilter();
      this.monthpadFilter = false;

      setTimeout( () =>
        {
          this.createMonthpad()
          this.buildMonthpadList()
        },0)

        this.samApp.saveStore("TQmonthpad.filter", this.monthpadFilter, "session")
        this.samApp.saveStore("TQmonthpad.filterRegEx", this.monthpadFilterRegEx, "session")
        }
    else if (this.taskId == 0)
    {
      this.clearProjectFilter()
    }
    else     
    {
      this.clearToTop()
    }
}

  @HostListener('document:keydown.home', ['$event'])
  ThisMonth(event: any)
  {
    event.preventDefault();
    ///this.scrollToTop()
    this.scrollToPaneTitle()
    this.goThisMonth()
  }
  @HostListener('document:keydown.pageUp', ['$event'])
  PreviousMonth(event: any)
  {
    event.preventDefault();
    ///this.scrollToTop()
    this.scrollToPaneTitle()
    this.goPreviousMonth()
  }
  @HostListener('document:keydown.pageDown', ['$event'])
  NextMonth(event: any)
  {
    event.preventDefault();
    ///this.scrollToTop()
    this.scrollToPaneTitle()
    this.goNextMonth()
  }

  canDrop(drag: DragRef, drop: DropListRef)
  {

    return true; 

    // let allow: boolean = true;

    // // Do not allow move to other day slices for pinned tasks
    // if (drag.data.isPinned)
    // {
    //     allow = true //false
    // }

    // return allow;
   }
  
  drop(event: CdkDragDrop<string[]>)
  {
    if (event.previousContainer === event.container) return;

    if (this.checkLimits(event) == false) return;

    this.updateTask(event.item.data, "drop", event.container.data);
    
    this.store.dispatch(TASKS_ACTIONS.selectTask({id: event.item.data}))
  }

  checkLimits(event):boolean
  {
    // Allow moving tasks when it is a start and end event in same cell
    if (event.item.data.isStart && event.item.data.isEnd)
    {
      return true
    }
   
    let startDate = this.tqDT.toLuxon(event.item.data.startDay)
    let startTime = this.tqDT.timeTo24h(event.item.data.startTime)

    let endDate = this.tqDT.toLuxon(event.item.data.endDay)
    let endTime = this.tqDT.timeTo24h(event.item.data.endTime)

    let date = event.container.data[0].numberYear+"-"
             + event.container.data[0].numberMonth.toString().padStart(2, '0')+"-"
             + event.container.data[0].numberDay.toString().padStart(2, '0')
    let dropDate = this.tqDT.toLuxonFromISO(date)
    let dropTime = "00:00"  
    if (event.item.data.isStart) dropTime = startTime
    if (event.item.data.isEnd)   dropTime = endTime

    let startDateTime = this.tqDT.formatDateISO(startDate) + "T" + startTime
    let endDateTime   = this.tqDT.formatDateISO(endDate)   + "T" + endTime
    let dropDateTime  = this.tqDT.formatDateISO(dropDate)  + "T" + dropTime
    
    // Do not allow to move before start or after end
    if (event.item.data.isStart)
    {
      if (dropDateTime > endDateTime) return false
    }
    if (event.item.data.isEnd)
    {
      if (dropDateTime < startDateTime) return false
    }

    return true
  }

  scrollToPaneTitle()
  {
    document.getElementById("monthpadPane").scrollIntoView({ behavior: 'smooth' });
  }
  
  scrollToTop(delay: number = 0)
  {
    ///this.cdr.detach();   
    ///setTimeout( () => {
      /// document.getElementById("header").scrollIntoView({ behavior: 'smooth' })
      ///this.cdr.reattach();   
    ///}, delay)
  }

  clearToTop()
  {
    this.store.dispatch(TASKS_ACTIONS.selectTask({id: 0}))   
    this.scrollToPaneTitle()
//    this.scrollToTop(1000)
  }

  scrollToTask()
  {
    // Do not scroll until data is ready
    if (this.tasksState.status != 'loaded') return;

    // Do not scroll when filter is active
    if (this.monthpadFilter == true) return;

    // No task where to scroll
    if (this.taskId == null) return;

    // Search taskId in monthpad
    var elem = document.getElementById(this.taskId.toString());
    if (elem != null)
    {
      elem.scrollIntoView({inline: 'start', block: 'center'});
//      this.scrollToTop()
    }
  }


  addTask(d: any)
  {
    this.taskId=0;
    this.taskText="";

    // Leaving component
    this.store.dispatch(APP_ACTIONS.nextTQpad({ pad:'task' })) 

    this.store.dispatch(TASKS_ACTIONS.selectTask({id: 0}))

    this.samApp.saveStore("TQmonthpad.year", this.monthpadMonth.year, "session")
    this.samApp.saveStore("TQmonthpad.month", this.monthpadMonth.month, "session")

    this.samApp.saveStore("TQmonthpad.filter", this.monthpadFilter, "session")
    this.samApp.saveStore("TQmonthpad.filterRegEx", this.monthpadFilterRegEx, "session")

    this.router.navigate(['/task', this.taskId],
      { queryParams: { 
          from: "monthpad", 
          YYYY: d["numberYear"], 
          MM: d["numberMonth"], 
          DD: d["numberDay"], 
        }
      }
    )
  }

  editTask(event: any, t?: any)
  {
    // Just select the task if Ctrl key is pressed
    if (event && event.ctrlKey) 
      {
      //event.preventDefault();
      this.taskId=t["id"];
      this.store.dispatch(TASKS_ACTIONS.selectTask({id: this.taskId}))
      return;
    }
  
    if (t == null)
    {
      this.taskId=0;
      this.taskText="";
    }
    else
    {
      this.taskId=t["id"];
      this.taskText=t["title"];
    }
    // Leaving component
    this.store.dispatch(APP_ACTIONS.nextTQpad({ pad:'task' })) 

    this.store.dispatch(TASKS_ACTIONS.selectTask({id: this.taskId}))

    this.samApp.saveStore("TQmonthpad.year", this.monthpadMonth.year, "session")
    this.samApp.saveStore("TQmonthpad.month", this.monthpadMonth.month, "session")

    this.samApp.saveStore("TQmonthpad.filter", this.monthpadFilter, "session")
    this.samApp.saveStore("TQmonthpad.filterRegEx", this.monthpadFilterRegEx, "session")

    this.router.navigate(['/task', this.taskId])
  }

  async updateTask
  (
    t: any,
    attribute: string,
    value: string | any[],
  )
  {
    var TQTask = {
      "id"         : t,
      "profile_id" : this.appState.TQprofileId,
    }
    if (attribute=="status")
    {
      TQTask['status']=value
    }
    if (attribute=="priority")
    {
      TQTask['priority']=value
    }
    if (attribute=="relDate")
    {
      if (value == "not pinned") 
      {
        TQTask['relDate']='future'
      }
      if (value == "current day") 
      {
        TQTask['relDate']='+0d'
      }
      if (value == "next day") 
      {
        TQTask['relDate']='+1d'
      }
    }
    if (attribute=="drop")
    {
      // Get the taskId from the full task t
      TQTask['id']=t.id;

      // Drop date
      let newDateISO = this.tqDT.formatDateISO(value [0].date)

      if (t.isStart)
      {
        TQTask['startWorkDate'] = newDateISO;
        if (t.startWorkTZ != null && t.startWorkTZ != "N")  
        {
          let dateTimeTZ = this.tqDT.toLuxonFromISO(newDateISO, t.startWorkTime, this.TQSession.prefLocTimeZone).setZone(t.startWorkTZ)
          TQTask['startWorkTZ'] = t.startWorkTZ
          TQTask['startWorkTZDate'] = this.tqDT.formatDateISO(dateTimeTZ)
        }            
      }
      if (t.isEnd)
      {
        TQTask['endWorkDate'] = newDateISO;
        if (t.endWorkTZ != null && t.endWorkTZ != "N")  
          {
            let dateTimeTZ = this.tqDT.toLuxonFromISO(newDateISO, t.endWorkTime, this.TQSession.prefLocTimeZone).setZone(t.endWorkTZ)
            TQTask['endWorkTZ'] = t.endWorkTZ
            TQTask['endWorkTZDate'] = this.tqDT.formatDateISO(dateTimeTZ)
          }            
        }
      if (t.isDue)
      {
        TQTask['dueDate'] = newDateISO;
        if (t.dueTZ != null && t.dueTZ != "N")  
        {
          let dateTimeTZ = this.tqDT.toLuxonFromISO(newDateISO, t.dueTime, this.TQSession.prefLocTimeZone).setZone(t.dueTZ)
          TQTask['dueTZ'] = t.dueTZ
          TQTask['dueTZDate'] = this.tqDT.formatDateISO(dateTimeTZ)
        }            
      }
      if (t.isTarget)
      {
        TQTask['targetDate'] = newDateISO;
        if (t.targetTZ != null && t.targetTZ != "N")  
        {
          let dateTimeTZ = this.tqDT.toLuxonFromISO(newDateISO, t.targetTime, this.TQSession.prefLocTimeZone).setZone(t.targetTZ)
          TQTask['targetTZ'] = t.targetTZ
          TQTask['targetTZDate'] = this.tqDT.formatDateISO(dateTimeTZ)
        }            
      }
    }

    // TODO catch
    let res = await this.tqApi.updateTask(TQTask)

    this.store.dispatch(TASKS_ACTIONS.selectTask({id: res['id']}))

    this.store.dispatch(TASKS_ACTIONS.loadTasksList());
    this.createMonthpad();
    this.buildMonthpadList();
  }

  async duplicateTask(taskId: any)
  {
    let res = await this.tqApi.duplicateTask(taskId);

    this.store.dispatch(TASKS_ACTIONS.selectTask({id: res['id']}))

    this.store.dispatch(TASKS_ACTIONS.loadTasksList());
    this.createMonthpad();
    this.buildMonthpadList();
  }

  goThisMonth()
  {
    this.monthpadMonth.year = this.tqDT.tqDateTime.year
    this.monthpadMonth.month = this.tqDT.tqDateTime.month
    this.monthpadMonth.name = this.tqDT.formatMonthName(this.tqDT.tqDateTime)

    this.samApp.saveStore("TQmonthpad.year", this.monthpadMonth.year, "session")
    this.samApp.saveStore("TQmonthpad.month", this.monthpadMonth.month, "session")

    this.createMonthpad()
    this.buildMonthpadList()
  }

  goPreviousMonth()
  {
    let firstDayOfMonth = DateTime.fromObject({'year':this.monthpadMonth.year, 'month':this.monthpadMonth.month, 'day':1}).minus({'month': 1})

    this.monthpadMonth.year = firstDayOfMonth.year
    this.monthpadMonth.month = firstDayOfMonth.month
    this.monthpadMonth.name = this.tqDT.formatMonthName(firstDayOfMonth)

    this.samApp.saveStore("TQmonthpad.year", this.monthpadMonth.year, "session")
    this.samApp.saveStore("TQmonthpad.month", this.monthpadMonth.month, "session")

    this.createMonthpad()
    this.buildMonthpadList()
  }

  goNextMonth()
  {
    let firstDayOfMonth = DateTime.fromObject({'year':this.monthpadMonth.year, 'month':this.monthpadMonth.month, 'day':1}).plus({'month': 1})

    this.monthpadMonth.year = firstDayOfMonth.year
    this.monthpadMonth.month = firstDayOfMonth.month
    this.monthpadMonth.name = this.tqDT.formatMonthName(firstDayOfMonth)
    this.samApp.saveStore("TQmonthpad.year", this.monthpadMonth.year, "session")
    this.samApp.saveStore("TQmonthpad.month", this.monthpadMonth.month, "session")

    this.createMonthpad()
    this.buildMonthpadList()
  }

  createMonthpad()
  {
    ///this.cdr.detach();   

    this.monthpadMonth.year = this.samApp.retrieveStore("TQmonthpad.year", "session")
    if (this.monthpadMonth.year === null)
    {
      this.monthpadMonth.year = this.tqDT.tqDateTime.year
    }
    this.monthpadMonth.month = this.samApp.retrieveStore("TQmonthpad.month", "session")
    if (this.monthpadMonth.month === null)
    {
      this.monthpadMonth.month = this.tqDT.tqDateTime.month
    }
    this.monthpadMonth.name = this.tqDT.formatMonthNameFromNumber(this.monthpadMonth.month)

    this.samApp.saveStore("TQmonthpad.year", this.monthpadMonth.year, "session")
    this.samApp.saveStore("TQmonthpad.month", this.monthpadMonth.month, "session")

    // Start at the week of first day of the month
    let firstDayOfMonth = DateTime.fromObject({'year':this.monthpadMonth.year, 'month':this.monthpadMonth.month, 'day':1})
    // Start at the week number of first day of the week
    let weekNumber = firstDayOfMonth.weekNumber;

    let dow;
    let firstDay=this.TQSession['prefLocWeekStart']
    if (firstDay == null) firstDay="Sunday"
    switch (firstDay)
    {
      case "Friday"  : dow = [ "Fri.", "Sat.", "Sun.", "Mon.", "Tue.", "Wed.", "Thu.", ]; break
      case "Saturday": dow = [ "Sat.", "Sun.", "Mon.", "Tue.", "Wed.", "Thu.", "Fri.", ]; break
      case "Sunday"  : dow = [ "Sun.", "Mon.", "Tue.", "Wed.", "Thu.", "Fri.", "Sat.", ]; break
      case "Monday"  : dow = [ "Mon.", "Tue.", "Wed.", "Thu.", "Fri.", "Sat.", "Sun.", ]; break
      default        : dow = [ "Sat.", "Sun.", "Mon.", "Tue.", "Wed.", "Thu.", "Fri.", ]; break
    }

    // Display previous week to fit the first day of the month, when week not starting on Sunday
    if ( (firstDay == "Friday"   && firstDayOfMonth.day <= 5 ) ||
         (firstDay == "Saturday" && firstDayOfMonth.day <= 6 ) ||
         (firstDay == "Monday"   && firstDayOfMonth.day == 1 )
    )
    {
      firstDayOfMonth.minus({"week": 1})
    }

    // Prepare the weeks in the month
    let weeks: Array<WeekOfMonth> = Array(6);

    for (let w=0; w<6; w++)
    {
      // Prepare days in this week
      let days: Array<DayOfWeek> = Array(7);
      for (let d=0; d<7; d++)
      {
        // From the first day of the week of the first day of the month
        // add w weeks and d days
        let thisDate = firstDayOfMonth.startOf('week').plus({'days': 7*w+d})
        switch (firstDay)
        {
          case "Friday"  : thisDate = thisDate.minus({'day': 3}); break
          case "Saturday": thisDate = thisDate.minus({'day': 2}); break
          case "Sunday"  : thisDate = thisDate.minus({'day': 1}); break
        }
        let day: DayOfWeek = {
          date: thisDate,
          nameDay: dow[d],
          numberDay: thisDate.day,
          numberMonth: thisDate.month,
          numberYear: thisDate.year,
          isToday: false,
          isCurrentMonth: false,
          weekend: false,
          tasks: [],
        };

        // Mark weekends
        day.weekend = this.tqDT.isWeekend(day.date)

        // Mark current month
        if (day.numberYear  == this.monthpadMonth.year &&
            day.numberMonth == this.monthpadMonth.month)
        {
          day.isCurrentMonth = true;
          // Mark actual today when in this month
          if (day.numberYear  == this.tqDT.tqDateTime.year &&
              day.numberMonth == this.tqDT.tqDateTime.month && 
              day.numberDay == this.tqDT.tqDateTime.day)
          {
            day.isToday = true;
          }
        }

        days[d] = day
      }

      // Set the week in this month
      weeks[w] = {
        number: weekNumber,
        days: days
      }

      // Next week
      weekNumber++
    }
    this.monthpadMonth.weeks = weeks;

    ///this.cdr.reattach();   
  }

  buildMonthpadList()
  {
    /// if (this.TQtasks == null) return;
    if (this.tasksState.TQtasks == null) return;

    ///this.cdr.detach();

    // Order tasks by project code
    let tasks: any[] = this.tasksState.TQtasks // Array.from(this.TQtasks)
    //   .sort((a, b) => {
    //     if (a.project_code == b.project_code) return 0;
    //     return (a.project_code < b.project_code ? -1 : 1)
    //   }
    // )

    for (var i = 0; i < tasks.length; i++)
    {
      var t=tasks[i];

      // Filter by project list
      if (this.appState.projectFilterCode != '')
      {
        if (this.appState.projectFilterList.indexOf(t.project_id) < 0) continue;
      }

      // Ignore filtered tasks
      if (this.filterTask(t)) continue;

      // TODO Factor out forEach x2

      // Test for Start Date event
      if (t.startWorkDate)
      {
        // Check if this date is one of the weeks in this calendar
        // including other month days >= this.monthpadMonth.firstDate w[0]d[0]
        if (this.tqDT.isSameDateOrAfter(t.startWorkDate, this.tqDT.formatDate(this.monthpadMonth.weeks[0].days[0].date)) && 
            this.tqDT.isSameDateOrBefore(t.startWorkDate, this.tqDT.formatDate(this.monthpadMonth.weeks[5].days[6].date)))
        {
          let date = this.tqDT.toLuxon(t.startWorkDate)
          let day = date.day
          let month = date.month
          let newDay = null
          this.monthpadMonth.weeks
            .forEach( w => {
              w.days.forEach ( d => {
                if (d.numberDay == day && d.numberMonth == month)
                {
                  newDay = d;
                }
              })
            })
          this.loadTask(t, newDay, "start")
        }
      }

      // Test for End Date event
      if (t.endWorkDate)
      {
        if (this.tqDT.isSameDateOrAfter(t.endWorkDate, this.tqDT.formatDate(this.monthpadMonth.weeks[0].days[0].date)) && 
            this.tqDT.isSameDateOrBefore(t.endWorkDate, this.tqDT.formatDate(this.monthpadMonth.weeks[5].days[6].date)))
        {
          let date = this.tqDT.toLuxon(t.endWorkDate)
          let day = date.day
          let month = date.month
          let newDay = null
          this.monthpadMonth.weeks
            .forEach( w => {
              w.days.forEach ( d => {
                if (d.numberDay == day && d.numberMonth == month)
                {
                  newDay = d;
                }
              })
            })
            this.loadTask(t, newDay, "end")
        }
      }

      // Test for Due Date event
      if (t.dueDate)
      {
        if (this.tqDT.isSameDateOrAfter(t.dueDate, this.tqDT.formatDate(this.monthpadMonth.weeks[0].days[0].date)) && 
            this.tqDT.isSameDateOrBefore(t.dueDate, this.tqDT.formatDate(this.monthpadMonth.weeks[5].days[6].date)))
        {
          let date = this.tqDT.toLuxon(t.dueDate)
          let day = date.day
          let month = date.month
          let newDay = null
          this.monthpadMonth.weeks
            .forEach( w => {
              w.days.forEach ( d => {
                if (d.numberDay == day && d.numberMonth == month)
                {
                  newDay = d;
                }
              })
            })
            this.loadTask(t, newDay, "due")
        }
      }

      // Test for Target Date event
      if (t.targetDate)
      {
        if (this.tqDT.isSameDateOrAfter(t.targetDate, this.tqDT.formatDate(this.monthpadMonth.weeks[0].days[0].date)) && 
            this.tqDT.isSameDateOrBefore(t.targetDate, this.tqDT.formatDate(this.monthpadMonth.weeks[5].days[6].date)))
        {
          let date = this.tqDT.toLuxon(t.targetDate)
          let day = date.day
          let month = date.month
          let newDay = null
          this.monthpadMonth.weeks
            .forEach( w => {
              w.days.forEach ( d => {
                if (d.numberDay == day && d.numberMonth == month)
                {
                  newDay = d;
                }
              })
            })
            this.loadTask(t, newDay, "target")
        }
      }

      // Test for Pin Today event
      if (t.relDate == "+0d")
      {
        if (t['status'] != "done" && t['status'] != "canceled" )
        {
          if (this.tqDT.isSameDateOrAfter(this.tqDT.tqDateString, this.tqDT.formatDate(this.monthpadMonth.weeks[0].days[0].date)) && 
              this.tqDT.isSameDateOrBefore(this.tqDT.tqDateString, this.tqDT.formatDate(this.monthpadMonth.weeks[5].days[6].date)))
          {
          let date = this.tqDT.tqDateTime
          let day = date.day
          let month = date.month
          let newDay = null
          this.monthpadMonth.weeks
            .forEach( w => {
              w.days.forEach ( d => {
                if (d.numberDay == day && d.numberMonth == month)
                {
                  newDay = d;
                }
              })
            })
          this.loadTask(t, newDay, "pinned")
          }
        }
      }

      // Test for Relative Tomorrow event
      if (t.relDate == "+1d")
      {
        if (t['status'] != "done" && t['status'] != "canceled" )
        {
          let nextDate = this.tqDT.tqDateTime.plus({'day': 1})
          if (this.tqDT.isSameDateOrAfter(this.tqDT.formatDate(nextDate), this.tqDT.formatDate(this.monthpadMonth.weeks[0].days[0].date)) && 
              this.tqDT.isSameDateOrBefore(this.tqDT.formatDate(nextDate), this.tqDT.formatDate(this.monthpadMonth.weeks[5].days[6].date)))
        {
          let date = nextDate
          let day = date.day
          let month = date.month
          let newDay = null
          this.monthpadMonth.weeks
            .forEach( w => {
              w.days.forEach ( d => {
                if (d.numberDay == day && d.numberMonth == month)
                {
                  newDay = d;
                }
              })
            })              
          this.loadTask(t, newDay, "pinned")
          }
        }
      }

      // Test for day of the week repetition
      if ( TASK.hasRepetitionOnWeekdays(t) && this.showOngoing)
      {
        if (t.status != "todo" && t.status != "doing") continue;

        let newDay = null
        this.monthpadMonth
          .weeks.forEach( w => {
            w.days.forEach( d => {

              if (!TASK.taskRepeatsThisWeekday(t, d.date.weekday)) return;

              if (TASK.hasRepetitionOnNumberDay(t) && !TASK.taskRepeatsThisNumberDay(t, d.numberDay)) return;
              if (TASK.hasRepetitionOnMonths(t) && !TASK.taskRepeatsThisMonth(t, d.numberMonth)) return;

              if (t.startWorkDate != null && this.tqDT.isDateAfter(t.startWorkDate, this.tqDT.formatDate(d.date))) return;
              if (t.endWorkDate   != null && this.tqDT.isDateBefore(t.endWorkDate, this.tqDT.formatDate(d.date))) return;

              this.loadTask(t, d, "repetition")
            })
          })              
      }

      // Test for day of month repetition
      if ( TASK.hasRepetitionOnNumberDay(t) )
      {
        if (t.status != "todo" && t.status != "doing") continue;

        this.monthpadMonth
          .weeks.forEach( w => {
            w.days.forEach( d => {
              
              if (!TASK.taskRepeatsThisNumberDay(t, d.numberDay) ) return;

              if (TASK.hasRepetitionOnWeekdays(t) && !TASK.taskRepeatsThisWeekday(t, d.date.weekday) ) return;
              if (TASK.hasRepetitionOnMonths(t) && !TASK.taskRepeatsThisMonth(t, d.numberMonth)) return;

              if (t.startWorkDate != null && this.tqDT.isDateAfter(t.startWorkDate, this.tqDT.formatDate(d.date))) return;
              if (t.endWorkDate   != null && this.tqDT.isDateBefore(t.endWorkDate, this.tqDT.formatDate(d.date))) return;

              this.loadTask(t, d, "repetition")
            })
          })              
      }
      
      // Test for month repetition
      if ( TASK.hasRepetitionOnMonths(t) )
      {
        if (t.status != "todo" && t.status != "doing") continue;

        this.monthpadMonth
          .weeks.forEach( w => {
            w.days.forEach( d => {
              
              if (!TASK.taskRepeatsThisMonth(t, d.numberMonth) ) return;

              if (TASK.hasRepetitionOnWeekdays(t) && !TASK.taskRepeatsThisWeekday(t, d.date.weekday) ) return;
              if (TASK.hasRepetitionOnNumberDay(t) && !TASK.taskRepeatsThisNumberDay(t, d.numberDay)) return;

              if (t.startWorkDate != null && this.tqDT.isDateAfter(t.startWorkDate, this.tqDT.formatDate(d.date))) return;
              if (t.endWorkDate   != null && this.tqDT.isDateBefore(t.endWorkDate, this.tqDT.formatDate(d.date))) return;

              this.loadTask(t, d, "repetition")
            })
          })              
      }

      // Test for ongoing task
      if (this.showOngoing)
        {
          if (t.status != "doing") continue;
          if (t.relDate == "+0d" || t.relDate == "+1d") continue;
  
          this.monthpadMonth
            .weeks.forEach( w => {
              w.days.forEach( d => {
                {
                  if (TASK.hasRepetitionOnNumberDay(t) && !TASK.taskRepeatsThisNumberDay(t, d.numberDay)) return;
                  if (TASK.hasRepetitionOnMonths(t) && !TASK.taskRepeatsThisMonth(t, d.numberMonth) ) return;
                  if (TASK.hasRepetitionOnWeekdays(t) && !TASK.taskRepeatsThisWeekday(t, d.date.weekday) ) return;
  
                  if (t.startWorkDate != null && this.tqDT.isDateAfter(t.startWorkDate, this.tqDT.formatDate(d.date))) return;
                  if (t.endWorkDate   != null && this.tqDT.isDateBefore(t.endWorkDate, this.tqDT.formatDate(d.date))) return;
  
                  this.loadTask(t, d, "ongoing")
                }
              })
            })
        }
        
    }

    setTimeout( () => this.scrollToTask(), 0)
    ///this.cdr.reattach();   
  }

  loadTask(t:any, day:any, attribute:string)
  {
    let task = day.tasks.find(e => e.id == t.id)
    day.tasks = day.tasks.filter(e => e.id != t.id)
    if (!task)
    {
      task = {
          id: null,
          title: null,
          isStart: false,
          isEnd: false,
          isDue: false,
          isTarget: false,
          isPinned: false,
          isOngoing: false,
          isRepetition: false,
          status: null,
          project_code: null,
          project_color: null,
          // Save timezones
          startWorkTZ: t.startWorkTZ,
          endWorkTZ: t.endWorkTZ,
          dueTZ: t.dueTZ,
          targetTZ: t.targetTZ,
        }
    }

    // Load task attributes
    task.id = t.id
    task.title = t.title
    task.status = t.status
    switch (attribute)
    {
      case "ongoing":    task.isOngoing = true; break;
      case "pinned":     task.isPinned = true; break;
      case "repetition": task.isRepetition = true; break;
      case "start":      task.isStart = true; break;
      case "end":        task.isEnd = true; break;
      case "due":        task.isDue = true; break;
      case "target":     task.isTarget = true; break;
    }

    task.project_code = t.project_code
    task.project_color = t.project_color

    task.startDay = t.startWorkDate,
    task.startTime = t.startWorkTime,
    task.endDay = t.endWorkDate,
    task.endTime = t.endWorkTime,
    task.dueDay = t.dueDate,
    task.dueTime = t.dueTime,
    task.targetDay = t.targetDate,
    task.targetTime = t.targetTime,

    day.tasks.push(task)
  }

  getNamesOfDays()
  {
    if (!!!this.monthpadMonth.weeks) return;

    if (this.showWeekend)
    {
      return this.monthpadMonth.weeks[0].days
    }
    else
    {
      return this.monthpadMonth.weeks[0].days.filter(d => d.weekend == false)
    }
  }

  getTasksOfDay(d:any)
  {
    return d.tasks;
  }

  getDaysOfWeek(w: number)
  {
    if (!!!this.monthpadMonth.weeks) return;

    if (this.showWeekend)
    {
      return this.monthpadMonth.weeks[w].days
    }
    else
    {
      return this.monthpadMonth.weeks[w].days.filter(d => d.weekend == false)
    }
  }

  getWeeksOfMonth()
  {
    return this.monthpadMonth.weeks
  }

  gotoTodopad(d:any)
  {
    let date = this.tqDT.formatDateISO(d.date)
    this.router.navigate(['todopad'], { queryParams: {date:date} })
  }


  isToday(d)
  {
    return (d.numberDay == this.tqDT.tqDateTime.day && d.numberMonth == this.tqDT.tqDateTime.month && d.numberYear == this.tqDT.tqDateTime.year)
  }

  toggleOngoing()
  {
    this.showOngoing = ! this.showOngoing
    this.samApp.saveStore("TQmonthpad.showOngoing", this.showOngoing, "session")

    this.createMonthpad()
    this.buildMonthpadList()
  }

  toggleWeekend()
  {
    this.showWeekend = ! this.showWeekend
    this.samApp.saveStore("TQmonthpad.showWeekend", this.showWeekend, "session")
  }

  clearMonthpadFilter()
  {
    this.monthpadFilterRegEx = null;
    this.samApp.deleteStore("TQmonthpad.filterRegEx", "session")
    this.samApp.deleteStore("TQmonthpad.filter", "session")

    var elem=document.getElementById("MonthpadFilter")
    setTimeout(() =>
    {
      elem.focus();
      elem.scrollIntoView({behavior: 'smooth', inline: 'center', block: 'center'});
    }, 0);
  }

  filterTask(t: any): boolean
  {
    if (this.monthpadFilterRegEx == null) return false

    this.samApp.saveStore("TQmonthpad.filter", this.monthpadFilter, "session")
    this.samApp.saveStore("TQmonthpad.filterRegEx", this.monthpadFilterRegEx, "session")

    // Return whether the task title matches the filter
    return (t['title'].match(new RegExp(this.monthpadFilterRegEx,"i")) == null)? true:false;
  }

  clearProjectFilter()
  {
    this.store.dispatch(APP_ACTIONS.projectFiltered({ id: 0, code:'', color:'', list:[]}))
  }

  onTaskMenuCommand(command: string, task: any)
  {
    switch(command)
    {
      case "Edit":
        this.editTask(null, task); // TODO Remove null
        break;
      case "Duplicate":
        this.duplicateTask(task.id);
        break;
      case "planning":
      case "todo":
      case "doing":
      case "waiting":
      case "stopped":
      case "done":
      case "canceled":
        this.updateTask(task.id, "status", command);
        break;
      case "A":
      case "B":
      case "C":
      case "D":
      case "E":
        this.updateTask(task.id, "priority", command);
        break;
      case "not pinned":
      case "current day":
      case "next day":
        this.updateTask(task.id, "relDate", command);
        break;
      case "Select":
        this.store.dispatch(TASKS_ACTIONS.selectTask({id: task.id}))
        break;
      }
  }

}
